// import React, { useEffect, useState } from "react";
// import { Main, Section } from "./style";
// import View from "../../../assets/img/policies/view.svg";
// import Edit from "../../../assets/img/policies/edit.svg";
// import Delete from "../../../assets/img/policies/delete.svg";
// import { Link, useNavigate } from "react-router-dom";
// import useHooks from "./useHook";
// import DeleteModal from "components/modal/delete";
// import Spiner from "components/spinner";

// const Policies = () => {
//   const { policiesGet, policiesDelete } = useHooks();
//   const [loading, setLoading] = useState(true);
//   const navigate = useNavigate();

//   const EditPolicy = (id) => {
//     navigate("/my-policies/create-policies", { state: { id } });
//   };

//   // const ViewPolicy = (id) => {
//   //   navigate(`/my-policies/view-policies/${id}`);
//   // };
//   const handleCreatePolicy = (id, policyname) => {
//     navigate(`/my-policies/create-policies/${id}/${policyname}`);
//   };
//   const [policieslist, setPolicieslist] = useState([]);
//   const fetchPolicies = async () => {
//     try {
//       const userID = localStorage.getItem("id");
//       const formdata = {
//         userId: userID,
//       };
//       const data = await policiesGet(formdata);
//       setPolicieslist(data);
//       console.log(data.map((item) => item.description));
//     } catch (error) {
//       console.log(error);
//     } finally {
//       setLoading(false);
//     }
//   };
//   useEffect(() => {
//     fetchPolicies();
//   }, []);
//   const handleDeletePolicies = async (policyId) => {
//     try {
//       await policiesDelete(policyId);
//       console.log("deleteid is", policyId);
//       const updatedPolicylist = policieslist.filter(
//         (policy) => policy.id !== policyId
//       );
//       setPolicieslist(updatedPolicylist);
//     } catch (error) {
//       console.error("Error deleting product:", error);
//     }
//   };

//   //   delete Modal
//   const [deleteModalOpen, setDeleteModalOpen] = useState(false);
//   const [deleteID, setDeleteID] = useState(null);
//   const handleCloseDeleteModal = () => {
//     setDeleteID(null);
//     setDeleteModalOpen(false);
//   };
//   const handleConfirmDelete = async () => {
//     if (deleteID !== null) {
//       try {
//         await policiesDelete(deleteID);
//         const newdata = policieslist.filter(
//           (product) => product.id !== deleteID
//         );
//         setPolicieslist(newdata);
//         handleCloseDeleteModal();
//       } catch (error) {
//         console.error("Error deleting catalog:", error);
//       }
//     }
//   };
//   useEffect(() => {
//     if (!deleteModalOpen) {
//       policiesGet();
//     }
//   }, [deleteModalOpen]);
//   const handleOpenDeleteModal = (id) => {
//     setDeleteID(id);
//     setDeleteModalOpen(true);
//   };
//   const data = [
//     { index: 1, id: 1, policyname: "cancelation policy" },
//     { index: 2, id: 2, policyname: "returned policy" },
//     { index: 3, id: 3, policyname: "shippment policy" },
//   ];
//   const matchingPolicies = data.filter((item) =>
//     ["cancelation policy", "returned policy", "shippment policy"].includes(
//       item.policyname.toLowerCase()
//     )
//   );
//   const handleViewPolicy = (policyId) => {
//     navigate(`/my-policies/view-policies/${policyId}`);
//   };
//   return (
//     <Main>
//       {loading ? (
//         <Spiner />
//       ) : (
//         <>
//           <DeleteModal
//             open={deleteModalOpen}
//             handleClose={handleCloseDeleteModal}
//             handleDelete={handleConfirmDelete}
//           />
//           <div className="container-fluid">
//             <div className="heading-flex">
//               <p className="heading">My Policies</p>
//             </div>
//             <div>
//               {/* {policieslist.length <= 0 ? (
//                 <div className="no-record">No Record Found</div>
//               ) : (
//                 policieslist.map((items, index) => ( */}
//               <Section style={{ padding: "10px 0px" }}>
//                 {/* <div
//                   className="policies-list"
//                   style={{ background: "#ffffff" }}
//                 >
//                   <div>
//                     <p className="policy-heading">Privacy policy</p>
//                     <div className="policy-name"></div>
//                   </div>
//                   <div className="policies-actions"> */}
//                 {/* <img
//                           src={View}
//                           alt="View Item"
//                           //   onClick={handleClick}
//                           onClick={() => ViewPolicy(items.id)}
//                           className="view-icon"
//                         />
//                         <img
//                           src={Edit}
//                           alt="Edit Item"
//                           onClick={() => EditPolicy(items.id)}
//                           className="edit-icon"
//                         />
//                         <img
//                           src={Delete}
//                           alt="Delete Item"
//                           onClick={() => handleOpenDeleteModal(items.id)}
//                           className="delete-icon"
//                         /> */}
//                 {/* <button
//                       type="submit"
//                       className="category-btn"
//                       onClick={handleCreatePolicy}
//                     >
//                       Create New Policy
//                     </button>
//                   </div>
//                 </div> */}
//                 {policieslist.map((item, index) =>
//                   item.name === "cancelation policy" ||
//                   item.name === "returned policy" ||
//                   item.name === "shippment policy" ? (
//                     <>
//                       <Section style={{ padding: "10px 0px" }} key={index}>
//                         {matchingPolicies ? (
//                           // If a matching policy exists, show the icons
//                           <div
//                             className="policies-list"
//                             style={{ background: "#ffffff" }}
//                           >
//                             <div>
//                               <p className="policy-heading">
//                                 {matchingPolicies.map(
//                                   (data) => data.policyname
//                                 )}
//                               </p>
//                             </div>
//                             <div className="policies-actions">
//                               <img
//                                 src={View}
//                                 alt="View Item"
//                                 onClick={() =>
//                                   handleViewPolicy(matchingPolicies.id)
//                                 }
//                                 className="view-icon"
//                               />
//                               <img
//                                 src={Edit}
//                                 alt="Edit Item"
//                                 onClick={() => EditPolicy(matchingPolicies.id)}
//                                 className="edit-icon"
//                               />
//                               <img
//                                 src={Delete}
//                                 alt="Delete Item"
//                                 onClick={() =>
//                                   handleOpenDeleteModal(matchingPolicies.id)
//                                 }
//                                 className="delete-icon"
//                               />
//                             </div>
//                           </div>
//                         ) : (
//                           // If no matching policy exists, show the "Create New Policy" button
//                           <div
//                             className="policies-list"
//                             style={{ background: "#ffffff" }}
//                           >
//                             <div>
//                               <div className="policy-name">{item.name}</div>
//                             </div>
//                             <div className="policies-actions">
//                               <div className="no-record">
//                                 <button
//                                   type="submit"
//                                   className="category-btn"
//                                   onClick={() =>
//                                     handleCreatePolicy(item.id, item.name)
//                                   }
//                                 >
//                                   Create New Policy
//                                 </button>
//                               </div>
//                             </div>
//                           </div>
//                         )}
//                       </Section>
//                     </>
//                   ) : (
//                     <Section style={{ padding: "10px 0px" }} key={index}>
//                       <div
//                         className="policies-list"
//                         style={{ background: "#ffffff" }}
//                       >
//                         <div>
//                           <div className="policy-name">no policy</div>
//                         </div>
//                         <div className="policies-actions">
//                           <div className="no-record">
//                             <button
//                               type="submit"
//                               className="category-btn"
//                               onClick={() =>
//                                 handleCreatePolicy(item.id, item.policyname)
//                               }
//                             >
//                               Create New Policy
//                             </button>
//                           </div>
//                         </div>
//                       </div>
//                     </Section>
//                   )
//                 )}
//               </Section>
//               {/* ))
//               )} */}
//             </div>
//           </div>
//         </>
//       )}
//     </Main>
//   );
// };

// export default Policies;

import React, { useEffect, useState } from "react";
import { Main, Section } from "./style";
import View from "../../../assets/img/policies/view.svg";
import Edit from "../../../assets/img/policies/edit.svg";
import Delete from "../../../assets/img/policies/delete.svg";
import { useNavigate } from "react-router-dom";
import useHooks from "./useHook";
import DeleteModal from "components/modal/delete";
import Spiner from "components/spinner";
import toastr from "toastr";

const Policies = () => {
  const { policiesGet, policiesDelete } = useHooks();
  const [loading, setLoading] = useState(true);
  const [policiesList, setPoliciesList] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteID, setDeleteID] = useState(null);
  const navigate = useNavigate();

  const fetchPolicies = async () => {
    try {
      const userID = localStorage.getItem("id");
      const formData = { userId: userID };
      const data = await policiesGet(formData);
      setPoliciesList(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPolicies();
  }, []);

  // const handleDeletePolicies = async (policyId) => {
  //   try {
  //     await policiesDelete(policyId);
  //     const updatedPolicyList = policiesList.filter(
  //       (policy) => policy.id !== policyId
  //     );
  //     setPoliciesList(updatedPolicyList);
  //     handleCloseDeleteModal();
  //   } catch (error) {
  //     console.error("Error deleting policy:", error);
  //   }
  // };

  const handleCloseDeleteModal = () => {
    setDeleteID(null);
    setDeleteModalOpen(false);
  };

  const handleOpenDeleteModal = (id) => {
    setDeleteID(id);
    setDeleteModalOpen(true);
  };

  const handleViewPolicy = (policyId) => {
    navigate(`/my-policies/view-policies/${policyId}`);
  };

  const handleEditPolicy = (id, policyname) => {
    navigate(`/my-policies/create-policies/${id}/${policyname}`);
  };

  const handleCreatePolicy = (policyname) => {
    navigate(`/my-policies/create-policies/${policyname}`);
  };

  const requiredPolicies = [
    "cancellation policy",
    "returned policy",
    "shippment policy",
  ];

  const handleDeletePolicies = async (policyId) => {
    try {
      await policiesDelete(policyId); 
      const updatedPolicyList = policiesList.filter(
        (policy) => policy.id !== policyId
      ); 
      setPoliciesList(updatedPolicyList); 
      handleCloseDeleteModal(); // Close the modal
    } catch (error) {
      console.error("Error deleting policy:", error);
    }
  };

  return (
    <Main>
      {loading ? (
        <Spiner />
      ) : (
        <>
          <DeleteModal
            open={deleteModalOpen}
            handleClose={handleCloseDeleteModal}
            handleDelete={() => handleDeletePolicies(deleteID)}
          />
          <div className="container-fluid">
            <div className="heading-flex">
              <p className="heading">My Policies</p>
            </div>
            <div>
              {requiredPolicies.map((policyName) => {
                const policy = policiesList?.find(
                  (item) => item.name.toLowerCase() === policyName
                );

                return (
                  <Section style={{ padding: "10px 0px" }} key={policyName}>
                    <div
                      className="policies-list"
                      style={{ background: "#ffffff" }}
                    >
                      <div>
                        <p className="policy-heading">{policyName}</p>
                      </div>
                      <div className="policies-actions">
                        {policy ? (
                          <>
                            <img
                              src={View}
                              alt="View Item"
                              onClick={() => handleViewPolicy(policy.id)}
                              className="view-icon"
                            />
                            <img
                              src={Edit}
                              alt="Edit Item"
                              onClick={() => handleEditPolicy(policy.id)}
                              className="edit-icon"
                            />
                            <img
                              src={Delete}
                              alt="Delete Item"
                              onClick={() => handleOpenDeleteModal(policy.id)}
                              className="delete-icon"
                            />
                          </>
                        ) : (
                          <button
                            type="submit"
                            className="category-btn"
                            onClick={() => handleCreatePolicy(policyName)}
                          >
                            Create New Policy
                          </button>
                        )}
                      </div>
                    </div>
                  </Section>
                );
              })}
            </div>
          </div>
        </>
      )}
    </Main>
  );
};

export default Policies;
