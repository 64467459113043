import React, { useEffect, useState } from "react";
import { Main, Table } from "./style";
import PaginationRounded from "components/Pagination";
import PaymentRequestModal from "components/modal/my-wallet-RequestPayment-modal";
import WalletChart from "../wallet-chart";
import AddAmountModal from "components/modal/my-wallet-addDetailsModal";
import SelectAccountModal from "components/modal/my-wallet-selectAccountModal";
import RequestPaymentImage from "../../../assets/img/my-wallet/requestPaymentImage1.png";
import { Link } from "react-router-dom";
import useHook from "./usehooks";
import { ReactComponent as Question } from "../../../assets/img/my-wallet/Question.svg";
import { FaInfoCircle } from "react-icons/fa";
import Spiner from "components/spinner";
import Viewer from "react-viewer";
const Wallet = () => {
  // usehook here
  const { GetwalletCards, GetallRequests, GetSettings } = useHook();

  const [requestAmountModal, setRequestAmountModal] = useState(false);
  const [selectAccountModal, setSelectAccountModal] = useState(false);
  const [addAccountModal, setAddAccountModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [setting, setsetting] = useState([]);

  // Modals Funtions for show and hide //BecareFully while updating transition of these modals
  const handleRequestModal = () => {
    setRequestAmountModal(true);
    setSelectAccountModal(false);
  };

  const handleSelectAccountModal = () => {
    setRequestAmountModal(false);
    setSelectAccountModal(true);
  };

  const handleAddAccountModal = () => {
    setRequestAmountModal(false);
    setSelectAccountModal(false);
    setAddAccountModal(true);
  };
  // Api Hook
  const [withdrawlCard, setWithdrawlCard] = useState("");
  const getCardsWithdrawl = async () => {
    const response = await GetwalletCards();
    setWithdrawlCard(response.data);
  };

  useEffect(() => {
    getCardsWithdrawl();
  }, []);
  // See all withDraw Request Table
  const [seeAllWithDrawRequest, setSeeAllWithDrawRequest] = useState(false);
  const handleSeeAllRequests = () => {
    setSeeAllWithDrawRequest(true);
  };
  const cardsData = [
    {
      id: 1,
      title: "Total Earning",
      cost: `$${withdrawlCard?.totalEarning || "0"}`,
      costColor: "#B36618",
      icon: Question,
      abbr: `The total earning amount is available after deduction of ${setting}% of transaction fee through admin channel.`,
    },
    {
      id: 2,
      title: "Pending Payment",
      cost: `$${withdrawlCard?.pendingPayment || "0"}`,
      costColor: "#FB923C",
      icon: Question,
      abbr: "After 30 days your payment will arrive in Available Amount. 🚀.",
    },
    {
      id: 3,
      title: "Available Amount",
      cost: `$${withdrawlCard?.availableAmount || "0"}`,
      costColor: "#379417",
      icon: null,
    },
    {
      id: 4,
      title: "Withdraws",
      cost: `$${withdrawlCard?.withdraws || "0"}`,
      costColor: "#FF1010",
      icon: null,
    },
  ];
  
  useEffect(() => {
    if (!requestAmountModal) {
      getAllRequest();
    }
  }, [requestAmountModal]);

  //    get all request   //

  const [allRequests, setAllRequests] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  const getAllRequest = async () => {
    try {
      const response = await GetallRequests(page, perPage);
      setAllRequests(response.data.results);
      // Assuming the API returns the total count of items
      const totalCount = response.data.counts;
      setTotalPages(Math.ceil(totalCount / perPage));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllRequest();
  }, [page,perPage]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    });
  };

  const getAllSettings = async () => {
    const response = await GetSettings();
    setsetting(response.data[1]?.value);
  };

  useEffect(() => {
    getAllSettings();
  }, []);

  // image viwer
  const [visible, setVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState(null); 

  const handleImageClick = (imageSrc) => {
    setCurrentImage(imageSrc);  
    setVisible(true); 
  };

  return (
    <Main>
      {loading ? (
        <Spiner />
      ) : (
        <div className="container-fluid">
          <div className="wallet-headingSection">
            <p className="wallet-headingName">Wallet</p>
            <button
              type="submit"
              className="wallet-headingButton"
              onClick={handleRequestModal}
            >
              Request Payment
            </button>
          </div>
          <div className="wallet-cards">
            {cardsData.map((items, index) => (
              <div key={index} className="cards-section">
                <h5>
                  {items.title}{" "}
                  <span>
                    {" "}
                    {items.icon && (
                      <abbr
                        style={{ cursor: "pointer" }}
                        title={items.abbr || ""}
                      >
                        <items.icon />
                      </abbr>
                    )}
                  </span>
                </h5>

                <p className="card-cost" style={{ color: items.costColor }}>
                  {items.cost}
                </p>
              </div>
            ))}
          </div>

          <div>{/* <WalletChart /> */}</div>
          <div className="withdraw-section">
            <p className="withdraw-section-title">Withdraw Requests</p>
            {/* <Link to="/my-wallet/withdraw-request-data">See All</Link> */}
          </div>

          <div className="dataTable-section">
            <div className="dataTable-section-body">
              <div className="table-responsive">
                <table className="table-width">
                  <thead>
                    <tr className="table-head">
                      <th > ID</th>
                      <th>Requested Amount</th>
                     
                      <th>Status</th>
                      <th>Image</th>
                      <th>Requested Date</th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {allRequests.map((item, index) => {
                      const formattedDate = formatDate(item.createdAt);
                      return (
                        <tr key={index}>
                          <td className="table-body-id">{item.id}</td>
                          <td>{item.amount}</td>
                         
                          <td>
                            <span
                            style={{textTransform:"capitalize"}}
                              className={`status-${
                                item.status.toLowerCase() === "pending"
                                  ? "pending"
                                  : "approved"
                              }`}
                            >
                              {item.status}
                            </span>
                          </td>
                          {/* <td>
                            {item.status.toLowerCase() === "approved" &&
                              item.image && (
                                <>
                                <img
                                  src={`${process.env.REACT_APP_BASE_URL}/${item.image}`}
                                  alt="Withdraw ScreenShots!"
                                  className="image-width"
                                  onClick={() => setVisible(true)}
                                />
                                <Viewer
                                visible={visible}
                                onClose={() => setVisible(false)}
                                images={[{ src: {`${process.env.REACT_APP_BASE_URL}/${item.image}`}, alt: "Image" }]}
                              />
                              </>
                              )}
                          </td> */}
                          <td>
                            {item.status.toLowerCase() === "approved" &&
                              item.image && (
                                <>
                                  <img
                                    src={`${process.env.REACT_APP_BASE_URL}/${item.image}`}
                                    alt="Withdraw ScreenShots!"
                                    className="image-width"
                                   style={{cursor: "pointer"}}
                                    onClick={() =>
                                      handleImageClick(
                                        `${process.env.REACT_APP_BASE_URL}/${item.image}`
                                      )
                                    }
                                  />
                                  {visible && currentImage && (
                                    <Viewer
                                      visible={visible}
                                      onClose={() => setVisible(false)}
                                      images={[
                                        { src: currentImage, alt: "Image" },
                                      ]} // Only show the clicked image
                                      // noClose={true}
                                      noToolbar={true}
                                      noFooter={true}
                                      drag={false}
                                      noDrag={true}
                                      noResetZoomAfterChange={true}
                                      disableMouseZoom={true}
                                    />
                                  )}
                                </>
                              )}
                          </td>

                          <td>
                          {item?.createdAt
                      ? new Date(item?.createdAt).toLocaleDateString(
                          "en-US",
                          {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                          }
                        )
                      : "N/A"}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <div className="pagination-section">
                <PaginationRounded
                  count={totalPages} // Total pages based on the API data
                  page={page} // Current page
                  onChange={handlePageChange}
                />
              </div>
            </div>
          </div>
          {/* Modals Flow */}
          {requestAmountModal && (
            <PaymentRequestModal
              onCloseRequestModal={() => {setRequestAmountModal(false);
                localStorage.removeItem('accountName');
                localStorage.removeItem('accountNumber');
              }}
              onOpenSelectAccountModal={handleSelectAccountModal}
              onOpenRequestModal={requestAmountModal}
            />
          )}

          {selectAccountModal && (
            <SelectAccountModal
              onCloseSelectAccountModal={() => {
                setSelectAccountModal(false);
                setRequestAmountModal(true); // Reopen the PaymentRequestModal on close
              }}
              onOpenSelectAccountModal={selectAccountModal}
              onOpenAddAccountModal={handleAddAccountModal}
            />
          )}

          {addAccountModal && (
            <AddAmountModal
              onCloseAddAccountModal={() => {
                setAddAccountModal(false);
                setSelectAccountModal(true); // Reopen the SelectAccountModal on close
              }}
              onOpenAddAccountModal={addAccountModal}
            />
          )}
        </div>
      )}
    </Main>
  );
};

export default Wallet;
