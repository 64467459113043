import React, { useEffect, useState } from "react";
import editOnly from "../../../assets/img/policies/editOnly.svg";
import deleteOnly from "../../../assets/img/policies/deleteOnly.svg";
import { Main } from "./style";
import { useParams } from "react-router-dom";
import useHook from "./useHook";
const ViewPolicies = ({ policyId, policy }) => {
  const { id } = useParams();
  console.log(policy);
  console.log(policyId);
  const { Getpolicies } = useHook();
  const data = [
    {
      id: 1,
      title: "Cancellation Period:",
      description:
        "Customers can cancel their order within [insert number] hours of placing the order without incurring any cancellation fees.",
    },
    {
      id: 2,
      title: "Cancellation Fees:",
      description:
        "After the cancellation period has elapsed, a cancellation fee of [insert percentage]% of the total order value may apply.",
    },
    {
      id: 3,
      title: "Cancellation Procedure:",
      description:
        "To cancel an order, customers must contact our customer service team via [insert contact details] and provide their order number for reference.",
    },
    {
      id: 4,
      title: "Refund Process:",
      description:
        "Upon cancellation within the allowed period, a full refund will be issued to the original payment method within [insert number] business days. For cancellations after the allowed period, refunds will be processed minus the applicable cancellation fee.",
    },
    {
      id: 5,
      title: "Exceptions:",
      description:
        "Certain products may be exempt from cancellation or subject to different cancellation terms. Customers will be notified of any such exceptions at the time of purchase.",
    },

    {
      id: 6,
      title: "Refund Eligibility:",
      description:
        "Refunds will only be issued for cancellations made in accordance with this policy and if the product is returned in its original condition, unused, and with all packaging intact.",
    },
    {
      id: 7,
      title: "Cancellation of Customized Orders:",
      description:
        "Orders for customized or made-to-order products may not be eligible for cancellation after production has commenced. Customers will be informed of the cancellation policy for customized orders before placing their order.",
    },
    {
      id: 8,
      title: "Policy Updates:",
      description:
        "This cancellation policy is subject to change without prior notice. Any updates or modifications to the policy will be reflected on our website.",
    },
  ];

  const [singlePolicy, setSinglePolicy] = useState("");
  const FetchSingleCollection = async () => {
    try {
      const response = await Getpolicies(id);
      setSinglePolicy(response.data);
      console.log("single policy", response.data);
    } catch (error) {
      console.log("Error single collection get", error);
    }
  };

  useEffect(() => {
    FetchSingleCollection();
  }, []);

  return (
    <Main>
      <div className="container-fluid">
        <div className="heading-flex">
          <p className="heading">View Policies</p>
          {/* <div className="view-policies-action">
            <img src={editOnly} alt="Edit Items" />
            <img src={deleteOnly} alt="Delete Items" />
          </div> */}
        </div>

        <div className="policy-details" style={{ background: "#ffffff" }}>
          {/* <h5>Policy Name</h5>
          <h4>{policyId ? policyId.name : "hye"}</h4> */}
          <h5 style={{ marginBottom: "23px" }}>Policy Discription</h5>
          <div>
            <div className="view-text">
              <h6>
                {/* {items.title}&nbsp; */}
                {/* <span
                  dangerouslySetInnerHTML={{
                    __html: singlePolicy.description
                      ? singlePolicy.description
                      : "nothing",
                  }}
                ></span> */}
                <span
                  dangerouslySetInnerHTML={{
                    __html: singlePolicy?.description,
                  }}
                ></span>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
};

export default ViewPolicies;
