import styled from "styled-components";

export const Main = styled.div`
  width: 100%;
  height: 100vh;
  background-color: var(--all-background);
  .nav-pills .nav-link.active,
  .edagDK .nav-pills .show > .nav-link {
    color: black;
    background-color: #f9f9f9 !important;
  }
  .category-btn {
    width: 218px;
    height: 48px;
    border-radius: 5px;
    background-color: var(--primaryGreen-button);
    font-family: inter;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.2px;
    color: #ffffff;
    border: 0px;
  }
  .policies-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    padding: 20px;
  }
  .policies-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  /* .policies-actions img {
    height: 35px;
  } */
`;

export const Section = styled.section`
  padding: 0px, 20px;
  background-color: none;

  p {
    margin-bottom: 0px;
  }

  .policy-heading {
    font-size: 15px;
    font-weight: 600;
    color: var(--darkGray-color);
    text-transform: capitalize;
  }
  .policy-name {
    font-size: 16px;
    font-weight: 700;
    color: var(--black-color);
  }
`;
