import React, { useState } from "react";
import { Box, Modal, Typography, TextField, Button } from "@mui/material";
import { styled } from "@mui/system";
import { Main } from "./style";
import { IoMdClose } from "react-icons/io";
import AdminLotusPRO from "assets/img/chat/dp.png";
import { MdVerified } from "react-icons/md";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "700px",
  bgcolor: "background.paper",
  outline: "none",
  borderRadius: "12px",
  borderTopLeftRadius: "20px", // Apply border to top-left corner
  borderTopRightRadius: "20px", // Apply border to top-right corner
  boxShadow: 24,
};

const StyledAvatar = styled(AdminLotusPRO)({
  borderRadius: "100px",
  width: 120,
  height: 120,
  margin: "0 auto 20px",
});

const VendorProfileModal = ({
  vendorProfileOpen,
  handleVendorProfileClose,
  singelVendor,
}) => {
  const [profileData, setProfileData] = useState({
    name: "",
    age: "",
    email: "",
    phone: "",
    dateOfBirth: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData({
      ...profileData,
      [name]: value,
    });
  };
  function formatDate(dateString) {
    const date = new Date(dateString);

    // Format the date to 'DD MMM YYYY'
    const options = { day: "2-digit", month: "short", year: "numeric" };

    return date.toLocaleDateString("en-GB", options);
  }

  return (
    <div>
      <Modal open={vendorProfileOpen} onClose={handleVendorProfileClose}>
        <Box sx={style}>
          <Main>
            <Typography className="header">
              <Typography variant="h6">Personal Details</Typography>
              <IoMdClose
                className="close-icon"
                onClick={handleVendorProfileClose}
              />
            </Typography>
            <div className="img-container">
              <img
                src={`${process.env.REACT_APP_BASE_URL}${singelVendor?.profile_pic}`} // Placeholder, replace with your image path or state
                alt="Profile"
                className="profile-pic"
              />
              <span className="vendor-name">
                {singelVendor?.name}{" "}
                {singelVendor?.isVerified ? (
                  <MdVerified
                    color="rgb(21, 106, 61)"
                    size={20}
                    style={{ marginBottom: " 4px" }}
                  />
                ) : (
                  "not Verifiyed"
                )}
              </span>
            </div>
            <div className="main-container">
              <div className="profile-container">
                <span className="column">
                  <label className="lablel-text">Email</label>
                  <span className="user-detail">{singelVendor?.email}</span>
                </span>
                <span className="column">
                  <label className="lablel-text">Phone</label>
                  <span className="user-detail">{singelVendor?.phone}</span>
                </span>
                <span className="column">
                  <label className="lablel-text">Gender</label>
                  <span className="user-detail">{singelVendor?.gender}</span>
                </span>

                <span className="column">
                  <label className="lablel-text">Date of Birth</label>
                  <span className="user-detail">
                    {new Date(singelVendor?.dob).toLocaleDateString("en-US")}
                  </span>
                </span>
              </div>
            </div>
          </Main>
        </Box>
      </Modal>
    </div>
  );
};

export default VendorProfileModal;
