import styled from "styled-components";
import profileBgImage from "../../../assets/img/vendor-profile/profileBgImage.svg";
export const Main = styled.main`
  width: 100%;
  background-color: var(--all-background);

  section {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 0px 0px 25px 0px;
  }

  h1,
  h6,
  address,
  figure {
    margin-bottom: 0px;
  }
  .nav-pills .nav-link.active,
  .edagDK .nav-pills .show > .nav-link {
    color: black;
    background-color: #f9f9f9 !important;
  }
  .edit-btn {
    width: 80px;
    height: 34px;
    border-radius: 5px;
    color: #ffffff;
    background-color: #ff8f50;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0px 8px;
  }
  .back-img {
    width: 100%;
    height: 172px;
    border-radius: 0px 25px 0px 0px;
    background-image: url(${profileBgImage});
    background-size: cover;
    /* object-fit: cover; */
    /* padding: 0px; */
    margin-bottom: 134px;
    padding: 82px 48px;
  }
  /* .active-border:hover{
    border-bottom: 5px solid black;
}
.padding{
  /* padding: 0px 25px; */
  /* font-family: inter;
  font-weight: 600;
  font-size: 24px;
  letter-spacing: 0.2px;
  color: white;
} */
  /* .space-between{
  display: flex;
    justify-content: space-between;
}
.email-flex{
  display: flex;
  gap: 57px;
}
.email{
    font-family: inter;
    font-weight: 500;
    font-size: 16px;
    color: #808D9E;
}
.email-name{
  font-family: inter;
    font-weight: 500;
    font-size: 16px;
    color: #1C1C27;
}
.phone-flex{
  display: flex;
  gap: 22px;
}
.rating{
  font-family: inter;
  font-weight: 500;
  font-size: 16px;
  color: #1C1C27;
}
.rating-2{
  font-family: inter;
  font-weight: 500;
  font-size: 16px;
  color: #808D9E; 
} */
  .women {
    width: 149px;
    height: 149px;
  }
  .business-details {
    width: 177px;
    height: 34px;
    border-radius: 5px;
    background-color: #6b9773;
    font-family: inter;
    font-weight: 600;
    font-size: 14px;
    color: #ffffff;
    border: 0px;
  }
  .ratting-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
  }
`;
// =================================Top-section==============================

export const Profile = styled.div`
  .main-profile {
  }
  width: 100%;
  height: 302px;
  background-color: var(--white-background);
  border-radius: 15px;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: hidden; /* Disable vertical scrolling */
  margin-bottom: 10px;

  /* @media (max-width: 1090px) {
    width: 768px; /* Ensure content width triggers scrolling */
  /* }  */
  .profile-pic {
    background-size: contain;
    background-position: center;
    width: 100%;
    height: 143px;
  }

  .profile-content {
    display: flex;
    /* min-width: 1200px; */
    min-width: 1090px;
    /* white-space: nowrap; Prevent content from wrapping to the next line */
  }
  /* @media (max-width: 1090px) {
      .profile-width {
      width: 0; 
    }
  } */
  .profile-image {
    width: 149px;
    height: 136px;
    border-radius: 50%;
    border: 4px solid white;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .icon-flex{
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .profile-details-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 40px 0px 0px 15px;
    /* margin:70px 0px 0px 15px; */
  }
  .profile-details-section h2 {
    font-family: inter;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0.2px;
    color: #ffffff;
  }
  .email-title,
  .phone-title,
  .follower-title,
  .profile-followers-section p {
    font-size: 16px;
    font-family: inter;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #808d9e;
  }
  .profile-followers-sections p {
    font-size: 16px;
    font-family: inter;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: white;
  }

  .email-detail,
  .phone-detail,
  .profile-followers-section p > span {
    font-family: inter;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #1c1c27;
    padding: 0px 10px;
  }
  .email-section,
  .phone-section {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
  }
  .contact-info-section {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: start;
    margin-top: 10px;
    text-align: start;
    /* gap: 28px; */
  }
  .license-img-width {
    margin-top: 10px;
    width: 100%;
    text-align: justify;
    /* background-color: #ff8f50; */
  }
  .license-img {
    width: 200px;
    height: 115px;
    border-radius: 10px;
  }
  .padding1 {
    padding: 0px 40px;
  }
  .padding2 {
    padding: 0px 8px;
  }
  .rating-section {
    display: flex;
    flex-direction: row;
    gap: 5px;
  }
  .profile-followers-sections {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* padding-top: 30px; */
    gap: 70px;
  }
  .profile-details-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .rating {
    font-family: inter;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.2px;
    color: #1c1c27;
  }
  .total-rating {
    font-family: inter;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.2px;
    color: #808d9e;
  }
  .width {
    width: 33.33%;
    /* background-color: #ff8f50; */
  }
`;
// =================================Mid-section==============================
export const Details = styled.div`
  width: 100%;
  height: auto;
  background-color: var(--white-background);
  border-radius: 15px;
  padding: 25px;

  .business-details-section {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 20px;
  }
  .bussiness-name-title,
  .business-address-title,
  .business-phone-title,
  .business-shop-title,
  .business-description-title {
    font-family: inter;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #808d9e;
    margin-bottom: 3px;
  }
  .busines-name-section p,
  .busines-address-section p {
    font-size: 16px;
    font-weight: 400;
    font-family: inter;
    line-height: 20px;
    text-align: justify;
    color: #1c1c27;
  }
  .busines-name-section p {
    letter-spacing: -0.24px;
  }
  .business-phone-section p {
    font-size: 16px;
    font-weight: 500;
    font-family: inter;
    text-align: justify;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #1c1c27;
  }
  .business-shop-section p,
  .business-description-section p {
    font-size: 14px;
    font-weight: 400;
    font-family: inter;
    line-height: 17px;
    color: #1c1c27;
  }
`;
// =================================Last-section==============================

export const Reviews = styled.div`
  width: 100%;
  height: auto;
  background-color: var(--white-background);
  border-radius: 15px;
  padding: 25px;

  .review-section-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .review-section-name {
    font-family: inter;
    font-weight: 500;
    font-size: 16px;
    color: #1c1c27;
    line-height: 24px;
  }
  .see-all-reviews-button {
    font-family: inter;
    font-weight: 400;
    font-size: 14px;
    color: #1c1c27;
    line-height: 24px;
    border: none;
    background-color: transparent;
  }
  .review-section-body {
    display: flex;
    flex-direction: row;
    gap: 25px;
    padding-top: 15px;
  }
  .profile-picture {
    width: 45px;
    height: 45px;
  }
  .review-section-profile {
    display: flex;
    align-items: center;
    gap: 25px;
  }
  .review-rating-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }
  .review-reports {
    font-family: inter;
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;
    text-align: right;
    color: #e00000;
    background-color: transparent;
    border: none;
  }
  .user-name {
    font-family: inter;
    font-size: 16px;
    font-weight: 600;
    color: #1c1c27;
  }
  .profile-rating {
    font-family: inter;
    font-size: 16px;
    font-weight: 500;
    color: #1c1c27;
    line-height: 24px;
    letter-spacing: 0.2px;
  }
  .review-star {
    width: 17px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .reviews-section-timestamp {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px;
  }
  .reviews-time,
  .reviews-date {
    font-family: inter;
    font-size: 14px;
    font-weight: 400;
    color: #64748b;
  }

  .reviews-comment {
    font-family: roboto;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
  }
  .seeAll {
    cursor: pointer;
  }
`;

export const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: white;
  width: 100%;
  /* overflow-x: scroll; */
  flex-wrap: wrap;
  .lotus-pro {
    width: 118px;
    height: 118px;
    border-radius: 100px;
  }
  .profile-pic {
    border-radius: 100px;
  }
  .profile-picture {
    width: 120px;
    border-radius: 100px;
    border: 1px solid #d9d9d9;
    border-style: dashed;
    height: 120px;
  }
  .search {
    width: 150px;
    height: 48px;
    border-radius: 5px;
    background-color: #156a3d;
    border: 0;
    font-family: inter;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    color: #ffffff;
  }
  .email-title {
    font-size: 16px;
    font-family: inter;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #808d9e;
  }
  .email-detail {
    font-family: inter;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #1c1c27;
  }
  .profile-flex {
    display: flex;
    gap: 100px;
  }
  /* @media (min-width: 600px) and (max-width:1100px) {
  overflow-x: auto;
    width: 100%;
} */
  @media (max-width: 600px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    width: 100%;
    .profile-picture {
      margin-bottom: 15px;
    }

    .name-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }
    h6 {
      width: 30%;
      margin-bottom: 0px;
      text-align: left;
    }
    span {
      width: 30%;
    }
    .email-container {
      width: 100%;
      margin-top: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-align: left;
      gap: 20px;
    }
    .phone-container {
      margin-top: 5px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }
    .search {
      margin-top: 10px;
      width: 100%;
    }
  }
`;
