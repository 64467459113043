import React, { useEffect, useState } from "react";
import View from "../../../assets/img/collection/view.svg";
import Edit from "../../../assets/img/collection/edit.svg";
import Delete from "../../../assets/img/collection/delete.svg";
import Star from "assets/img/products/star.png";
import PaginationRounded from "components/Pagination";
// import TransitionsModal from "components/modal";
import IosButton from "components/ios-button";
// Collection-Items-List-Images
import collectionProductListSofa from "../../../assets/img/collection/collectionProductListSofa.jpeg";
import collectionProductListChair from "../../../assets/img/collection/collectionProductListChair.jpeg";
import collectionProductListBench from "../../../assets/img/collection/collectionProductListBench.jpeg";
import collectionProductListCabient from "../../../assets/img/collection/collectionProductListCabient.jpeg";
import collectionProductListWashbasen from "../../../assets/img/collection/collectionProductListWashbasen.jpeg";
import { Product } from "./style";
import { useNavigate, useParams } from "react-router-dom";
import useHooks from "./useHooks";
import DeleteModal from "components/modal/delete";
import Spiner from "components/spinner";
import LotusPro from "assets/img/common/lotuspro.png";
const CollectionDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    singleCollectionGet,
    getproductlist,
    productSwitchPut,
    productDelete,
  } = useHooks();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteID, setDeleteID] = useState(null);
  const [collectionDetail, setCollectionDetail] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [switches, setSwitches] = useState([]);
  const [loading, setLoading] = useState(true);
  const fetchSingleCollectionDetail = async () => {
    const response = await singleCollectionGet(id);
    setCollectionDetail(response.data);
    setLoading(false);
    // console.log("response is",response);
    // console.log("colllection detail is",collectionDetail)
  };
  useEffect(() => {
    fetchSingleCollectionDetail();
  }, []);
  const [productlist, setprductlist] = useState([]);
  const fetchProducts = async () => {
    let params = `?collectionId=${encodeURIComponent(
      id
    )}&page=${currentPage}&perPage=0`;

    const response = await getproductlist(params);
    setprductlist(response.data.results);
    setSwitches(response.data.results.map((item) => item.isPublished));
    console.log("subswitches state is", switches);
    console.log(response);
  };
  useEffect(() => {
    fetchProducts();
  }, []);

  const handleSwitchToggle = async (index, itemID) => {
    // Create a copy of the switches array
    const newSwitches = [...switches];
    // Toggle the switch
    newSwitches[index] = !newSwitches[index];

    setSwitches(newSwitches);
    const isPublished = newSwitches[index];
    console.log("is publish state", isPublished);

    try {
      await productSwitchPut(itemID, isPublished);
    } catch (error) {
      console.error("Error in handleSwitchToggle: ", error);
      setSwitches((prevSwitches) => [...prevSwitches]);
      return;
    }
  };
  // delete modal
  const handleOpenDeleteModal = (id) => {
    setDeleteID(id);
    setDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteID(null);
    setDeleteModalOpen(false);
  };
  const handleConfirmDelete = async () => {
    if (deleteID !== null) {
      try {
        await productDelete(deleteID);
        const newdata = productlist.filter(
          (product) => product.id !== deleteID
        );
        setprductlist(newdata);
        handleCloseDeleteModal();
      } catch (error) {
        console.error("Error deleting catalog:", error);
      }
    }
  };
  useEffect(() => {
    if (!deleteModalOpen) {
      fetchSingleCollectionDetail();
    }
  }, [deleteModalOpen, currentPage]);

  const handleEdit = (id) => {
    navigate(`/products/update/${id}`);
  };
  const handleView = (id) => {
    navigate(`/products/details/${id}`);
  };
  return (
    <>
      <Product>
        {loading ? (
          <Spiner />
        ) : (
          <>
            <div className="container-fluid">
              <div className="collection-flex ">
                <p className="heading">My Collection</p>
              </div>
              <div className="card border-0 mt-3">
                <div className="card-body">
                  <div className="single-product-description">
                    <img
                      src={
                        collectionDetail.image
                          ? process.env.REACT_APP_MEDIA_URL +
                            collectionDetail.image
                          : LotusPro
                      }
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "12px",
                      }}
                    />
                    <div style={{ marginLeft: "10px" }}>
                      <p className="m-0 product-name">
                        {collectionDetail?.name}{" "}
                      </p>
                      {CollectionDetail.productCount >= 2 ? (
                        <p className="product-count">
                          {collectionDetail.productCount} Product
                        </p>
                      ) : (
                        <p className="product-count">
                          {collectionDetail.productCount} Products
                        </p>
                      )}
                      <p className="product-para">
                        {collectionDetail.description}{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* my-collection ItemsList Table */}

              <div className="card mt-3 border-0">
                <div className="card-body">
                  {productlist.length === 0 ? (
                    <div
                      style={{
                        width: "100%",
                        backgroundColor: "white",
                        padding: "12px",
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "20px",
                        color: "red",
                      }}
                    >
                      No Record Found
                    </div>
                  ) : (
                    <div className="table-responsive">
                      <table className="table-width">
                        <thead className="table-head">
                          <tr className="table-head">
                            <th>Product ID</th>
                            <th>Image</th>
                            <th>Product Name</th>
                            <th>Ratings</th>
                            <th>Category</th>
                            <th>Sub Category</th>
                            <th>Price</th>
                            <th>Sale Price</th>
                            <th>Stock</th>
                            <th>Status</th>
                            <th>Published</th>
                            <th>ACTIONS</th>
                          </tr>
                        </thead>

                        {productlist.map((items, index) => (
                          <tbody className="table-body" key={index}>
                            <td style={{ paddingBlock: "15px" }}>{items.id}</td>
                            <td style={{ paddingBlock: "15px" }}>
                              {" "}
                              {items.images && items.images.length > 0 && (
                                <img
                                  src={`${process.env.REACT_APP_MEDIA_URL}${items.images[0].url}`}
                                  alt="Product Image"
                                  style={{
                                    height: "40px",
                                    width: "42px",
                                    borderRadius: "5px",
                                  }}
                                />
                              )}{" "}
                            </td>
                            <td
                              style={{ paddingBlock: "15px" }}
                              className="dot-property"
                            >
                              {items.name}
                            </td>
                            <td style={{ paddingBlock: "15px" }}>
                              {" "}
                              <span className="rating">
                                <img src={Star} alt="Ratings" /> &nbsp;{" "}
                                {items.totalRatting}
                              </span>
                            </td>

                            <td style={{ paddingBlock: "15px" }}>
                              {" "}
                              {items.category?.parent
                                ? items.category.parent.name
                                : "-"}
                            </td>
                            <td style={{ paddingBlock: "15px" }}>
                              {items.category?.name ? items.category.name : "-"}
                            </td>
                            <td style={{ paddingBlock: "15px" }}>
                              {items.productPrice}
                            </td>
                            <td style={{ paddingBlock: "15px" }}>
                              {items.salePrice}
                            </td>
                            <td style={{ paddingBlock: "15px" }}>
                              {items.qnty}
                            </td>
                            <td style={{ paddingBlock: "15px" }}>
                              {/* <p
                          className={`status-${items.Status === "Sold out" ? "sold" : "stock"
                            } m-0`}
                        >
                          {items.Status}
                        </p> */}
                              <p
                                className={`status-${
                                  items.qnty <= 0 ? "sold" : "stock"
                                } m-0`}
                              >
                                {items.qnty <= 0 ? "Sold out" : "Stock In"}
                              </p>
                            </td>
                            <td style={{ paddingBlock: "15px" }}>
                              <IosButton
                                checked={switches[index]}
                                onChange={() =>
                                  handleSwitchToggle(index, items.id)
                                }
                              />
                            </td>
                            <td
                              style={{ paddingBlock: "15px" }}
                              className="icon-flex"
                            >
                              <img
                                src={View}
                                alt="View Only"
                                onClick={() => handleView(items.id)}
                                className="view-icon"
                              />
                              <img
                                src={Edit}
                                alt="Edit Only"
                                onClick={() => handleEdit(items.id)}
                                className="edit-icon"
                              />
                              <img
                                src={Delete}
                                alt="Delete Only"
                                onClick={() => handleOpenDeleteModal(items.id)}
                                className="delete-icon"
                              />
                            </td>
                          </tbody>
                        ))}
                      </table>
                    </div>
                  )}
                </div>
                <DeleteModal
                  open={deleteModalOpen}
                  handleClose={handleCloseDeleteModal}
                  handleDelete={handleConfirmDelete}
                />
              </div>
            </div>
          </>
        )}
      </Product>
    </>
  );
};

export default CollectionDetail;
