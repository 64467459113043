import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import Star from "../../../assets/img/vendor-profile/starIcon.svg";
import HalfStar from "../../../assets/img/vendor-profile/halfStar.svg";
import { BusinessDetailsContainer } from "./style";
import { IoMdClose } from "react-icons/io";
import { MdVerified } from "react-icons/md";


const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    width: 1022px; // Adjust width as needed
    height: 700px; // Adjust height as needed
    max-width: 100%; // Ensure responsiveness
    max-height: 100%;
    border-radius: 18px;
  }
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 50px;
  }
  .heading {
    margin-left: 400px;
  }
`;

const BusinessDetailsModal = ({ open, onClose, businessInfo, ProfileData }) => {
  const backgroundImage = businessInfo?.businessBannerImage
    ? `${process.env.REACT_APP_BASE_URL}${businessInfo?.businessBannerImage}`
    : "";
  return (
    <StyledDialog open={open} onClose={onClose}>
      <div className="header">
        <DialogTitle className="heading">Business Details</DialogTitle>
        <DialogActions>
          {/* <Button onClick={onClose} color="primary">
            Close
          </Button> */}
          <IoMdClose
            className="close-icon"
            onClick={onClose}
            style={{
              fontSize: " 25px",
              cursor: "pointer",
              color: "#0f0f0f",
            }}
          />
        </DialogActions>
      </div>
      <DialogContent>
        <Typography variant="body1">
          <BusinessDetailsContainer>
            <div
              style={{
                backgroundImage: `
          linear-gradient(
            180deg,
            rgba(2, 0, 36, 0.4) 180%,
            rgba(2, 0, 36, 1) 250%
          ),
          url(${backgroundImage})
        `,
                backgroundSize: "cover",
                backgroundPosition: "center",
                width: "100%",
                height: "200px",
                borderRadius: "25px 25px 0 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "80px",
                  padding: "0px 20px",
                }}
              >
                <p className="styling-font">
                  Followers :
                  <span className="ms-2">{ProfileData?.followersCount}</span>
                </p>
                <img
                  src={`${process.env.REACT_APP_BASE_URL}${
                    // profileData ? profileData.profile_pic : ProfileImage
                    businessInfo?.businessLogo
                  }`}
                  style={{
                    width: "149px",
                    height: "149px",
                    borderRadius: "76px",
                    border: "4px solid white",
                  }}
                  alt=""
                />
                <p className="styling-font">
                  Profile like :
                  <span className="ms-2">{ProfileData?.profileLike}</span>
                </p>
              </div>
            </div>
            <div className="text-center" style={{ marginTop: "30px" }}>
              <h2 className="main-name">{businessInfo?.businessName}  {ProfileData?.isVerified ? (
                              <MdVerified
                                color="rgb(21, 106, 61)"
                                size={20}
                                // style={{ marginBottom: " 4px" }}
                              />
                            ) : (
                              "not Verifiyed"
                            )}</h2>
              

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "5px",
                  alignItems: "center",
                }}
              >
                <div>
                  <p className="ratting ">
                    {ProfileData &&
                    ProfileData.totalRatting !== undefined &&
                    ProfileData.totalRatting !== null
                      ? parseFloat(ProfileData.totalRatting).toFixed(2)
                      : "Loading..."}
                    ({ProfileData.numberOfRattings})
                  </p>
                </div>
                <div>
                  <img src={Star} alt="Ratings" />
                  {/* <img src={Star} alt="Ratings" />
                  <img src={Star} alt="Ratings" />
                  <img src={Star} alt="Ratings" />
                  <img src={HalfStar} alt="Ratings" /> */}
                </div>
              </div>
            </div>

            <div className="main-width">
              <div className="partition-width">
                <p className="main-head">EIN/ SSN</p>
                <div>
                  <p className="sub-head">{businessInfo?.businessSsin}</p>
                </div>
              </div>
              <div className="partition-width">
                <p className="main-head">DBA</p>
                <div>
                  <p className="sub-head">{businessInfo?.dba}</p>
                </div>
              </div>
              <div className="partition-width">
                <p className="main-head">Type of Business</p>
                <div>
                  <p className="sub-head">{businessInfo?.businessType}</p>
                </div>
              </div>
            </div>
            <div className="main-width">
              <div className="partition-width">
                <p className="main-head">Date Started</p>
                <div>
                  <p className="sub-head">
                    {" "}
                    {businessInfo?.createdAt
                      ? new Date(businessInfo.createdAt).toLocaleDateString(
                          "en-US",
                          {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                          }
                        )
                      : "N/A"}
                  </p>
                </div>
              </div>
              <div className="partition-width">
                <p className="main-head">Business License</p>
                <div>
                  <p className="sub-head">{businessInfo?.businessLicenseNo}</p>
                </div>
              </div>
              <div className="partition-width">
                <p className="main-head">Business License Image</p>
                <div>
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}${businessInfo?.businessLicenseImage}`}
                    alt=""
                    className="license-img"
                  />
                </div>
              </div>
            </div>
            <div className="main-width">
              <div className="partition-width">
                <p className="main-head">State</p>
                <div>
                  <p className="sub-head">{businessInfo?.state}</p>
                </div>
              </div>
              <div className="partition-width">
                <p className="main-head">City</p>
                <div>
                  <p className="sub-head">{businessInfo?.city}</p>
                </div>
              </div>
              <div className="partition-width">
                <p className="main-head">Zip Code</p>
                <div>
                  <p className="sub-head">{businessInfo?.zip}</p>
                </div>
              </div>
            </div>
            <hr />

            <div style={{ display: "flex", gap: "20px" }}>
              <div className="w-50 ">
                <p className="main-head">Physical Address Line 1</p>
                <div>
                  <p className="address-font">{businessInfo?.addressLine1}</p>
                </div>
              </div>
              <div className="w-50 ">
                <p className="main-head">Physical Address Line 2</p>
                <div>
                  <p className="address-font">{businessInfo?.addressLine2}</p>
                </div>
              </div>
            </div>
            <div style={{ display: "flex", gap: "20px", marginTop: "15px" }}>
              <div className="w-50 ">
                <p className="main-head">Description</p>
                <div>
                  <p className="address-font">{businessInfo?.description}</p>
                </div>
              </div>
              <div className="w-50 ">
                <p className="main-head">About Shop</p>
                <div>
                  <p className="address-font">{businessInfo?.aboutShop}</p>
                </div>
              </div>
            </div>
            {/* Add more detailed content as needed */}
          </BusinessDetailsContainer>
        </Typography>
      </DialogContent>
    </StyledDialog>
  );
};

export default BusinessDetailsModal;
