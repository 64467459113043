import styled from "styled-components";

export const Main = styled.div`
  width: 100%;
  background-color: var(--all-background);

  .products {
    font-family: inter;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.2px;
    color: #000000;
    padding-block: 20px;
  }
  .validation-color {
    font-weight: 500;
    color: red;
    font-family: "Inter";
  }
  .info {
    font-family: inter;
    font-weight: 500;
    font-size: 24px;
    letter-spacing: 0.2px;
    color: #156a3d;
    padding: 0px 0px 6px 41px;
  }
  .bottom-border {
    border-bottom: 3px solid #156a3d;
    width: 225px;
  }
  .padding {
    padding: 0px 50px 0px 64px;
  }
  .inputs-flex {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
  }
  .inputs-flexs {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
  }
  @media (max-width: 1280px) {
    .inputs-flex {
      flex-wrap: wrap;
      display: block;
    }
  }
  @media (max-width: 1280px) {
    .inputs-flexs {
      flex-wrap: wrap;
      flex-direction: column;
    }
  }
  .inputs-width {
    width: 673px;
    height: 56px;
    border-radius: 12px;
    border: 1px solid #e2e8f0;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    padding: 13px;
    outline: none;
    resize: none;
  }
  @media (max-width: 1280px) {
    .inputs-width {
      width: 100%;
    }
  }
  .inputs-description {
    width: 673px;
    height: 161px;
    border-radius: 12px;
    border: 1px solid #e2e8f0;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    padding: 13px;
    outline: none;
    resize: none;
  }
  @media (max-width: 1280px) {
    .inputs-description {
      width: 100%;
    }
  }
  .inputs-height {
    width: 155px;
    height: 56px;
    border-radius: 12px;
    border: 1px solid #e2e8f0;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    padding: 13px;
    outline: none;
  }
  .inputs-price {
    width: 673px;
    height: 56px;
    border-radius: 12px;
    border: 1px solid #e2e8f0;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: black;
    padding: 0px 0px 0px 70px;
    outline: none;
    background-color: #f9f9f9;
  }
  @media (max-width: 1280px) {
    .inputs-price {
      width: 100%;
    }
  }
  .label {
    font-family: inter;
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 0.2px;
    color: #000000;
    padding-block: 15px;
  }
  .card-padding {
    padding: 20px 0px 0px 0px;
  }
  .upload-img {
    width: 672px;
    height: 74px;
    border: 2px dashed #94a3b8;
    display: flex;
    align-items: center;
    padding: 0px 30px;
    gap: 15px;
    border-radius: 12px;
  }
  @media (max-width: 1280px) {
    .upload-img {
      width: 100%;
    }
  }
  .drag {
    font-family: inter;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.2px;
    color: #000000;
  }
  .accepted-only {
    font-family: inter;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #94a3b8;
  }
  @media (max-width: 419px) {
    .accepted-only {
      font-size: 7px;
    }
  }
  .keywords {
    width: 57px;
    height: 28px;
    border-radius: 6px;
    background-color: #0c3a2d;
    font-family: inter;
    font-weight: 400;
    font-size: 12px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .img-dollar {
    position: absolute;
    border: 1px solid #e2e8f0;
    padding: 13px 18px 17px 22px;
    border-radius: 12px;
    background-color: #f9f9f9;
  }
  .absolute {
    position: absolute;
    right: 20px;
    top: 13px;
  }
  .picked-color {
    border-radius: 6px;
    font-family: inter;
    font-weight: 400;
    font-size: 12px;
    color: #ffffff;
  }

  .select-color {
    display: flex;
    margin: 10px 0px;
    flex-wrap: wrap;
  }
  .Add {
    width: 222px;
    height: 48px;
    border-radius: 5px;
    background-color: #0c3a2d;
    border: 0px;
    font-family: inter;
    font-weight: 700;
    font-size: 16px;
    color: #ffffff;
  }
  @media (max-width: 425px) {
    .Add {
      width: 100%;
    }
  }
  .Cancel {
    width: 222px;
    height: 48px;
    border-radius: 5px;
    background-color: #94a3b866;
    border: 0px;
    font-family: inter;
    font-weight: 700;
    font-size: 16px;
    color: #1c1c27;
  }
  @media (max-width: 425px) {
    .Cancel {
      width: 100%;
    }
  }
  .warranty-input {
    width: 492px;
    height: 56px;
    border-radius: 12px;
    border: 1px solid #e2e8f0;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    padding: 13px;
    outline: none;
  }
  @media (max-width: 1280px) {
    .warranty-input {
      width: 100%;
    }
  }
  .span-flex {
    display: flex;
    gap: 12px;
  }
  .span-select-flex {
    display: flex;
    align-items: center;
    gap: 30px;
  }
  @media (max-width: 1280px) {
    .span-select-flex{
      flex-wrap: wrap;
    }
  }
  .span-space {
    display: flex;
    gap: 15px;
    font-family: inter;
    font-size: 18px;
    font-weight: 400;
    color: #000000;
  }
  .Cross-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 17px;
    height: 17px;
    color: red;
    cursor: pointer;
    border: 1px solid red;
    border-radius: 10px;
  }
  .border-img {
    border: 1px dashed #94a3b8;
    width: 72px;
    height: 72px;
    border-radius: 12px;
  }
  .upload-image {
    width: 72px;
    height: 75px;
    border: 2px dashed #94a3b8;
    display: flex;
    align-items: center;
    justify-content: center;
    /* gap: 15px; */
    flex-direction: column;
    border-radius: 12px;
    /* padding: 10px 0px; */
    font-family: inter;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    color: #000000;
  }
  .img-width {
    width: 17px;
    height: 17px;
  }
  .color-img {
    width: 98px;
    height: 72px;
    border-radius: 10px;
    position: absolute;
    top: 1px;
    left: 1px;
  }

  .color-upload-img {
    width: 100px;
    height: 74px;
    border: 2px dashed #94a3b8;
    display: flex;
    align-items: center;
    gap: 15px;
    border-radius: 12px;
    /* justify-content: center; */
    padding: 0px 25px;
  }

  .buttons-flex {
    display: flex;
    justify-content: end;
    gap: 10px;
    margin-top: 12px;
    padding-bottom: 12px;
  }
  @media (max-width: 425px) {
    .buttons-flex {
      flex-wrap: wrap;
      gap: 10px;
      flex-direction: column-reverse;
    }
  }
  .apply-btn {
    border: 0;
    width: 100%;
    background-color: #0c3a2d;
    color: white;
    font-family: inter;
    font-size: 12px;
    height: 28px;
    border-radius: 7px;
  }
  .cancel-btn {
    border: 0;
    width: 100%;
    background-color: gray;
    color: white;
    font-family: inter;
    font-size: 12px;
    height: 28px;
    border-radius: 7px;
  }
`;
