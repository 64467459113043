// import React, { useState, useRef, useEffect } from "react";
// import Backdrop from "@mui/material/Backdrop";
// import Box from "@mui/material/Box";
// import Modal from "@mui/material/Modal";
// import Fade from "@mui/material/Fade";
// import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";
// import { ImgInput, Main } from "./style";
// import BigArrow from "../../../assets/img/catalogs/Upload Big Arrow.png";
// import TagInputForm from "../input-tags";
// import { IoCloseOutline } from "react-icons/io5";
// import useHooks from "./useHooks";

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 695,
//   height: 600,
//   bgcolor: "background.paper",
//   border: "0px",
//   boxShadow: 24,
//   padding: 5,
//   borderRadius: "30px 0px 0px 30px",
//   paddingTop: 3,
//   overflowX: "auto",
// };
// export default function AddImgModal({
//   open,
//   onClose,
//   catalogId,
//   imageModalItemID,
// }) {
//   const {
//     imagePost,
//     Subcatalogspost,
//     getSubcatalogs,
//     putSubCatalog,
//     getSingleSubcatalogs,
//   } = useHooks();

//   // creatings meta tags
//   const [tags, setTags] = useState([]);
//   const [inputValue, setInputValue] = useState("");
//   const inputRef = useRef(null);

//   const handleInputChange = (event) => {
//     setInputValue(event.target.value);
//   };

//   const handleInputKeyPress = (event) => {
//     const { key } = event;
//     if (key === "Enter" || key === "," || key === " ") {
//       event.preventDefault();
//       const trimmedValue = inputValue?.trim();
//       if (trimmedValue && trimmedValue !== "") {
//         setTags([...tags, trimmedValue]);
//         setInputValue("");
//       }
//     }
//   };

//   const removeTag = (tagToRemove) => {
//     setTags(tags.filter((tag) => tag !== tagToRemove));
//   };

//   const handleInputPaste = (event) => {
//     event.preventDefault();
//     const pasteData = event.clipboardData.getData("text/plain");
//     const pastedTags = pasteData.split(/[,\s]+/);
//     const newTags = [...new Set([...tags, ...pastedTags])];
//     setTags(newTags.filter((tag) => tag.trim() !== ""));
//   };

//   const handleInputKeyDown = (event) => {
//     if (event.key === "Backspace" && inputValue === "" && tags.length > 0) {
//       const updatedTags = [...tags];
//       updatedTags.pop();
//       setTags(updatedTags);
//     }
//   };

//   const handleTagClick = () => {
//     inputRef.current.focus();
//   };
//   // get input field data
//   const [name, setName] = useState("");
//   const [selectedImage, setSelectedImage] = useState(null);
//   const [description, setDescription] = useState("");

//   const handleImageChange = async (e) => {
//     const file = e.target.files[0];
//     try {
//       const imagePath = await imagePost(file); // Ensure this is awaited
//       console.log("FINAL: ", imagePath);
//       if (imagePath) {
//         setSelectedImage(imagePath);
//         console.log("Uploaded Image URL:", imagePath);
//       }
//     } catch (error) {
//       console.error("Error in handleFileInputChange: ", error);
//       alert("An error occurred during image upload");
//     }
//   };
//   const handleImagePickerClick = () => {
//     document.getElementById("imagePicker").click();
//   };
//   const handleCreateSubCatalogs = async (e) => {
//     e.preventDefault();
//     const formData = {
//       name: name,
//       catalogId: parseInt(catalogId),
//       image: selectedImage,
//       tags: tags,
//       description: description,
//     };
//     const res = await Subcatalogspost(formData);
//     console.log("Sub Catalog post data", res);
//     setName("");
//     setDescription("");
//     setSelectedImage(null);
//     setTags([]);

//     onClose();
//   };
//   // for updating
//   const fetchSingleSubCatalog = async () => {
//     try {
//       const response = await getSingleSubcatalogs(imageModalItemID);
//       console.log("Response Single for testing", response);

//       if (imageModalItemID) {
//         setName(response.data.name);
//         setDescription(response.data.description);
//         setSelectedImage(response.data.image);
//         setTags(response.data.tags);
//       } else {
//         setName("");
//         setDescription("");
//         setSelectedImage(null);
//         setTags([]);
//       }
//     } catch (error) {
//       alert("Error in fetching Single Product");
//     }
//   };
//   useEffect(() => {
//     fetchSingleSubCatalog();
//     if (open && !imageModalItemID == "") {
//       // Reset the form fields when opening the modal to add a new image
//       setName("");
//       setDescription("");
//       setSelectedImage(null);
//       setTags([]);
//     }
//   }, [open, imageModalItemID]);

//   const handleUpdate = async (e) => {
//     e.preventDefault();
//     const formData = {
//       name: name,
//       catalogId: parseInt(catalogId),
//       image: selectedImage,
//       tags: tags,
//       description: description,
//     };
//     try {
//       const response = await putSubCatalog(imageModalItemID, formData);
//       console.log("Response Single", response);
//       setName("");
//       setDescription("");
//       setSelectedImage();
//       setTags("");
//       onClose();
//     } catch (error) {
//       alert("Error in fetching Single Product");
//     }
//   };

//   //     Validations

//   return (
//     <div>
//       <Main>
//         {/* <button onClick={handleOpen} className='create-button' >Add Images</button> */}
//         <Modal
//           aria-labelledby="transition-modal-title"
//           aria-describedby="transition-modal-description"
//           open={open}
//           onClose={onClose}
//           closeAfterTransition
//           slots={{ backdrop: Backdrop }}
//           slotProps={{
//             backdrop: {
//               timeout: 500,
//             },
//           }}
//         >
//           <Fade in={open}>
//             <Box sx={style}>
//               {/* Parent catalog ID is:= {catalogId}
//               image Modal Item ID is:=  {imageModalItemID} */}
//               <div
//                 style={{
//                   display: "flex",
//                   flexDirection: "row",
//                   justifyContent: "space-between",
//                 }}
//               >
//                 <Typography></Typography>
//                 <Typography
//                   id="transition-modal-title"
//                   variant="h6"
//                   component="h2"
//                   className="text-center"
//                   style={{ fontWeight: "bold" }}
//                 >
//                   {imageModalItemID ? (
//                     <span>Update Catalog Image </span>
//                   ) : (
//                     <span>Add Catalog Image</span>
//                   )}
//                 </Typography>
//                 <IoCloseOutline
//                   style={{ fontSize: "30px", cursor: "pointer" }}
//                   onClick={onClose}
//                 />
//               </div>
//               <Typography id="transition-modal-description" sx={{ mt: 2 }}>
//                 <ImgInput>
//                   {/* <label>Add Image</label>
//                   <div className='add-img-input'>
//                     <img src={BigArrow} />
//                     <p style={{ padding: "10px 0px", margin: "0px" }}>Upload Image</p>
//                     <p className='m-0'>(Only *.jpeg, *.webp and *.png images will be accepted)</p>
//                   </div> */}
//                   <form>
//                     <label className="fw-bold">Add Image</label>
//                     {/* <div className="add-img-input">
//                     <img src={BigArrow} alt="big-arrow" />
//                     <p style={{ padding: "10px 0px", margin: "0px" }}>
//                       Upload Image
//                     </p>
//                     <p className="m-0">
//                       (Only *.jpeg, *.webp and *.png images will be accepted)
//                     </p>
//                   </div> */}
//                     <div
//                       className="add-img-input"
//                       style={{ cursor: "pointer" }}
//                       onClick={handleImagePickerClick}
//                     >
//                       <label htmlFor="image-upload">
//                         {selectedImage ? (
//                           <img
//                             src={
//                               process.env.REACT_APP_MEDIA_URL + selectedImage
//                             }
//                             alt="Selected Image"
//                             className="img-sizzing"
//                           />
//                         ) : (
//                           <>
//                             <img src={BigArrow} alt="big-arrow" />
//                             <p style={{ padding: "10px 0px", margin: "0px" }}>
//                               Upload Image
//                             </p>
//                             <p className="m-0">
//                               (Only *.jpeg, *.webp and *.png images will be
//                               accepted)
//                             </p>
//                           </>
//                         )}
//                       </label>
//                       {/* Hidden file input */}
//                       <input
//                         type="file"
//                         id="imagePicker"
//                         accept="image/*"
//                         style={{ display: "none" }}
//                         onChange={handleImageChange}
//                       />
//                       <div></div>
//                     </div>

//                     <label className="mt-2">Name</label>
//                     <div>
//                       <input
//                         type="text"
//                         className="name"
//                         placeholder="Enter Catalog Image name"
//                         value={name}
//                         onChange={(e) => setName(e.target.value)}
//                       />
//                     </div>

//                     <label className="mt-2">Description</label>
//                     <div>
//                       <textarea
//                         className="text-area"
//                         placeholder="Write Catalog Image Description"
//                         rows={3}
//                         value={description}
//                         onChange={(e) => {
//                           setDescription(e.target.value);
//                         }}
//                       ></textarea>
//                     </div>

//                     <div>{/* <TagInputForm /> */}</div>
//                     <div>
//                       <label htmlFor="input">Input</label>
//                       <div className="background">
//                         {Array.isArray(tags) &&
//                           tags.map((tag, index) => (
//                             <>
//                               <span key={index} className="tag">
//                                 {tag}
//                                 <button
//                                   type="button"
//                                   className="close"
//                                   onClick={() => removeTag(tag)}
//                                 >
//                                   &times;
//                                 </button>
//                               </span>
//                             </>
//                           ))}
//                         <input
//                           type="text"
//                           value={inputValue}
//                           onChange={handleInputChange}
//                           onKeyPress={handleInputKeyPress}
//                           className="border-0 "
//                           placeholder="Add tags..."
//                           style={{
//                             outline: "none",
//                             backgroundColor: "#F9F9F9",
//                           }}
//                         />
//                       </div>
//                     </div>
//                     <div className="mt-4">
//                       {imageModalItemID ? (
//                         <button
//                           type="submit"
//                           className="create-btn"
//                           onClick={handleUpdate}
//                         >
//                           Update
//                         </button>
//                       ) : (
//                         <button
//                           type="submit"
//                           className="create-btn"
//                           onClick={handleCreateSubCatalogs}
//                         >
//                           Create
//                         </button>
//                       )}
//                     </div>
//                   </form>
//                 </ImgInput>
//               </Typography>
//             </Box>
//           </Fade>
//         </Modal>
//       </Main>
//     </div>
//   );
// }

import React, { useState, useRef, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { ImgInput, Main } from "./style";
import BigArrow from "../../../assets/img/catalogs/Upload Big Arrow.png";
import { IoCloseOutline } from "react-icons/io5";
import useHooks from "./useHooks";
import toastr from "toastr";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 695,
  height: 600,
  bgcolor: "background.paper",
  border: "0px",
  boxShadow: 24,
  padding: 5,
  borderRadius: "30px 0px 0px 30px",
  paddingTop: 3,
  overflowX: "auto",
  "@media (max-width: 425px)": {
    width: "90%",
    height: "auto",
    padding: 2,
    borderRadius: "30px",
  },
};

export default function AddImgModal({
  open,
  onClose,
  catalogId,
  imageModalItemID,
}) {
  const { imagePost, Subcatalogspost, getSingleSubcatalogs, putSubCatalog } =
    useHooks();

  // Creating state and error messages
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [name, setName] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [description, setDescription] = useState("");
  const [nameError, setNameError] = useState("");
  const [imageError, setImageError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [tagsError, setTagsError] = useState("");
  const [disabled, setDisabled] = useState(false);
  

  const inputRef = useRef(null);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyPress = (event) => {
    const { key } = event;
    if (key === "Enter" || key === "," || key === " ") {
      event.preventDefault();
      const trimmedValue = inputValue?.trim();
      if (trimmedValue && trimmedValue !== "") {
        setTags([...tags, trimmedValue]);
        setInputValue("");
      }
    }
  };

  const removeTag = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };

  const handleInputPaste = (event) => {
    event.preventDefault();
    const pasteData = event.clipboardData.getData("text/plain");
    const pastedTags = pasteData.split(/[,\s]+/);
    const newTags = [...new Set([...tags, ...pastedTags])];
    setTags(newTags.filter((tag) => tag.trim() !== ""));
  };

  const handleInputKeyDown = (event) => {
    if (event.key === "Backspace" && inputValue === "" && tags.length > 0) {
      const updatedTags = [...tags];
      updatedTags.pop();
      setTags(updatedTags);
    }
  };

  const handleTagClick = () => {
    inputRef.current.focus();
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    const allowedFileTypes = ["image/jpeg", "image/png", "image/webp"];
    const maxFileSize = 2 * 1024 * 1024; // 2MB in bytes
  
    // Validation for file type and size
    if (!file) {
      toastr.error("Please select a file.");
      return;
    }
  
    if (!allowedFileTypes.includes(file.type)) {
      toastr.error("Only *.jpeg, *.webp, and *.png images are accepted.");
      return;
    }
  
    if (file.size > maxFileSize) {
      toastr.error("Image size should not exceed 2MB.");
      return;
    }
  
    try {
      // Call imagePost API
      const imagePath = await imagePost(file); 
      if (imagePath) {
        setSelectedImage(imagePath);
      
      }
    } catch (error) {
      console.error("Error in handleImageChange:", error);
      toastr.error("An error occurred during image upload");
    }
  };
  

  const handleImagePickerClick = () => {
    document.getElementById("imagePicker").click();
  };

  const validateForm = () => {
    let isValid = true;

    // Validate name
    if (name.trim() === "") {
      setNameError("Name is required");
      isValid = false;
    } else {
      setNameError("");
    }

    // Validate selectedImage
    if (!selectedImage) {
      setImageError("Image is required");
      isValid = false;
    } else {
      setImageError("");
    }

    // Validate description
    if (description.trim() === "") {
      setDescriptionError("Description is required");
      isValid = false;
    } else {
      setDescriptionError("");
    }

    if (tags.length === 0) {
      setTagsError("At least one tag is required");
      isValid = false;
    } else {
      setTagsError("");
    }

    return isValid;
  };

  const handleCreateSubCatalogs = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formData = {
        name: name,
        catalogId: parseInt(catalogId),
        image: selectedImage,
        tags: tags,
        description: description,
      };
      const res = await Subcatalogspost(formData);
      console.log("Sub Catalog post data", res);
      setName("");
      setDescription("");
      setSelectedImage(null);
      setTags([]);
      onClose();
    }
  };

  const handleClickButton = ()=>{
  setDisabled(true)
  }

  const fetchSingleSubCatalog = async () => {
    try {
      const response = await getSingleSubcatalogs(imageModalItemID);
      if (imageModalItemID) {
        setName(response.data.name);
        setDescription(response.data.description);
        setSelectedImage(response.data.image);
        setTags(response.data.tags);
      } else {
        setName("");
        setDescription("");
        setSelectedImage(null);
        setTags([]);
      }
    } catch (error) {
      alert("Error in fetching Single Product");
    } finally{
      setDisabled(false)
    }
  };

  useEffect(() => {
    fetchSingleSubCatalog();
    if (open && !imageModalItemID) {
      setName("");
      setDescription("");
      setSelectedImage(null);
      setTags([]);
    }
  }, [open, imageModalItemID]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formData = {
        name: name,
        catalogId: parseInt(catalogId),
        image: selectedImage,
        tags: tags,
        description: description,
      };
      try {
        const response = await putSubCatalog(imageModalItemID, formData);
        console.log("Response Single", response);
        setName("");
        setDescription("");
        setSelectedImage(null);
        setTags([]);
        onClose();
      } catch (error) {
        alert("Error in updating Sub Catalog");
      }
    }
  };

  return (
    <div>
      <Main>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={onClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography></Typography>
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                  className="text-center"
                  style={{ fontWeight: "bold" }}
                >
                  {imageModalItemID ? (
                    <span>Update Catalog Image</span>
                  ) : (
                    <span>Add Catalog Image</span>
                  )}
                </Typography>
                <IoCloseOutline
                  style={{ fontSize: "30px", cursor: "pointer" }}
                  onClick={onClose}
                />
              </div>
              <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                <ImgInput>
                  <form>
                    <label className="fw-bold">Add Image</label>
                    <div
                      className="add-img-input"
                      style={{ cursor: "pointer" }}
                      onClick={handleImagePickerClick}
                    >
                      <label htmlFor="image-upload">
                        {selectedImage ? (
                          <img
                            src={
                              process.env.REACT_APP_MEDIA_URL + selectedImage
                            }
                            alt="Selected Image"
                            className="img-sizzing"
                          />
                        ) : (
                          <>
                            <img src={BigArrow} alt="big-arrow" />
                            <p style={{ padding: "10px 0px", margin: "0px" }}>
                              Upload Image
                            </p>
                            <p className="m-0">
                              (Only *.jpeg, *.webp and *.png images will be
                              accepted)
                            </p>
                          </>
                        )}
                      </label>
                      <input
                        type="file"
                        id="imagePicker"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={handleImageChange}
                      />
                    </div>
                    {imageError && <div className="error">{imageError}</div>}

                    <label className="mt-2">Name</label>
                    <div>
                      <input
                        type="text"
                        className="name"
                        placeholder="Enter Catalog Image name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      {nameError && <div className="error">{nameError}</div>}
                    </div>

                    <label className="mt-2">Description</label>
                    <div>
                      <textarea
                        className="text-area"
                        placeholder="Enter Description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                      {descriptionError && (
                        <div className="error">{descriptionError}</div>
                      )}
                    </div>

                    <div>
                      <label htmlFor="input">Input</label>
                      <div className="background">
                        {Array.isArray(tags) &&
                          tags.map((tag, index) => (
                            <>
                              <span key={index} className="tag">
                                {tag}
                                <button
                                  type="button"
                                  className="close"
                                  onClick={() => removeTag(tag)}
                                >
                                  &times;
                                </button>
                              </span>
                            </>
                          ))}
                        <input
                          type="text"
                          value={inputValue}
                          onChange={handleInputChange}
                          onKeyPress={handleInputKeyPress}
                          className="border-0 "
                          placeholder="Add tags..."
                          style={{
                            outline: "none",
                            backgroundColor: "#F9F9F9",
                          }}
                        />
                      </div>
                    </div>
                    {tagsError && <div className="error">{tagsError}</div>}

                    <div className="mt-4">
                      {imageModalItemID ? (
                        <button
                          type="submit"
                          className="create-btn"
                          onClick={handleUpdate}
                        >
                          Update
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="create-btn"
                          onClick={handleCreateSubCatalogs}
                          disabled={disabled}
                        >
                            {disabled ? 'Creating...' : 'Create'}
                        </button>
                      )}
                    </div>
                  </form>
                </ImgInput>
              </Typography>
            </Box>
          </Fade>
        </Modal>
      </Main>
    </div>
  );
}
