import React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { Main } from "./style";

const PaginationRounded = ({ count, page, onChange }) => {
  return (
    <Main>
      <div>
        {/* <p className="showing m-0">
          Showing page {page} of {count}
        </p> */}
      </div>
      <div>
        <Stack spacing={2}>
          <Pagination
            count={count}
            page={page}
            onChange={onChange}
            variant="outlined"
            shape="rounded"
          />
        </Stack>
      </div>
    </Main>
  );
};

export default PaginationRounded;
