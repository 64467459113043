import React, { useState, useEffect, useRef } from "react";
import { Card, ChatInput, Main } from "./style";
import BackButton from "../../../assets/img/chat/Back Button.png";
import ProfileImage from "../../../assets/img/chat/dp.png";
import ProfileImage2 from "../../../assets/img/chat/dp2.png";
import Block from "../../../assets/img/chat/block.png";
import Report from "../../../assets/img/chat/report.png";
import Clip from "../../../assets/img/chat/Clip.png";
import Vector from "../../../assets/img/chat/Vector.png";
import { useNavigate, useParams } from "react-router-dom";
import {
  collection,
  addDoc,
  query,
  orderBy,
  onSnapshot,
  doc,
  setDoc,
  updateDoc,
  getDoc,
  arrayUnion,
  arrayRemove,
  getFirestore,
  deleteDoc,
} from "firebase/firestore";
import ChatReportModal from "components/modal/report-modal-chat";
import { getDocs, where } from "firebase/firestore";
import { db, messaging } from "../../../firebase";
import { MessageModel, MessageType } from "./message-modal";
import lotuspro from "assets/img/common/lotuspro.png";
import useHooks from "./useHook";
import { getToken, onMessage } from "firebase/messaging";

const Chat = () => {
  const [text, settext] = useState([
    { text: "Aww", time: "10:43 am", sender: "other" },
    { text: "Haha oh man", time: "10:43 am", sender: "self" },
    {
      text: "Wow, this is really epic😂😂",
      time: "10:43 am",
      sender: "other",
      // img: ChatImg,
    },
    { text: "omg, this is amazing", time: "10:43 am", sender: "self" },
    { text: "perfect!", time: "10:43 am", sender: "self" },
    { text: "How are you?", time: "10:43 am", sender: "self" },
  ]);
  const { id } = useParams();
  const [customerData, setCustomerData] = useState([]);
  const [blockedData, setBlockedData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [file, setFile] = useState(null);
  const [reportComment, setReportComment] = useState(false);
  const [chatData, setChatData] = useState([]);
  const navigate = useNavigate();
  const { imagePost, PushNotifications, SaveFCMToken } = useHooks();

  const chatId = id;
  useEffect(() => {
    const getFirebaseChats = async () => {
      const snapshot = await getDocs(collection(db, "chat"));
      const customers = [];
      const blocked = [];
      snapshot.forEach((doc) => {
        console.log(doc.data());
        const data = doc.data();
        if (data.block?.block) {
          blocked.push({ ...data, id: doc.id });
        } else {
          customers.push({ ...data, id: doc.id });
        }
      });
      setCustomerData(customers);
      setBlockedData(blocked);
      // Log chatId and userId
      console.log("Chat ID:", id);
      const userId = localStorage.getItem("id");
      console.log("User ID:", userId);
    };

    getFirebaseChats();
  }, []);

  // useEffect(() => {
  //   const getFirebaseChats = async () => {
  //     const snapshot = await getDocs(collection(db, "chat"));
  //     const customers = [];
  //     const blocked = [];
  //     const userId = parseInt(localStorage.getItem("id"));

  //     snapshot.forEach((doc) => {
  //       console.log(doc.data());
  //       const data = doc.data();

  //       // Check if the current user is blocked
  //       const isBlocked = data.blockedUsers?.includes(userId);

  //       // Ensure data.messages is defined
  //       const messages = data.messages || [];

  //       // Filter messages where the current user is not the sender
  //       const filteredMessages = messages.filter(
  //         (message) => !isBlocked || message.userId !== userId
  //       );

  //       if (data.block?.block) {
  //         blocked.push({ ...data, id: doc.id, messages: filteredMessages });
  //       } else {
  //         customers.push({ ...data, id: doc.id, messages: filteredMessages });
  //       }
  //     });

  //     setCustomerData(customers);
  //     setBlockedData(blocked);

  //     // Log chatId and userId
  //     console.log("Chat ID:", id);
  //     console.log("User ID:", userId);
  //   };

  //   getFirebaseChats();
  // }, []);

  const ChatManagement = () => {
    navigate("/chat-managment");
  };

  const HandleReportsSection = () => {
    setReportComment(true);
    console.log("clicked");
  };

  const handleChange = (e) => {
    setInputValue(e.target.value);
    // Optionally, you can adjust the height of the textarea dynamically here
    e.target.style.height = "auto";
    e.target.style.height = e.target.scrollHeight + "px";
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]); // Set the selected file to state
    handleSendMessage(); // Call handleSendMessage immediately after setting the file
  };

  // const handleSendMessage = async () => {
  //   if (!inputValue.trim() && !file) {
  //     return; // If neither message nor image is present, do nothing
  //   }

  //   let messageContent = inputValue;
  //   let messageType = MessageType.TEXT;

  //   // Check if a file (image) is selected
  //   if (file) {
  //     const allowedTypes = ["image/png", "image/jpeg", "image/webp"];
  //     const maxSizeMB = 2;
  //     const maxSizeBytes = maxSizeMB * 1024 * 1024; // Convert MB to bytes

  //     // Check file type and size
  //     if (allowedTypes.includes(file.type) && file.size <= maxSizeBytes) {
  //       // Display alert or notification that image is selected (optional)
  //       alert("Image selected!");

  //       // Upload the image and get its URL
  //       const imageUrl = await imagePost(file);
  //       if (imageUrl) {
  //         messageContent = imageUrl;
  //         messageType = MessageType.IMAGE;
  //       } else {
  //         return; // If image upload fails, do not proceed with sending the message
  //       }
  //     } else {
  //       alert(
  //         `Please select a valid image file (PNG, JPG, WEBP) less than ${maxSizeMB}MB.`
  //       );
  //       return; // Stop further execution if file type or size is invalid
  //     }
  //   }

  //   const vendorId = localStorage.getItem("id");

  //   // Create message model
  //   const messageModel = new MessageModel({
  //     createdOn: new Date(),
  //     message: messageContent,
  //     messageType: messageType,
  //     userId: parseInt(vendorId), // Replace with the actual user ID
  //   });

  //   // Send message using your service
  //   await OnSendMessageService({ messageModel, block: [] });

  //   // Clear input and file state, and scroll to bottom
  //   setInputValue("");
  //   setFile(null);
  //   scrollToBottom();
  // };

  ///////////////////// request Permission //////////////

  const handleSendMessage = async () => {
    if (!inputValue.trim() && !file) {
      return; // If neither message nor image is present, do nothing
    }

    const chatId = id; // Replace with actual chat ID
    const userId = localStorage.getItem("id");

    // Fetch chat data to check if the user is blocked
    const chatRef = doc(db, "chat", chatId);
    const chatDoc = await getDoc(chatRef);
    const chatData = chatDoc.data();

    if (
      chatData.blockedUsers &&
      chatData.blockedUsers.includes(parseInt(userId))
    ) {
      console.log("You are blocked from sending messages to this chat.");
      return; // Stop execution if the user is blocked
    }

    let messageContent = inputValue;
    let messageType = MessageType.TEXT;

    // Check if a file (image) is selected
    if (file) {
      const allowedTypes = ["image/png", "image/jpeg", "image/webp"];
      const maxSizeMB = 2;
      const maxSizeBytes = maxSizeMB * 1024 * 1024; // Convert MB to bytes

      // Check file type and size
      if (allowedTypes.includes(file.type) && file.size <= maxSizeBytes) {
        // Display alert or notification that image is selected (optional)
        // alert("Image selected!");

        // Upload the image and get its URL
        const imageUrl = await imagePost(file);
        if (imageUrl) {
          messageContent = imageUrl;
          messageType = MessageType.IMAGE;
        } else {
          return; // If image upload fails, do not proceed with sending the message
        }
      } else {
        alert(
          `Please select a valid image file (PNG, JPG, WEBP) less than ${maxSizeMB}MB.`
        );
        return; // Stop further execution if file type or size is invalid
      }
    }

    const vendorId = localStorage.getItem("id");

    // Create message model
    const messageModel = new MessageModel({
      createdOn: new Date(),
      message: messageContent,
      messageType: messageType,
      userId: parseInt(vendorId), // Replace with the actual user ID
    });

    // Send message using your service
    await OnSendMessageService({ messageModel, block: [] });

    // Clear input and file state, and scroll to bottom
    setInputValue("");
    setFile(null);
    scrollToBottom();
  };

  const [isInChat, setIsInChat] = useState(false);

  useEffect(() => {
    const requestPermission = async () => {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        // Generate token
        const token = await getToken(messaging, {
          vapidKey:
            "BI3e34yhGxRmTkesxjtIk2mxlwg4muhYnV5Lh4AgeGONxh7BMKaCqBkT66ceA9Yy-XKEiILXMuxifc4nFrmDop0",
        });
        console.log("Token Gen", token);

        // Define deviceId here (e.g., use a unique identifier for the device)
        const deviceId = "21690930-DCD4-4B44-9911-F8E7EE4571FF"; // Replace with your actual device ID logic
        console.log("Device ID:", deviceId);
        SaveFCMToken(deviceId, token);

        // Check chatId value in Firestore
        //   const docRef = doc(db, "chatInUser", deviceId);
        //   console.log("Document Reference:", docRef);

        //   try {
        //     const docSnap = await getDoc(docRef);
        //     // debugger;
        //     if (docSnap.exists()) {
        //       const data = docSnap.data();
        //       console.log("Document Data:", data);

        //       const chatId = data.chatId;
        //       console.log("Chat ID:", chatId);
        //       if (chatId === "" && !isInChat) {
        //         // Send token to server and show notfication
        //         // SaveFCMToken(deviceId, token);
        //         new Notification("Chat ID is empty!");
        //       }
        //     } else {
        //       console.log("No such document!");
        //     }
        //   } catch (error) {
        //     console.error("Error getting document:", error);
        //   }
      } else if (permission === "denied") {
        // alert("Permission is Denied");
      }
    };

    requestPermission();

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then(function (registration) {
          console.log("Registration successful, scope is:", registration.scope);
        })
        .catch(function (err) {
          console.log("Service worker registration failed, error:", err);
        });
    }

    // Call the checkIfInChat function to update the isInChat state
    // checkIfInChat();

    // Optional: set up a listener or interval to keep updating the isInChat state
    // const intervalId = setInterval(checkIfInChat, 1000); // Adjust the interval as needed

    // // Cleanup interval on component unmount
    // return () => clearInterval(intervalId);
  }, []);

  ///////////////////// request Permission  END //////////////

  ///   Recieve MEASSAGE

  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState(null);
  const chatid = localStorage.setItem("chatid", id);
  useEffect(() => {
    const messagesRef = collection(db, "chat", id, "messages");
    const q = query(messagesRef, orderBy("createdOn", "asc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newMessages = [];
      querySnapshot.docChanges().forEach((change) => {
        const messageData = {
          id: change.doc.id,
          ...change.doc.data(),
        };
        if (
          change.type === "added" &&
          !messages.find((msg) => msg.id === messageData.id)
        ) {
          newMessages.push(messageData);
        }
      });
      if (newMessages.length > 0) {
        setMessages((prevMessages) => [...prevMessages, ...newMessages]); // Append new messages at the end
        scrollToBottom();
      }
    });
    return () => unsubscribe();
  }, [id, messages]);

  const OnSendMessageService = async ({ messageModel, block }) => {
    try {
      // Add new message to the messages subcollection
      const messagesRef = collection(db, "chat", id, "messages");
      const newMessageRef = doc(messagesRef);
      await setDoc(newMessageRef, {
        text: messageModel.message,
        userId: messageModel.userId,
        createdOn: messageModel.createdOn.getTime(), // Assuming messageModel.createdOn is a Date object
        isRead: [messageModel.userId],
        isDeleted: [],
        block: block,
        messageType: messageModel.messageType,
      });

      // Update the chat document with the last message and modifiedOn timestamp
      const chatRef = doc(db, "chat", id);
      await updateDoc(
        chatRef,
        {
          lastMessage: {
            text: messageModel.message,
            userId: messageModel.userId,
            createdOn: messageModel.createdOn.getTime(), // Assuming messageModel.createdOn is a Date object
            isRead: [messageModel.userId],
            block: block,
            isDeleted: [],
            messageType: messageModel.messageType,
          },
          modifiedOn: Date.now(),
        },
        { merge: true }
      );
    } catch (error) {
      console.error("Error sending message: ", error);
    }
  };

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
  };

  useEffect(() => {
    // Scroll to bottom only if the last message is from the current user
    if (messages.length > 0 && messages[messages.length - 1].userId === id) {
      scrollToBottom();
    }
  }, [messages]);

  // Helper functions for formatting time and date
  function formatTime(timestamp) {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  }

  function formatDate(isoString) {
    const date = new Date(isoString);
    const day = date.getDate();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  const currentChatData = customerData.find((item) => item.id === id);
  console.log(currentChatData, "currentchatdata");

  useEffect(() => {
    if (currentChatData) {
      console.log(currentChatData, "currentchatdata");

      // Destructure and log each field to verify the data
      const {
        otherUser,
        modifiedOn,
        createdOn,
        IDS,
        myInfo,
        blockedUsers,
        chatID,
      } = currentChatData;

      console.log("otherUser:", otherUser);
      console.log("modifiedOn:", modifiedOn);
      console.log("createdOn:", createdOn);
      console.log("IDS:", IDS);
      console.log("myInfo:", currentChatData.myInfo.id);
      console.log("blockedUsers:", blockedUsers);

      // Ensure the fields are properly updated
      setChatData({
        otherUser,
        modifiedOn,
        createdOn,
        IDS,
        myInfo,
        blockedUsers,
        chatID,
      });
    }
  }, [currentChatData]);
  const customerId = chatData.myInfo?.id;
  console.log(customerId);

  useEffect(() => {
    console.log(chatData, "chatData");
  }, [chatData]);

  if (!currentChatData) return null;

  const { item } = currentChatData;

  // push notifications :

  const handleSubmited = async () => {
    const params = {
      title: " 📩 New Message",
      body: " You have unread messages waiting for you. Don't miss out, check them now!",
      data: JSON.stringify(chatData),
      userId: currentChatData.myInfo.id,
    };
    try {
      await PushNotifications(params);
    } catch (error) {
      localStorage.setItem("login", false);
      // toastr.error("Login failed");
    }
  };

  const handlesend = () => {
    handleSendMessage();
    handleSubmited();
  };

  //  Blocked User

  const userId = localStorage.getItem("id"); // Assuming this is the current user's ID

  async function blockUser(chatId, userId) {
    try {
      const chatRef = doc(db, "chat", chatId);
      await updateDoc(chatRef, {
        block: {
          block: true,
          id: parseInt(userId),
        },
        modifiedOn: Date.now(),
      });
      console.log("User blocked successfully");
      return true;
    } catch (e) {
      console.error("Error blocking user:", e.message);
      return false;
    }
  }

  const handleClick = async () => {
    const success = await blockUser(id, userId);
    if (success) {
      console.log("User blocked successfully");
      navigate("/chat-managment");
    } else {
      console.error("Failed to block user");
    }
  };

  //  ///////////////       DELETE MESSGAES       ///////////////////

  //   const deleteMessage = async (chatId, userId) => {
  //     const db = getFirestore();
  //     let result = false;

  //     if (typeof userId !== "number" && typeof userId !== "string") {
  //       console.error("Invalid userId:", userId);
  //       return result;
  //     }

  //     try {
  //       const messagesRef = collection(db, "chat", chatId, "messages");
  //       const snapshot = await getDocs(messagesRef);

  //       console.log(`Found ${snapshot.size} messages to process.`);

  //       for (const docSnapshot of snapshot.docs) {
  //         try {
  //           console.log(`Deleting document ${docSnapshot.id}`);
  //           await deleteDoc(docSnapshot.ref); // Physically delete the document
  //           console.log(`Document ${docSnapshot.id} deleted.`);
  //         } catch (e) {
  //           console.error(`Failed to delete document ${docSnapshot.id}:`, e);
  //         }
  //       }

  //       console.log(`Updating lastMessage document for chatId ${chatId}`);
  //       await setDoc(
  //         doc(db, "chat", chatId),
  //         {
  //           lastMessage: {
  //             isDeleted: arrayUnion(userId),
  //           },
  //           modifiedOn: Date.now(),
  //         },
  //         { merge: true }
  //       );

  //       console.log(`Last message document for chatId ${chatId} updated.`);
  //       result = true;
  //     } catch (e) {
  //       console.error("Error deleting messages:", e);
  //     }

  //     return result;
  //   };

  //   // Usage in your component
  //   const handleDeleteClick = async (chatId, customerId) => {
  //     if (!customerId) {
  //       console.error("customerId is not defined");
  //       return;
  //     }

  //     // Convert customerId to a number if necessary
  //     customerId = Number(customerId);
  //     if (isNaN(customerId)) {
  //       console.error("customerId is not a valid number");
  //       return;
  //     }

  //     const success = await deleteMessage(chatId, customerId);
  //     if (success) {
  //       console.log("Messages deleted successfully");
  //     } else {
  //       console.error("Failed to delete messages");
  //     }
  //   };
  

  return (
    <Main>
      <div className="container-fluid">
        <div className="heading-flex">
          <div className="heading">
            <p>Chat Management</p>
            {/* <p>This is customer id: {id}</p> */}
          </div>
        </div>
        {currentChatData && (
          <Card>
            <div className="card card-customize">
              <div className="card-body">
                <div className="card-border">
                  <div className="main-flex">
                    <div className="img-text-flex">
                      <img
                        src={BackButton}
                        alt="back"
                        style={{ cursor: "pointer" }}
                        onClick={ChatManagement}
                      />
                      <div className="img-text-flex">
                        <div>
                          <img
                            src={
                              currentChatData.myInfo &&
                              currentChatData.myInfo.profile_pic
                                ? `${currentChatData.myInfo.profile_pic}`
                                : lotuspro
                            }
                            alt="profile"
                            className="ms-3"
                            style={{
                              width: "38px",
                              height: "38px",
                              borderRadius: "62px",
                            }}
                          />
                        </div>
                        <div>
                          <p className="profile-name">
                            {" "}
                            {currentChatData.myInfo
                              ? currentChatData.myInfo.name
                              : ""}
                          </p>
                          {/* <p className="last-active">Last Active 3 hours ago</p> */}
                        </div>
                      </div>
                    </div>
                    <div className="block-button-width">
                      {/* <button
                        type="button"
                        className="block-button"
                        onClick={handleClick}
                      >
                        <img src={Block} alt="block" /> Block
                      </button> */}
                      {/* <button
                        type="button"
                        className="block-button"
                        onClick={() => handleDeleteClick(chatId, customerId)} // Pass chatId here
                      >
                        <img src={Block} alt="block" /> Delete
                      </button> */}
                      &nbsp;
                      {/* <button
                        type="button"
                        className="report-button"
                        onClick={HandleReportsSection}
                      >
                        <img src={Report} alt="report" /> Report
                      </button> */}
                    </div>
                  </div>
                  <p className="bottom-border"></p>
                  <div className="chat-container">
                    <div className="today-flex">
                      <p className="today-text">
                        {currentChatData.myInfo
                          ? formatDate(currentChatData.myInfo.updatedAt)
                          : ""}
                      </p>
                    </div>
                    {messages.map((message, index) => (
                      <React.Fragment key={index}>
                        {message.img && (
                          <div
                            className={
                              message.userId.toString() ===
                              currentChatData.myInfo.id.toString()
                                ? "share-image-our"
                                : "share-image-other"
                            }
                          >
                            <img
                              src={message.img}
                              alt="share-img"
                              aria-hidden="true"
                              style={{
                                width: "283px",
                                height: "95px",
                                borderRadius: "5px",
                              }}
                            />
                          </div>
                        )}
                        <div
                          className={
                            message.userId.toString() ===
                            currentChatData.myInfo.id.toString()
                              ? "padding-left"
                              : "our-chat-flex"
                          }
                          style={{ paddingBottom: "5px" }}
                        >
                          {message.userId.toString() ===
                          currentChatData.myInfo.id.toString() ? (
                            <img
                              src={`${
                                currentChatData.myInfo.profile_pic || lotuspro
                              }
                              `}
                              alt="profile"
                              style={{
                                width: "38px",
                                height: "38px",
                                borderRadius: "62px",
                              }}
                            />
                          ) : (
                            <img
                              src={`${process.env.REACT_APP_BASE_URL}${
                                currentChatData.otherUser.profile_pic ||
                                lotuspro
                              }`}
                              alt="profile"
                              style={{
                                width: "38px",
                                height: "38px",
                                borderRadius: "62px",
                              }}
                            />
                          )}

                          {message.messageType === "text" && (
                            <p
                              className={
                                message.userId.toString() ===
                                currentChatData.myInfo.id.toString()
                                  ? "other-chat"
                                  : "our-chat"
                              }
                            >
                              {message.text}
                              <span
                                className={
                                  message.userId.toString() ===
                                  currentChatData.myInfo.id.toString()
                                    ? "time-text-other-chat"
                                    : "time-text-our-chat"
                                }
                              >
                                {formatTime(message.createdOn)}
                              </span>
                            </p>
                          )}
                          {message.messageType === "image" && (
                            <div>
                              <img
                                src={`${process.env.REACT_APP_BASE_URL}${message.text}`}
                                alt="message"
                                style={{
                                  width: "283px",
                                  height: "95px",
                                  borderRadius: "10px",
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </React.Fragment>
                    ))}

                    <div ref={messagesEndRef} />
                    <ChatInput>
                      <div className="field-flex">
                        <label
                          htmlFor="file-upload"
                          style={{ cursor: "pointer" }}
                        >
                          <img src={Clip} alt="clip-img" />
                        </label>
                        <input
                          id="file-upload"
                          type="file"
                          style={{ display: "none" }}
                          accept=".png, .jpg, .jpeg, .webp"
                          onChange={handleFileChange} // Set the file to state on change
                        />
                        <div style={{ width: "91%", position: "relative" }}>
                          <textarea
                            type="text"
                            placeholder="Type a message"
                            className="chat-input"
                            value={inputValue}
                            onChange={handleChange}
                            style={{ minHeight: "51px", maxHeight: "80px" }}
                          ></textarea>
                          <button
                            type="submit"
                            style={{ display: "none" }}
                          ></button>
                          <img
                            src={Vector}
                            alt="vector"
                            className="absolute-position"
                            onClick={handlesend}
                          />
                        </div>
                      </div>
                    </ChatInput>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        )}
      </div>
      {reportComment && (
        <ChatReportModal
          onOpen={HandleReportsSection}
          onClose={() => setReportComment(false)}
        />
      )}
    </Main>
  );
};

export default Chat;
