import React, { useState, useEffect } from "react";
import Home1 from "../../../assets/img/catalogs/home1.png";
import Home2 from "../../../assets/img/catalogs/home2.png";
import Home3 from "../../../assets/img/catalogs/home3.png";
import Home4 from "../../../assets/img/catalogs/home4.png";
import Home5 from "../../../assets/img/catalogs/home5.png";
import Home6 from "../../../assets/img/catalogs/home6.png";
import Home7 from "../../../assets/img/catalogs/home7.png";
import View from "../../../assets/img/catalogs/view.png";
import Copy from "../../../assets/img/catalogs/edit.svg";
import Delete from "../../../assets/img/catalogs/delete.svg";
import PaginationRounded from "components/Pagination";
import TransitionsModal from "components/modal";
import AddImgModal from "../add-img-modal";
import { Product, Main } from "./style";
import { useLocation, useParams } from "react-router-dom";
import useHooks from "./useHooks";
import DeleteModal from "components/modal/delete";
import LotusPro from "assets/img/common/lotuspro.png";
import Spiner from "components/spinner";
import toastr from "toastr";
import Viewer from "react-viewer";
const MycatalogDetail = () => {
  const [cardVisible, setCardVisible] = useState(true);
  const { id } = useParams();
  const { getSubcatalogs, SubcatalogsDelete, getSingleMaincatalog } =
    useHooks();
  const [isLoading, setIsLoading] = useState(false);
  const [imageModalItemID, setimageModalItemID] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [subCatalogList, setsubCatalogList] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteID, setDeleteID] = useState(null);
  // One time fetch on the start of page
  const FetchSubCatalog = async () => {
    console.log(id, "id");
    const formData = {
      catalogId: id,
      page: currentPage,
      perPage: 0,
    };
    console.log(formData);
    setIsLoading(true);
    try {
      const response = await getSubcatalogs(formData);
      console.log("response is subcatalog ", response);
      setsubCatalogList(response.data);
    } catch (error) {
      toastr.error("Error Here", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (!modalOpen) {
      FetchSubCatalog();
    }
  }, [modalOpen, currentPage]);

  // Delete here

  const handleOpenDeleteModal = (id) => {
    setDeleteID(id);
    setDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteID(null);
    setDeleteModalOpen(false);
  };
  const handleConfirmDelete = async () => {
    if (deleteID !== null) {
      try {
        await SubcatalogsDelete(deleteID);
        const newdata = subCatalogList.filter(
          (catalog) => catalog.id !== deleteID
        );
        setsubCatalogList(newdata);
        handleCloseDeleteModal();
      } catch (error) {
        console.error("Error deleting catalog:", error);
      }
    }
  };
  useEffect(() => {
    if (!deleteModalOpen) {
      FetchSubCatalog();
    }
  }, [deleteModalOpen, currentPage]);

  // open the Add image modal
  const [responseEdit, setResonseEdit] = useState([]);
  const handleEdit = (id) => {
    setimageModalItemID(id);
    setModalOpen(true);

    // console.log("handleEdit id on items number", id);
  };
  const handleModalOpen = (e) => {
    e.preventDefault();
    setModalOpen(true);
    setimageModalItemID("");
  };
  useEffect(() => {
    if (!modalOpen) {
      FetchSubCatalog();
    }
  }, [currentPage, modalOpen]);

  const [singleCataloglist, setSungleCataloglist] = useState("");
  const fetchSingleMainCatalog = async () => {
    const response = await getSingleMaincatalog(id);
    setSungleCataloglist(response.data);
    // console.log("hahehehehehaha", response.data);
  };
  useEffect(() => {
    fetchSingleMainCatalog();
  }, []);

    // image viwer
    const [visible, setVisible] = useState(false);
    const [currentImage, setCurrentImage] = useState(null);
  
    const handleImageClick = (imageSrc) => {
      setCurrentImage(imageSrc);
      setVisible(true);
    };
  return (
    <>
      {/* This component is My Catalog detail
            Parent id is {id} */}
      <Main>
        <div className="container-fluid">
          <Product>
            {isLoading ? (
              <Spiner />
            ) : (
              <>
                <div className="heading-flex">
                  <p className="heading m-0">My Catalogs</p>
                  <button
                    type="submit"
                    className="category-btn create-button"
                    onClick={handleModalOpen}
                  >
                    {/* <AddImgModal catalogId={id} /> */}
                    Add Images
                  </button>
                </div>
                <AddImgModal
                  catalogId={id}
                  open={modalOpen}
                  onClose={() => setModalOpen(false)}
                  imageModalItemID={imageModalItemID}
                />
                <DeleteModal
                  open={deleteModalOpen}
                  handleClose={handleCloseDeleteModal}
                  handleDelete={handleConfirmDelete}
                />
                <div className="card border-0 mt-3">
                  <div className="card-body">
                    <div className="single-description">
                      <img
                        src={
                          singleCataloglist.image
                            ? process.env.REACT_APP_MEDIA_URL +
                              singleCataloglist.image
                            : LotusPro
                        }
                        alt="catalogImage"
                        className="singleCatalog-Image"
                      />

                      <div className="description-container">
                        <p className="m-0 product-name">
                          {singleCataloglist?.name}{" "}
                        </p>
                        {singleCataloglist?.catalogImagesCount >= 2 ? (
                          <p className="product-count">
                            {singleCataloglist.catalogImagesCount} Photo
                          </p>
                        ) : (
                          <p className="product-count">
                            {singleCataloglist.catalogImagesCount} Photos
                          </p>
                        )}
                        <p className="product-para">
                          {singleCataloglist.description}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-flex mt-3">
                  {subCatalogList.length > 0 ? (
                    subCatalogList.map((items, index) => (
                      <div key={index} className="mt-4 card-width">
                        <div
                          style={
                            {
                              // backgroundColor: "white",
                            }
                          }
                        >
                          <img
                            src={process.env.REACT_APP_MEDIA_URL + items.image}
                            className="img"
                            alt={items.heading}
                            onClick={() => {
                              setCurrentImage(
                                `${process.env.REACT_APP_BASE_URL}${items.image}`
                              );
                              setVisible(true);
                            }}
                          />
                          {visible && currentImage && (
                            <Viewer
                              visible={visible}
                              onClose={() => setVisible(false)}
                              images={[{ src: currentImage, alt: "Image" }]} // Only show the clicked image
                              noToolbar={true}
                              noFooter={true}
                              drag={false}
                              noDrag={true}
                              noResetZoomAfterChange={true}
                              disableMouseZoom={true}
                            />
                          )}
                          <div className="mt-3">
                            <div className="img-flex">
                              <div>
                                <p className="m-0 heading-card2">
                                  {items.name}
                                </p>
                                <p className="m-0 heading-card2">
                                  {items.description}
                                </p>
                              </div>
                              <div style={{ display: "flex", gap: "5px" }}>
                                <img
                                  src={Copy}
                                  alt="Copy Icon"
                                  onClick={() => handleEdit(items.id)}
                                  className="edit-icon"
                                />
                                <img
                                  src={Delete}
                                  alt="Delete Icon"
                                  onClick={() =>
                                    handleOpenDeleteModal(items.id)
                                  }
                                  className="delete-icon"
                                />
                              </div>
                            </div>
                            <div
                              className="mt-2"
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "6px",
                              }}
                            >
                              {items.tags.length >= 1 &&
                                items.tags.map((tag, tagIndex) => (
                                  <div key={tagIndex}>
                                    <button className="transitional">
                                      {tag}
                                    </button>{" "}
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="no-record">
                      <div>No Record Found</div>
                    </div>
                  )}
                </div>
              </>
            )}
          </Product>
        </div>
      </Main>
    </>
  );
};

export default MycatalogDetail;
