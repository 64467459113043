import React, { useEffect, useState } from "react";
import { Main } from "./style";
import useHook from "./useHook";
import Spiner from "components/spinner";

const Notifications = () => {
  const { GetNotifications, ReadNotifications } = useHook();
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);

  const loadNotifications = async (page) => {
    const params = {
      page: page.toString(),
      perPage: "10", // Load 10 notifications per page
    };
    try {
      const response = await GetNotifications(params);
      const newNotifications = response.data.results;

      // Append new notifications to the existing list
      setNotifications((prevNotifications) => [
        ...prevNotifications,
        ...newNotifications,
      ]);

      // Mark loading state as false
      setLoading(false);
      setLoadingMore(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // Initial load of notifications
    loadNotifications(page);

    ReadNotifications(); // Mark notifications as read
  }, [page]);

  const handleLoadMore = () => {
    setLoadingMore(true);
    setPage((prevPage) => prevPage + 1);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <Main>
      {loading ? (
        <Spiner />
      ) : (
        <div className="container-fluid">
          <div className="heading-flex">
            <h1 className="heading m-0">Notifications</h1>
          </div>

          
          {notifications.map((items , index) => (
            <div key={index} className="card border-0 mb-2">
              <div className="padding">
                <div className="flex">
                  <div>
                    {items.title === "Message" ? (
                      <p className="m-0 notification-heading">
                        🎉 New Message! 🚀
                      </p>
                    ) : (
                      <p className="m-0 notification-heading"> {items.title}</p>
                    )}
                    {items.title === "Message" ? (
                      <p className="m-0 notification-subheading">
                        {/* Parse the data field and display the otherUser's name */}
                        You have a new message from &nbsp;
                        {JSON.parse(items.data).myInfo.name}
                      </p>
                    ) : (
                      <p className="m-0 notification-subheading">
                        {items.message}
                      </p>
                    )}
                  </div>
                  <div>
                    <p className="m-0 time">
                      {formatDate(items.timestamp)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}

          {loadingMore && <Spiner />}
          {!loadingMore && (
            <div className="load-more" onClick={handleLoadMore}>
              <p className="load"> See more</p>
            </div>
          )}
        </div>
      )}
    </Main>
  );
};

export default Notifications;
