// import React, { useEffect, useState } from 'react'
// import { Main } from './style'
// import logo from '../../../assets/img/user-management/wallogo.png'
// import { useLocation } from 'react-router-dom';
// import { Product } from '../my-catalog/style';
// import { FaDownload } from "react-icons/fa6";
// import { BsFillPrinterFill } from "react-icons/bs";
// import jsPDF from 'jspdf';
// import html2canvas from 'html2canvas';
// const UserInvoice = () => {
//     const location = useLocation();
//     const { orderhistory, id } = location.state || {};
//     console.log("Order history is", orderhistory)
//     console.log("Order history id is", id)

//     // starts from here
//     let orderProductsArray = [];
//     let userArray = [];
//     let vendorArray = [];

//     // Loop through each entry in data (assuming it's an array of orders)
//     orderhistory.forEach(order => {
//         // Extract user and vendor data
//         const { user, vendor, orderproducts } = order;

//         // Store user and vendor data
//         userArray.push(user);
//         vendorArray.push(vendor);

//         // Store orderproducts data (assuming it's an array)
//         orderproducts.forEach(orderProduct => {
//             orderProductsArray.push(orderProduct);
//         });
//         // productlist.forEach(eachproduct=>{
//         //     productlistArray.push(eachproduct)
//         // })
//     });

//     // Output or further process the extracted arrays
//     console.log('User Array:', userArray);
//     console.log('Vendor Array:', vendorArray);
//     console.log('Order Products Array:', orderProductsArray);
//     // date conversion
//     const formatDate = (dateString) => {
//         const options = { year: 'numeric', month: 'short', day: 'numeric' };
//         const date = new Date(dateString);
//         return date.toLocaleDateString('en-US', options);
//     };
//     const generatePDF = () => {
//         const input = document.getElementById('invoice'); // Assuming 'invoice' is the ID of the element to capture
//         // Ensure images are loaded before capturing
//         Promise.all([
//             ...vendorArray.map(item => {
//                 return new Promise((resolve, reject) => {
//                     const img = new Image();
//                     img.src = item.profile_pic; // Assuming profile_pic is the URL
//                     img.onload = resolve;
//                     img.onerror = reject;
//                     console.log("image path",item.profile_pic)
//                 });
//             })
//         ]).then(() => {
//             html2canvas(input, {
//                 scale: 1, // Adjust scale to make content smaller
//             }).then((canvas) => {
//                 const pdf = new jsPDF({
//                     orientation: 'portrait', // 'portrait' or 'landscape'
//                     unit: 'mm', // units: mm, cm, in, px
//                     format: 'a4', // page size: a4, letter, etc.
//                 });

//                         // Calculate dimensions of the PDF page
//                         const pdfWidth = pdf.internal.pageSize.getWidth();
//                         const pdfHeight = pdf.internal.pageSize.getHeight();

//                         // Calculate dimensions and positioning of the captured canvas/image
//                         const imgWidth = canvas.width;
//                         const imgHeight = canvas.height;

//                         // Calculate aspect ratio of the image to fit within the PDF page
//                         const ratio = imgWidth / imgHeight;

//                         // Ensure the image fits within the PDF page, considering margins (here using 10mm margin)
//                         let imgPosition = 10; // Starting position, can be adjusted

//                         if (ratio > 1) {
//                             // Landscape orientation
//                             const pdfHeightAdjusted = pdfWidth / ratio;
//                             pdf.addImage(canvas.toDataURL('image'), 'PNG', 0, imgPosition, pdfWidth, pdfHeightAdjusted);
//                         } else {
//                             // Portrait orientation
//                             const pdfWidthAdjusted = pdfHeight * ratio;
//                             pdf.addImage(canvas.toDataURL('image'), 'PNG', (pdfWidth - pdfWidthAdjusted) / 2, imgPosition, pdfWidthAdjusted, pdfHeight);
//                         }

//                         // Save the PDF with a specific name
//                         pdf.save('invoice.pdf');
//                     })
//                     .catch((error) => {
//                         console.error('Error generating PDF:', error);
//                     });
//                 }).catch((error) => {
//                     console.error('Error loading images:', error);
//                 });
//             };

//             return (
//                 <Main>
//                     <div className='background-color'>
//                         <div className='container-fluid'>
//                             <div className='heading-flex'>
//                                 <p className='heading m-0'>Invoice </p>
//                             </div>
//                             <div id='invoice'>
//                                 <div className='card mb-5 border-0'>
//                                     <div className="card-body">
//                                         <div className='d-flex justify-content-between'>
//                                             <div>
//                                                 <div>
//                                                     <p className='invoice2'>Invoice </p>
//                                                 </div>
//                                                 <div className='d-flex align-items-center'>
//                                                     <p className='status m-0'>Status</p>
//                                                     {orderhistory.map((items) => (
//                                                         <button type='submit' className='process-button ms-2'>{items.status}</button>
//                                                     ))}

//                                                 </div>
//                                             </div>
//                                             {vendorArray.map((items) => (
//                                                 <div className='text-end'>
//                                                     {/* <img src={logo} alt="logo" /> */}
//                                                     <img src={items.profile_pic} alt="logo" style={{ width: "60px", height: "60px", borderRadius: "12px" }} />

//                                                     <h6 className='style-jenifer'>{items.name}</h6>
//                                                     <div className='style-address'>
//                                                         <p>{items.address} <p className='m-0'>{items.phone}</p>{items.email}</p>
//                                                     </div>
//                                                 </div>
//                                             )
//                                             )
//                                             }

//                                         </div>
//                                         <hr className='' />
//                                         {userArray.map((items) => (
//                                             <>
//                                                 <div className='d-flex justify-content-between align-items-center'>

//                                                     <div>
//                                                         <p className='style-date m-0'>DATE</p>
//                                                         <p className='status m-0'>{formatDate(items.createdAt)}</p>
//                                                     </div>
//                                                     <div>
//                                                         <div className='me-5 pe-5'>
//                                                             <p className='invoice2 m-0'>Invoice </p>
//                                                             <p className='status m-0'>{items.id}</p>

//                                                         </div>
//                                                     </div>
//                                                     <div className='text-end'>
//                                                         {/* <img src={logo} alt="logo" /> */}
//                                                         <h6 className='style-jenifer'>INVOICE &nbsp; &nbsp;TO</h6>

//                                                         <div className='style-address'>
//                                                             <p>{items.name}<p>{items.address} </p> <p className='m-0'>{items.phone}</p>{items.email}</p>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </>
//                                         ))}

//                                         <div className='card' style={{ border: "1px solid #D9DCE0" }}>
//                                             <div className="bg-white" style={{ borderRadius: "9px" }}>
//                                                 <table className='w-100'>
//                                                     <thead style={{ backgroundColor: "#F8FAFC", height: "60px" }} >
//                                                         <tr className='flex ms-2 table-head' >
//                                                             <th className='center'>SR no.</th>
//                                                             <th>PRODUCT TITLE</th>
//                                                             <th className='center'>QUANTITY</th>
//                                                             <th className='center'>ITEM PRICE</th>
//                                                             <th className='center'>AMOUNT</th>
//                                                         </tr>
//                                                     </thead>
//                                                     <tbody className='waleed-margin' >
//                                                         {orderProductsArray.map((items) => (
//                                                             <tr className="mt-3 flex2 style-table" style={{ paddingTop: '10%' }}>
//                                                                 <td style={{ paddingBlock: '15px' }} className='center'>{items.id}</td>
//                                                                 <td style={{ paddingBlock: '15px' }}>{items.product.name}</td>
//                                                                 <td style={{ paddingBlock: '15px' }} className='center'>{items.product.qnty}</td>
//                                                                 <td style={{ paddingBlock: '15px' }} className='center'>{items.itemPrice}</td>
//                                                                 {/* <td style={{ paddingTop: '3%', width: "10%" }} ><p className='return'>Returned</p></td> */}
//                                                                 <td style={{ paddingBlock: '15px' }} className='center'>  {parseInt(items.product.productPrice) + parseInt(items.product.salePrice)}</td>
//                                                             </tr>
//                                                         ))}
//                                                     </tbody>
//                                                 </table>
//                                             </div>
//                                         </div>

//                                         <div className='card mt-4' style={{ backgroundColor: "#F8FAFC", border: "1px solid #D9DCE0" }}>
//                                             <div className="card-body" style={{ padding: " 20px 32px" }}>
//                                                 <table className='w-100'>
//                                                     <thead className='style-payment'>
//                                                         <tr className='flex ms-2' >
//                                                             <th className='' style={{ width: "25%" }}>PAYMENT METHOD	</th>
//                                                             <th style={{ width: "25%" }}>SHIPPING COST	</th>
//                                                             <th className='' style={{ width: "25%" }}>DISCOUNT	</th>
//                                                             <th className='text-end'>TOTAL AMOUNT</th>
//                                                         </tr>
//                                                     </thead>
//                                                     <tbody className='waleed-margin' >
//                                                         {orderProductsArray.map((items) => (
//                                                             <tr className=" flex2 style-pay" >
//                                                                 <td className=''>Cash</td>
//                                                                 <td >Nill</td>
//                                                                 <td className=''>{items.discountPercent}</td>
//                                                                 <td className='text-end amount'>{items.totalPrice}</td>
//                                                             </tr>
//                                                         ))}
//                                                     </tbody>
//                                                 </table>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>

//                             </div>
//                         </div>
//                     </div>
//                     <div className='d-flex flex-row justify-content-between mx-5'>
//                         <button className='download-pdf' onClick={generatePDF}><FaDownload /> Downlaod Invoice</button>
//                         <button className='download-pdf'><BsFillPrinterFill /> Print Invoice</button>
//                     </div>
//                 </Main>
//             )
//         }

// export default UserInvoice

import React, { useEffect, useState } from "react";
import { Main } from "./style";
import { useLocation } from "react-router-dom";
import { FaDownload } from "react-icons/fa6";
import { BsFillPrinterFill } from "react-icons/bs";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import useHooks from "./useHooks";
import Spiner from "components/spinner";
import Logo from "../../../assets/img/user-invoice/invoice.png";
// const UserInvoice = () => {
//   const { orderGetID } = useHooks();

//   const [loading, setLoading] = useState(true);

//   const location = useLocation();
//   const { itemId } = location.state || {};
//   console.log("Order id is", itemId);
//   const Id = itemId;
//   const [orderhistory, setOrderHistory] = useState("");
//   const fetchOrder = async () => {
//     try {
//       const response = await orderGetID(Id);
//       // if (Array.isArray(response)) {
//       setOrderHistory(response.data);
//       console.log("orderget ", response);
//       //   } else {
//       // console.error('Expected an array but got:', response);
//       //   }
//     } catch (error) {
//       console.log("error ", error);
//     } finally {
//       setLoading(false);
//     }
//   };
//   useEffect(() => {
//     fetchOrder();
//   }, []);
//   // if (!orderhistory) return <div>Loading...</div>;
//   const { user, vendor, createdAt, address } = orderhistory;

//   const formatDate = (dateString) => {
//     const options = { year: "numeric", month: "short", day: "numeric" };
//     const date = new Date(dateString);
//     return date.toLocaleDateString("en-US", options);
//   };
//   const generatePDF = () => {
//     const input = document.getElementById("invoice"); // Assuming 'invoice' is the ID of the element to capture

//     // Load all images and resolve promises when all are loaded
//     const loadImage = (url) => {
//       return new Promise((resolve, reject) => {
//         const img = new Image();
//         img.crossOrigin = "anonymous"; // Ensure CORS support
//         img.onload = () => resolve(img);
//         img.onerror = (err) => reject(err);
//         img.src = url;
//       });
//     };

//     // Map over vendorArray to load all images
//     const imagePromises = orderhistory?.vendor?.profile_pic;
//     const imageUser = orderhistory?.user?.profile_pic;

//     // Wait for all images to load before proceeding
//     Promise.all(imagePromises, imageUser)
//       .then((images) => {
//         // Use html2canvas to capture the 'invoice' div
//         html2canvas(input, {
//           scale: 1, // Adjust scale to make content smaller
//           logging: true, // Enable logging for debugging
//           allowTaint: true, // Allow rendering content from other domains
//           useCORS: true, // Use CORS to fetch images properly
//         })
//           .then((canvas) => {
//             // Create a new jsPDF instance
//             const pdf = new jsPDF({
//               orientation: "portrait", // 'portrait' or 'landscape'
//               unit: "mm", // units: mm, cm, in, px
//               format: "a4", // page size: a4, letter, etc.
//             });

//             // Calculate dimensions of the PDF page
//             const pdfWidth = pdf.internal.pageSize.getWidth();
//             const pdfHeight = pdf.internal.pageSize.getHeight();

//             // Calculate dimensions and positioning of the captured canvas/image
//             const imgWidth = canvas.width;
//             const imgHeight = canvas.height;

//             // Calculate aspect ratio of the image to fit within the PDF page
//             const ratio = imgWidth / imgHeight;

//             // Ensure the image fits within the PDF page, considering margins (here using 10mm margin)
//             let imgPosition = 10; // Starting position, can be adjusted

//             if (ratio > 1) {
//               // Landscape orientation
//               const pdfHeightAdjusted = pdfWidth / ratio;
//               pdf.addImage(
//                 canvas.toDataURL("image/png"),
//                 "PNG",
//                 0,
//                 imgPosition,
//                 pdfWidth,
//                 pdfHeightAdjusted
//               );
//             } else {
//               // Portrait orientation
//               const pdfWidthAdjusted = pdfHeight * ratio;
//               pdf.addImage(
//                 canvas.toDataURL("image/png"),
//                 "PNG",
//                 (pdfWidth - pdfWidthAdjusted) / 2,
//                 imgPosition,
//                 pdfWidthAdjusted,
//                 pdfHeight
//               );
//             }

//             // Save the PDF with a specific name
//             pdf.save("invoice.pdf");
//           })
//           .catch((error) => {
//             console.error("Error generating PDF:", error);
//           });
//       })
//       .catch((error) => {
//         console.error("Error loading images:", error);
//       });
//   };
//   const handlePrint = () => {
//     const originalContents = document.body.innerHTML;
//     const printContents = document.querySelector(".printable").innerHTML;

//     document.body.innerHTML = `<div>${printContents}</div>`;

//     window.print();

//     document.body.innerHTML = originalContents;
//   };
const UserInvoice = () => {
  const { orderGetID } = useHooks();
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const { itemId } = location.state || {};
  const [orderhistory, setOrderHistory] = useState(null);

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const response = await orderGetID(itemId);
        setOrderHistory(response.data);
      } catch (error) {
        console.error("Error fetching order:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchOrder();
  }, [itemId]);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };

  const generatePDF = () => {
    const input = document.getElementById("invoice"); // Assuming 'invoice' is the ID of the element to capture

    // Load all images and resolve promises when all are loaded
    const loadImage = (url) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "anonymous"; // Ensure CORS support
        img.onload = () => resolve(img);
        img.onerror = (err) => reject(err);
        img.src = url;
      });
    };

    // Map over vendorArray to load all images
    const imagePromises = orderhistory?.vendor?.profile_pic;
    const imageUser = orderhistory?.user?.profile_pic;

    // Wait for all images to load before proceeding
    Promise.all(imagePromises, imageUser)
      .then((images) => {
        // Use html2canvas to capture the 'invoice' div
        html2canvas(input, {
          scale: 1, // Adjust scale to make content smaller
          logging: true, // Enable logging for debugging
          allowTaint: true, // Allow rendering content from other domains
          useCORS: true, // Use CORS to fetch images properly
        })
          .then((canvas) => {
            // Create a new jsPDF instance
            const pdf = new jsPDF({
              orientation: "portrait", // 'portrait' or 'landscape'
              unit: "mm", // units: mm, cm, in, px
              format: "a4", // page size: a4, letter, etc.
            });

            // Calculate dimensions of the PDF page
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();

            // Calculate dimensions and positioning of the captured canvas/image
            const imgWidth = canvas.width;
            const imgHeight = canvas.height;

            // Calculate aspect ratio of the image to fit within the PDF page
            const ratio = imgWidth / imgHeight;

            // Ensure the image fits within the PDF page, considering margins (here using 10mm margin)
            let imgPosition = 10; // Starting position, can be adjusted

            if (ratio > 1) {
              // Landscape orientation
              const pdfHeightAdjusted = pdfWidth / ratio;
              pdf.addImage(
                canvas.toDataURL("image/png"),
                "PNG",
                0,
                imgPosition,
                pdfWidth,
                pdfHeightAdjusted
              );
            } else {
              // Portrait orientation
              const pdfWidthAdjusted = pdfHeight * ratio;
              pdf.addImage(
                canvas.toDataURL("image/png"),
                "PNG",
                (pdfWidth - pdfWidthAdjusted) / 2,
                imgPosition,
                pdfWidthAdjusted,
                pdfHeight
              );
            }

            pdf.save("invoice.pdf");
          })
          .catch((error) => {
            console.error("Error generating PDF:", error);
          });
      })
      .catch((error) => {
        console.error("Error loading images:", error);
      });
  };
  const handlePrint = () => {
    const originalContents = document.body.innerHTML;
    const printContents = document.querySelector(".printable").innerHTML;

    document.body.innerHTML = `<div>${printContents}</div>`;

    window.print();

    document.body.innerHTML = originalContents;
  };

  if (loading) {
    return <Spiner />;
  }

  const { user, vendor, createdAt, address } = orderhistory || {};
  const statusColors = {
    pending: "#FF8F501A",
    delivered: "#209A421A",
    declined: "#97f0f1",
    returned: "#89c8ff",
    cancelled: "#FF00001A",
    processing: "#00D1FF1A",
    shipped: "#ffdbbb",
  };
  const textColor = {
    pending: "#FF8F50",
    delivered: "#209A42",
    declined: "#149c9e",
    returned: "#002647",
    cancelled: "#FF0000",
    processing: "#00D1FF",
    shipped: "#FF8F50",
  };

  return (
    <Main>
      <style>
        {`
                    @media print {
                        .sidebar, .header {
                            display: none !important;
                        }
                        .content {
                            width: 100%;
                        }
                        body {
                            margin: 0;
                            padding: 0;
                        }
                    }
                        
                `}
      </style>
      {loading ? (
        <Spiner />
      ) : (
        <>
          <div className="background-color printable">
            <div className="container-fluid">
              <div className="heading-flex">
                <p className="heading m-0">Invoice </p>
              </div>
              {/* userId is {id}
                    current orderID is {itemId} */}
              <div id="invoice">
                <div className="card mb-5 border-0">
                  <div className="card-body">
                    <div className="d-flex justify-content-between ">
                      <div>
                        <div className="">
                          <p className="invoice2 m-0">Invoice No. </p>
                          <p className="status m-0">{orderhistory.id}</p>
                        </div>
                      </div>
                      <div className=" align-items-center text-center">
                        <p className="invoice2">Status</p>

                        <p className=" ms-2 border-0">
                          {/* {orderhistory.status} */}
                          <span
                            style={{
                              backgroundColor:
                                statusColors[orderhistory.status] ||
                                "transparent",
                              color:
                                textColor[orderhistory.status] || "transparent",
                              padding: "3px",
                              borderRadius: "10px",
                              fontSize: "12px",
                              paddingLeft: "10px",
                              paddingRight: "10px",
                              textTransform: "capitalize",
                            }}
                          >
                            {orderhistory.status}
                          </span>
                        </p>
                      </div>
                      {/* {vendorArray.map((items) => ( */}
                      <div className="">
                        <div>
                          <p className="style-date m-0">Date</p>
                          <p className="status m-0">{formatDate(createdAt)}</p>
                        </div>
                      </div>
                    </div>
                    <hr className="" />

                    <div className="invoice-flex pb-3">
                      <div>
                        <div>
                          <h6 className="invoice2">Invoice&nbsp;&nbsp;From</h6>
                        </div>

                        <img
                          src={`${process.env.REACT_APP_MEDIA_URL}${orderhistory.vendor?.businessInfo?.businessLogo}`}
                          alt="logo"
                          style={{
                            width: "60px",
                            height: "60px",
                            borderRadius: "10px",
                            paddingBlock: "5px",
                          }}
                        />

                        <h6 className="style-jenifer">
                          {orderhistory.vendor?.businessInfo?.businessName}
                        </h6>
                        <div className="style-address">
                          <p>
                            <p className="m-0">{vendor?.phone}</p>
                            <p>{vendor?.email}</p>
                            {vendor?.businessInfo?.addressLine1},
                            {vendor?.businessInfo?.city},
                            {vendor?.businessInfo?.state},
                            {/* {vendor?.addresses?.[0]?.country}, */}
                            {vendor?.businessInfo?.zip}
                          </p>
                        </div>
                      </div>
                      {/* {userArray.map((items, index) => (
                                    <> */}
                      {/* <div>
                        <img src={Logo} alt="" className="logo-width" />
                      </div> */}

                      <div className="">
                        <div className="text-end">
                          <h6 className="style-jenifer">
                            Invoice&nbsp;&nbsp;To
                          </h6>
                          <img
                            src={`${process.env.REACT_APP_MEDIA_URL}${orderhistory.user?.profile_pic}`}
                            alt="logo"
                            style={{
                              width: "60px",
                              height: "60px",
                              borderRadius: "10px",
                              paddingBlock: "5px",
                            }}
                          />

                          <div className="style-address">
                            <p>
                              <p className="style-jenifer"> {user?.name}</p>
                              <p>{user?.address} </p>{" "}
                              <p className="m-0">{user?.phone}</p>
                              {user?.email}
                              <p>
                                {" "}
                                {orderhistory?.address.address},
                                {orderhistory?.address.city},
                                {orderhistory?.address.state},
                                {orderhistory?.address.country},
                                {orderhistory?.address.zipCode}
                              </p>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* </>
                                ))} */}

                    <div
                      className="card"
                      style={{ border: "1px solid #D9DCE0" }}
                    >
                      <div
                        className="bg-white table-responsive"
                        style={{ borderRadius: "9px" }}
                      >
                        <table className="table-width">
                          <thead
                            style={{
                              backgroundColor: "#F8FAFC",
                              height: "60px",
                            }}
                          >
                            <tr className="flex ms-2 table-head">
                              <th style={{ paddingInline: "10px" }}>Sr No.</th>
                              <th>Item Name</th>
                              <th>Color</th>
                              <th className="center">Quantity</th>
                              <th className="center">Item Price</th>
                              <th className="center">Amount</th>
                            </tr>
                          </thead>
                          <tbody className="waleed-margin">
                            {orderhistory?.orderproducts?.map(
                              (products, index) => (
                                <tr
                                  className="mt-3 flex2 style-table"
                                  style={{ paddingTop: "10%" }}
                                >
                                  <td
                                    style={{
                                      paddingBlock: "15px",
                                      paddingInline: "10px",
                                    }}
                                  >
                                    {index + 1}
                                  </td>
                                  <td
                                    style={{
                                      paddingBlock: "15px",
                                      width: "47%",
                                    }}
                                  >
                                    {products.product?.name}
                                  </td>
                                  <td className="">
                                    <div
                                      style={{
                                        backgroundColor:
                                          products?.orderProductColor
                                            ?.colorCode,
                                        width: "20px",
                                        height: "20px",
                                        borderRadius: "50%",
                                        display: "inline-block",
                                      }}
                                    ></div>
                                  </td>
                                  <td
                                    style={{ paddingBlock: "15px" }}
                                    className="center"
                                  >
                                    {products?.qnty}
                                  </td>
                                  <td
                                    style={{ paddingBlock: "15px" }}
                                    className="center"
                                  >
                                    ${products?.itemPrice}
                                  </td>
                                  {/* <td style={{ paddingTop: '3%', width: "10%" }} ><p className='return'>Returned</p></td> */}
                                  <td
                                    style={{ paddingBlock: "15px" }}
                                    className="center"
                                  >
                                    {" "}
                                    $
                                    {parseInt(
                                      products.itemPrice * products?.qnty ||
                                        products.itemActualPrice *
                                          products?.qnty ||
                                        0,
                                      10
                                    )}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div
                      className="card mt-4"
                      style={{
                        backgroundColor: "#F8FAFC",
                        border: "1px solid #D9DCE0",
                      }}
                    >
                      <div
                        className="card-body"
                        style={{ padding: " 20px 32px" }}
                      >
                        <table className="w-100">
                          <thead className="style-payment">
                            <tr className="flex ms-2">
                              {/* <th className="" style={{ width: "25%" }}>
                                PAYMENT METHOD{" "}
                              </th>
                              <th style={{ width: "25%" }}>SHIPPING COST </th> */}
                              {/* <th className="" style={{ width: "25%" }}>
                                DISCOUNT{" "}
                              </th> */}
                              <th className="text-end">Total Amount</th>
                            </tr>
                          </thead>
                          <tbody className="waleed-margin">
                            {/* {orderhistory?.orderproducts?.map((items) => ( */}
                            <tr className=" flex2 style-pay">
                              {/* <td className="">Credit Card</td>
                              <td>{orderhistory.shippingFee}</td> */}
                              {/* <td className="">
                                {orderhistory.totalDiscount}%
                              </td> */}
                              <td className="text-end amount">
                                ${orderhistory.totalAmount}
                              </td>
                            </tr>
                            {/* ))} */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="button-flex mx-5">
            <button className="download-pdf" onClick={generatePDF}>
              <FaDownload /> Download Invoice
            </button>
            <button className="download-pdf" onClick={handlePrint}>
              <BsFillPrinterFill /> Print Invoice
            </button>
          </div>
        </>
      )}
    </Main>
  );
};

export default UserInvoice;
