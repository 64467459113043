import React, { useEffect, useState } from "react";
import { Main } from "./style";
import useHooks from "./useHook";
import { useNavigate } from "react-router-dom";
import toastr from "toastr";
import Spiner from "components/spinner";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const VendorProfileEdit = () => {
  const [backgroundImage, setBackgroundImage] = useState("");
  const [profilePic, setProfilePicture] = useState("");
  const [inputdata, setInputdata] = useState({ name: "" ,lastname:""});
  const [backgroundPreview, setBackgroundPreview] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const { updateProfile, getProfile, imagePost } = useHooks();

  useEffect(() => {
    const getProfileData = async () => {
      const fields = [
        "name",
        "lastname",
        "countryCode",
        "isoCode",
        "phone",
        "dob",
        "profile_pic",
        "gender",
        "background_image",
      ];
      try {
        const response = await getProfile();
        if (response?.data) {
          const data = {};
          for (const key of Object.keys(response.data)) {
            if (fields.includes(key)) {
              data[key] = response.data[key];
            }
          }
          if (data.dob) {
            data.dob = new Date(data.dob).toISOString().split("T")[0];
          }
          console.log(data);
          setInputdata(data); // Set state with the fetched data
          setBackgroundPreview(data.background_image);
          setProfilePicture(data.profile_pic);
          setBackgroundImage(data.background_image);
        }
        
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    getProfileData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const today = new Date();
    const minDate = new Date(today.setFullYear(today.getFullYear() - 14));
    const minDateString = minDate.toISOString().split("T")[0];
  
    // Check if the entered date of birth is valid
    if (inputdata.dob > minDateString) {
      toastr.error("You must be at least 14 years old to register.");
      return;
    }
  
    const formData = new FormData();
    formData.append("image", selectedImages);

    // Default values in case `inputdata` is missing them
    const countryCode = inputdata.countryCode || "US";
    const isoCode = inputdata.isoCode || ""; // Default to empty string if isoCode is not set

    const data = {
      name: inputdata.name || "",
      lastname: inputdata.lastname || "",
      countryCode: countryCode,
      phone: inputdata.phone || "",
      isoCode: isoCode,
      dob: inputdata.dob, // Default to 0 if age is not a valid number
      gender: inputdata.gender || "",
    };

    try {
      const updatedData = {
        ...data,
        profile_pic: profilePic,
        background_image: backgroundImage,
      };

      const response = await updateProfile(updatedData);
      toastr.success("Profile updated successfully");
      navigate("/vendor-profile");
    } catch (error) {
      toastr.error("Failed to update profile:", error.message);
    }
  };

  const handleImagePickerClick = () => {
    document.getElementById("imagePicker").click();
  };

  const handleImageUpload = async (e) => {
    const files = Array.from(e.target.files);
    try {
      const imagePaths = await Promise.all(
        files.map((file) => imagePost(file))
      );
      if (imagePaths) {
        const newImages = imagePaths.map((path) => `${path}`);
        setSelectedImages(newImages);
        setProfilePicture(newImages[newImages.length - 1]);
      }
    } catch (error) {
      console.error("Error in handleImageUpload: ", error);
      alert("An error occurred during image upload");
    }
  };

  const handleBackgroundUpload = async (e) => {
    const file = e.target.files[0];
    try {
      const imagePath = await imagePost(file);
      if (imagePath) {
        setBackgroundImage(imagePath);
        setBackgroundPreview(imagePath);
      }
    } catch (error) {
      console.error("Error in handleBackgroundUpload: ", error);
      alert("An error occurred during background image upload");
    }
  };

  const handlePhoneChange = (phone, country) => {
    console.log("Selected phone number:", phone);
    console.log("Selected country code:", country.dialCode); // Extract country code
    console.log("Selected ISO code:", country.countryCode); // Extract ISO code

    setInputdata((prevState) => ({
      ...prevState,
      phone: phone,
      countryCode: country.dialCode, // Set the country code dynamically
      isoCode: country.countryCode || prevState.countryCode, // Set the ISO code dynamically
    }));
  };

  const handleData = (e) => {
    const { name, value } = e.target;
    setInputdata((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Main>
      {loading ? (
        <Spiner />
      ) : (
        <div className="container-fluid">
          <div>
            <h1 className="heading">My Profile</h1>
          </div>
          <div className="profile-section-body">
            <p className="profile-title">Profile</p>
            <section>
              <div className="profile-head-section">
                <div className="profile-picture-section">
                  <p className="profile-picture-title">Profile Picture</p>
                  <figure className="profile-Image-section">
                    <label htmlFor="profileUpload">
                      <div
                        onClick={handleImagePickerClick}
                        style={{ cursor: "pointer" }}
                      >
                        <input
                          type="file"
                          id="imagePicker"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={handleImageUpload}
                        />
                        <img
                          src={
                            selectedImages.length > 0
                              ? `${process.env.REACT_APP_BASE_URL}${
                                  selectedImages[selectedImages.length - 1]
                                }`
                              : `${process.env.REACT_APP_BASE_URL}${inputdata.profile_pic}`
                          }
                          alt="Profile"
                          className="profile-image"
                        />
                      </div>
                    </label>
                  </figure>
                </div>
              </div>
              <div className="input-fields-section">
                <div className="input-fields">
                  <label className="input-types-label">First Name</label>
                  <input
                    type="text"
                    name="name"
                    value={inputdata.name}
                    onChange={handleData}
                    placeholder="Full Name"
                    className="input-width"
                  />
                </div>
                
                <div className="input-fields">
                  <label className="input-types-label">Date of Birth</label>
                  <input
                    type="date"
                    name="dob"
                    value={inputdata.dob}
                    onChange={handleData}
                    className="input-width"
                  />
                </div>
                <div className="input-fields">
                  <label className="input-types-label">Contact Number</label>
                  <PhoneInput
                    country={"us"}
                    value={inputdata.phone}
                    onChange={handlePhoneChange}
                    className="input-width"
                  />
                </div>
                <div className="input-fields">
                  <label className="input-types-label">Gender</label>
                  <select
                    name="gender"
                    className="input-width"
                    value={inputdata.gender}
                    onChange={handleData}
                  >
                    <option value="" disabled>
                      Select Gender
                    </option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Others</option>
                  </select>
                </div>
              </div>
              <div className="text-end">
                <button
                  type="submit"
                  className="CreateVendor mt-3"
                  onClick={handleSubmit}
                >
                  Update Profile
                </button>
              </div>
            </section>
          </div>
        </div>
      )}
    </Main>
  );
};

export default VendorProfileEdit;

