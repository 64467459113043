import { AuthAPI } from "libs/http-service/api/auth.api";
import toastr from "toastr";
toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: false,
  progressBar: true,
  positionClass: "toast-top-right",
  preventDuplicates: false,
  onclick: null,
  showDuration: "300",
  hideDuration: "1000",
  timeOut: "3000",
  extendedTimeOut: "1000",
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
};
const useHooks = () => {
  const getcatalogs = async (params) => {
    try {
      const response = await AuthAPI.getcatalogs(params);
      return response;
    } catch (error) {
      console.error(error);
      alert(error.message);
    }
  };
  // const getSingleCatalog = async (id) => {
  //   try {
  //     const response = await AuthAPI.getSingleCatalog(id);
  //     // toastr.success("Catalog Get successfully");
  //     return response;
  //   } catch (error) {
  //     console.error(error);
  //     toastr.error("Error fetching catalog");
  //   }
  // };
  const deleteCatalog = async (id) => {
    try {
      const response = await AuthAPI.deleteCatalog(id);

      return response;
    } catch (error) {
      console.error(error);
      toastr.error("Error");
    }
  };

  return { getcatalogs, deleteCatalog };
};

export default useHooks;
