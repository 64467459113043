import React, { useEffect, useState } from "react";
import { Card, Main } from "./style";
import IosButton from "components/ios-button";
import Edit from "../../../assets/img/products-details/edit.png";
import Delete from "../../../assets/img/products-details/delete.png";
import ProductImg from "../../../assets/img/products-details/product-img.png";
import Star from "../../../assets/img/products-details/star.png";
import CommentPic from "../../../assets/img/products-details/comment pic.png";
import Sofaimg from "../../../assets/img/products-details/sofa.png";
import Sofaimg2 from "../../../assets/img/products-details/sofa.png";
import ReportModal from "components/modal/report-modal";
import useHooks from "./useHook";
import { useParams } from "react-router-dom";
import Spiner from "components/spinner";
import LotusPro from "assets/img/common/lotuspro.png";
import Viewer from "react-viewer";
const ProductsDetails = () => {
  const { id } = useParams();

  const ProductReviews = [
    {
      id: 1,
      img: CommentPic,
      name: "Waleed Kashif",
      rating: "4.5",
      date: "November 14,2023 at 1:03pm",
      comment:
        "Lorem ipsum dolor sit amet consectetur. Viverra faucibus ultricies placerat orci montes. Bibendum posuere sed risus amet. Magnis quis eu vel laoreet sit ac. Augue metus augue sodales tincidunt non dictum arcu proin.",
    },
    {
      id: 1,
      img: CommentPic,
      name: "Waleed Kashif",
      rating: "4.5",
      date: "November 14,2023 at 1:03pm",
      comment:
        "Lorem ipsum dolor sit amet consectetur. Viverra faucibus ultricies placerat orci montes. Bibendum posuere sed risus amet. Magnis quis eu vel laoreet sit ac. Augue metus augue sodales tincidunt non dictum arcu proin.",
      sofaimg: Sofaimg,
      sofaimg2: Sofaimg2,
    },
    {
      id: 1,
      img: CommentPic,
      name: "Waleed Kashif",
      rating: "4.5",
      date: "November 14,2023 at 1:03pm",
      comment:
        "Lorem ipsum dolor sit amet consectetur. Viverra faucibus ultricies placerat orci montes. Bibendum posuere sed risus amet. Magnis quis eu vel laoreet sit ac. Augue metus augue sodales tincidunt non dictum arcu proin.",
    },
  ];
  const formatDate = (isoDateString) => {
    const date = new Date(isoDateString);

    // Options for formatting the date
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true, // for 12-hour format with am/pm
    };

    // Format the date according to options
    const formattedDate = date.toLocaleDateString("en-US", options);

    return formattedDate;
  };

  const [reportComment, setReportComment] = useState(false);
  const [selectedColor, setSelectedColor] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showAllReviews, setShowAllReviews] = useState(false);

  const handleSeeAllClick = () => {
    setShowAllReviews(true);
  };

  // API hook
  const { getproductsdetails, fetchproductsreviews } = useHooks();
  const [productsDetails, setProductsDetails] = useState([]);
  const [reviews, setReviews] = useState();
  useEffect(() => {
    const getproductsdetail = async () => {
      try {
        const response = await getproductsdetails(id);
        if (response.status === true) {
          setProductsDetails(response.data);
          setSelectedColor(response.data.colors[0]); // Set default selected color
        } else {
          console.log("Response status is false");
        }
      } catch (error) {
        console.error("Error fetching product details:", error);
      } finally {
        setLoading(false);
      }
    };
    getproductsdetail();
  }, [id]);

  const handleColorClick = (color) => {
    setSelectedColor(color);
  };

  const HandleReportsSection = () => {
    setReportComment(true);
    // console.log("clicked");
  };

  // Products Reviews

  const [productReviews, setProductReviews] = useState([]);
  const getproductsreviews = async () => {
    const params = {
      productId: id,
      page: 1,
      perPage: 20,
    };
    // console.log("params",params)
    try {
      const response = await fetchproductsreviews(params);
      if (response.status === true) {
        setProductReviews(response.data.results);
        // setProductsDetails(response.data);
        // setSelectedColor(response.data.colors[0]); // Set default selected color
      } else {
        console.log("Response status is false");
      }
    } catch (error) {
      // console.error("Error fetching product details:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getproductsreviews();
  }, []);

  // image viwer
  const [visible, setVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  const handleImageClick = (imageSrc) => {
    setCurrentImage(imageSrc);
    setVisible(true);
  };

  return (
    <Main>
      {loading ? (
        <Spiner />
      ) : (
        <>
          <div className="container-fluid">
            <div className="heading-flex">
              <div className="padding-text">
                <p className="heading m-0">Product Details</p>
              </div>
              {/* <div className="published-flex">
                <p className="m-0 published">Published</p>
                <div className="ios-button">
                  <IosButton />
                </div>
                <img src={Edit} alt="" className="icon-width" />
                <img src={Delete} alt="" className="icon-width" />
              </div> */}
            </div>

            <Card
              className="card"
              style={{ borderRadius: "20px", border: "0px" }}
            >
              <div className="card-body">
                <p className="name">{productsDetails.name}</p>
                <div className="card-main-flex">
                  <div>
                    {selectedColor ? (
                      <img
                        src={`${process.env.REACT_APP_BASE_URL}/${selectedColor.image}`}
                        alt={selectedColor.colorName}
                        className="product-img"
                      />
                    ) : (
                      <p>No color images available</p>
                    )}
                  </div>
                  {/* <div className="padding-table">
                    <div className="table-responsive">
                      <div className="table-width">
                        <table
                          class="table table-borderless"
                          style={{ height: "0px" }}
                        >
                          <tbody>
                            <tr className="">
                              <td className="table-heading">Category</td>
                              <td className="table-data">
                                {productsDetails.category
                                  ? productsDetails.category.name
                                  : "Category Not Available"}
                              </td>
                              <td className="padding-rows table-heading">
                                Collection
                              </td>
                              <td className="table-data">
                                {productsDetails.collections &&
                                productsDetails.collections.length > 0 ? (
                                  <div>
                                    {productsDetails.collections.map(
                                      (collection) => (
                                        <div key={collection.id}>
                                          <p>{collection.name}</p>
                                        </div>
                                      )
                                    )}
                                  </div>
                                ) : (
                                  "Collection Not Available"
                                )}
                              </td>
                            </tr>

                            <tr>
                              <td className="table-heading">Rating</td>
                              <td
                                className="table-data"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {productsDetails.totalRatting}{" "}
                                <img src={Star} alt="" className="star-width" />
                              </td>
                              <td className="padding-rows table-heading">
                                Material
                              </td>
                              <td className="table-data" colSpan="3">
                                {productsDetails.materials &&
                                productsDetails.materials.length > 0
                                  ? productsDetails.materials.map(
                                      (material, index) => (
                                        <span key={material.id}>
                                          {material.material
                                            ? material.material.name
                                            : "Material Name Not Available"}
                                          {index <
                                            productsDetails.materials.length -
                                              1 && ", "}
                                        </span>
                                      )
                                    )
                                  : "Materials Not Available"}
                              </td>
                            </tr>

                            <tr>
                              <td className="table-heading">Price</td>
                              <td className="table-data">
                                {productsDetails.productPrice}
                              </td>
                              <td className="padding-rows table-heading">
                                Sale Price
                              </td>
                              <td className="table-data">
                                {productsDetails.salePrice}
                              </td>
                            </tr>

                            <tr>
                              <td className="table-heading">Product SKU</td>
                              <td className="table-data">
                                {productsDetails.sku}
                              </td>
                              <td className="padding-rows table-heading">
                                Product Tags
                              </td>
                              <td className="table-data">
                                {productsDetails.metas &&
                                productsDetails.metas.length > 0
                                  ? productsDetails.metas
                                      .map((meta) => meta.name)
                                      .join(", ")
                                  : "No metas available"}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div> */}

                  <table
                    className="table table-borderless"
                    style={{ height: "0px" }}
                  >
                    <div className="parent">
                      <div className="div1">
                        <td className="table-heading">Category</td>
                        <td className="table-data">
                          {productsDetails.category
                            ? productsDetails.category?.parent?.name
                            : "Category Not Available"}
                        </td>
                        <td className="padding-rows table-heading">
                          Collection
                        </td>
                        <td className="table-data">
                          {productsDetails.collections &&
                          productsDetails.collections.length > 0 ? (
                            <div>
                              {productsDetails.collections.map((collection) => (
                                <div key={collection.id}>
                                  <p>{collection.name}</p>
                                </div>
                              ))}
                            </div>
                          ) : (
                            "Collection Not Available"
                          )}
                        </td>
                      </div>

                      <div className="div2">
                        <td className="table-heading">Rating</td>
                        <td className="table-data">
                          {productsDetails.totalRatting}{" "}
                          <img src={Star} alt="" className="star-width" />
                        </td>
                        <td className="padding-rows table-heading">Material</td>
                        <td className="table-data" colSpan="3">
                          {productsDetails.materials &&
                          productsDetails.materials.length > 0
                            ? productsDetails.materials.map(
                                (material, index) => (
                                  <span key={material.id}>
                                    {material.material
                                      ? material.material.name
                                      : "Material Name Not Available"}
                                    {index <
                                      productsDetails.materials.length - 1 &&
                                      ", "}
                                  </span>
                                )
                              )
                            : "Materials Not Available"}
                        </td>
                      </div>

                      <div className="div3">
                        <td className="table-heading">Price</td>
                        <td className="table-data">
                          {productsDetails.productPrice}
                        </td>
                        <td className="padding-rows table-heading">
                          Sale Price
                        </td>
                        <td className="table-data">
                          {productsDetails.salePrice}
                        </td>
                      </div>
                      <div className="div4">
                        <td className="table-heading">Product SKU</td>
                        <td className="table-data">{productsDetails.sku}</td>
                        <td className="padding-rows table-heading">
                          Product Tags
                        </td>
                        <td className="table-data">
                          {productsDetails.metas &&
                          productsDetails.metas.length > 0
                            ? productsDetails.metas
                                .map((meta) => meta.name)
                                .join(", ")
                            : "No metas available"}
                        </td>
                      </div>
                    </div>
                  </table>
                </div>

                <div className="dimnetion-parent">
                  <div className="dimention">
                    <p className="table-headings">Dimentions</p>
                    <td className="table-data">
                      {productsDetails.length} x {productsDetails.width} x{" "}
                      {productsDetails.height} {productsDetails.unit} (L x W x
                      H)
                    </td>
                  </div>
                  <div className="dimention-flex">
                    <p className="table-heading">Colors</p>
                    <td className="table-data " style={{ display: "flex" }}>
                      {productsDetails.colors &&
                      productsDetails.colors.length > 0
                        ? productsDetails.colors.map((color) => (
                            <p
                              key={color.id}
                              style={{
                                backgroundColor: color.colorCode,
                                width: "22px",
                                height: "22px",
                                cursor: "pointer",
                              }}
                              className="color"
                              onClick={() => handleColorClick(color)}
                            >
                              {/* {color.colorName} */}
                            </p>
                          ))
                        : "No colors available"}
                      &nbsp;
                    </td>
                  </div>
                </div>

                <div className="product-flex">
                  <div className="div5">
                    <td className="table-heading">Product Images</td>
                  </div>
                  <div className="div6">
                    <td className="table-data product-img-flex">
                      {productsDetails &&
                      productsDetails.images &&
                      productsDetails.images.length > 0 ? (
                        productsDetails.images.map((image, index) => (
                          <img
                            key={index}
                            src={`${process.env.REACT_APP_BASE_URL}${image.url}`}
                            alt={`Product Image ${index + 1}`}
                            className="image-size"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setCurrentImage(
                                `${process.env.REACT_APP_BASE_URL}${image.url}`
                              );
                              setVisible(true);
                            }}
                          />
                        ))
                      ) : (
                        <div>No images available</div>
                      )}
                      {visible && currentImage && (
                        <Viewer
                          visible={visible}
                          onClose={() => setVisible(false)}
                          images={[{ src: currentImage, alt: "Image" }]} // Only show the clicked image
                          noToolbar={true}
                          noFooter={true}
                          drag={false}
                          noDrag={true}
                          noResetZoomAfterChange={true}
                          disableMouseZoom={true}
                        />
                      )}
                    </td>
                  </div>
                </div>

                <div className="parent-flex">
                  <p className="m-0 table-heading">Product Description</p>
                  <p className="description">{productsDetails.description}</p>
                </div>

                <div className="d-flex justify-content-between mt-4">
                  <p className="details">Product Reviews</p>
                  {!showAllReviews && productReviews.length > 0 && (
                    <p className="seeAll" onClick={handleSeeAllClick}>
                      See all
                    </p>
                  )}
                </div>

                {/* {productReviews
                  .slice(0, showAllReviews ? productReviews.length : 2) // Show 2 reviews by default
                  .map((items, index) => (
                    <div
                      key={index}
                      className="d-flex"
                      style={{
                        alignItems: "flex-start",
                        borderBottom: "1px solid #E2E8F0",
                        paddingBlock: "10px",
                      }}
                    >
                      <img
                        src={
                          items.user?.profile_pic
                            ? `${process.env.REACT_APP_BASE_URL}${items.user?.profile_pic}`
                            : LotusPro
                        }
                        alt=""
                        className="comment-pic"
                        style={{ borderRadius: "40px" }}
                      />
                      <div>
                        <div className="review-flex">
                          <p className="m-0 client-name">{items.user?.name}</p>
                          <p className="m-0 ps-4 comment-rating pe-2">
                            {items?.ratting
                              ? items.ratting.toString().slice(0, 3)
                              : ""}
                          </p>
                          <img
                            src={Star}
                            alt=""
                            style={{ width: "16px", height: "14px" }}
                            className=""
                          />
                        </div>
                        <div style={{ padding: "0px 10px" }}>
                          <span className="date">
                            {items.createdAt ? formatDate(items.createdAt) : ""}
                          </span>
                          <p className="m-0 comment">{items.comment}</p>
                          <div className="comment-img-flex">
                            {items.images.map((image) => (
                              <img
                                key={image.id}
                                src={`${process.env.REACT_APP_BASE_URL}${image.image}`}
                                alt=""
                                style={{
                                  maxWidth: "10%",
                                  margin: "10px 0",
                                  borderRadius: "8px",
                                }}
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))} */}

                {productReviews && productReviews.length > 0 ? (
                  productReviews
                    .slice(0, showAllReviews ? productReviews.length : 2)
                    .map((items, index) => (
                      <div
                        key={index}
                        className="d-flex"
                        style={{
                          alignItems: "flex-start",
                         
                          paddingBlock: "10px",
                        }}
                      >
                        <img
                          src={
                            items.user?.profile_pic
                              ? `${process.env.REACT_APP_BASE_URL}${items.user?.profile_pic}`
                              : LotusPro
                          }
                          alt=""
                          className="comment-pic"
                          style={{ borderRadius: "40px" }}
                        />
                        <div>
                          <div className="review-flex">
                            <p className="m-0 client-name">
                              {items.user?.name}
                            </p>
                            <p className="m-0 ps-4 comment-rating pe-2">
                              {items?.ratting
                                ? items.ratting.toString().slice(0, 3)
                                : ""}
                            </p>
                            <img
                              src={Star}
                              alt=""
                              style={{ width: "16px", height: "14px" }}
                              className=""
                            />
                          </div>
                          <div style={{ padding: "0px 10px" }}>
                            <span className="date">
                              {items.createdAt
                                ? formatDate(items.createdAt)
                                : ""}
                            </span>
                            <p className="m-0 comment">{items.comment}</p>
                            <div className="comment-img-flex">
                              {items.images.map((image) => (
                                <img
                                  key={image.id}
                                  src={`${process.env.REACT_APP_BASE_URL}${image.image}`}
                                  alt=""
                                  style={{
                                    maxWidth: "10%",
                                    margin: "10px 0",
                                    borderRadius: "8px",
                                  }}
                                />
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                ) : (
                  <p className="no-record">No reviews available.</p>
                )}
              </div>
            </Card>
          </div>
          {/* {reportComment && (
            <ReportModal
              onOpen={HandleReportsSection}
              onClose={() => setReportComment(false)}
            />
          )} */}
        </>
      )}
    </Main>
  );
};

export default ProductsDetails;
