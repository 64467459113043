 
import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import closeIcon from "../../../assets/img/my-wallet-modals/closeIcon.svg";
import { Title, Form } from "./style";
import useHook from "./useHoook";

const AddAmountModal = ({
  onOpenAddAccountModal,
  onCloseAddAccountModal,
  activeId,
}) => {
  const [accountIBANnumber, setAccountIBANnumber] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [error, setError] = useState("");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "695px",
    height: "413px",
    bgcolor: "#FFFFFF",
    p: "25px 45px",
    borderRadius: "30px",
    "@media (max-width: 425px)": {
      width: "90%",
      height: "auto",
      padding: 2,
      borderRadius: "30px",
    
    },
  };

  const handleModalClose = () => {
    onCloseAddAccountModal();
  };

  const handleSubmit = () => {
    // Validate before proceeding
    if (!accountIBANnumber) {
      setError("Account IBAN number cannot be empty.");
      return;
    }

    if (!accountHolderName) {
      setError("Account Holder Name cannot be empty.");
      return;
    }
    if (Account) {
      onCloseAddAccountModal();
    }

    setError(""); // Clear errors if validation passes
    Account(); // Call the API function
  };

  // API Function
  const { PostAccount } = useHook();

  const Account = async () => {
    const accountName = accountHolderName;
    const accountNumber = accountIBANnumber;

    try {
      const response = await PostAccount(accountName, accountNumber);
      console.log(response);
      if (response) {
        onCloseAddAccountModal(); // Close modal if API call is successful
      }
    } catch (error) {
      console.log("Error submitting account details:", error);
      setError("Failed to submit account details. Please try again.");
    }
  };

  return (
    <>
      <Modal
        open={onOpenAddAccountModal}
        onClose={handleModalClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 300,
          },
        }}
      >
        <Fade in={onOpenAddAccountModal}>
          <Box sx={style}>
            <Title className="modal-head">
              <p className="modal-title">Payment Request</p>
              <figure onClick={handleModalClose} style={{ cursor: "pointer" }}>
                <img src={closeIcon} alt="Close Button" />
              </figure>
            </Title>
            <Form>
              <div className="selectAccount">
                <label htmlFor="account" className="selectAccount-title">
                  Enter Account IBAN Number {activeId}
                </label>
              </div>
              <input
                type="number"
                placeholder="0000 0000 0000 0000"
                name="account"
                value={accountIBANnumber}
                onChange={(e) => setAccountIBANnumber(e.target.value)}
                className="selectAccount-inputField"
              />
              <div className="selectAccount">
                <label htmlFor="ammount" className="selectAmmount-title">
                  Enter Account Holder Name
                </label>
              </div>

              <input
                type="text"
                placeholder="Account Holder Name"
                name="ammount"
                value={accountHolderName}
                onChange={(e) => setAccountHolderName(e.target.value)}
                className="selectAmmount-inputField"
              />

              {/* Error Message */}
              {error && <p className="error-message">{error}</p>}

              <div className="sectionButton">
                <button
                  type="button"
                  id="cancelButton"
                  onClick={onCloseAddAccountModal}
                >
                  Cancel
                </button>
                <button type="button" id="saveButton" onClick={handleSubmit}>
                  Save
                </button>
              </div>
            </Form>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default AddAmountModal;
