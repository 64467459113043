import styled from "styled-components";
export const Main = styled.div`
  width: 100%;
  background: var(--all-background);
  height: max-content;
  /* padding: 35px 0px; */
  .order-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-block: 20px;
  }
  .orders {
    color: #1c1c27;
    font-family: inter;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.2px;
  }
  .download-orders {
    width: 198px;
    height: 48px;
    border-radius: 5px;
    background-color: #156a3d;
    border: 0px;
    color: #ffffff;
    font-family: inter;
    font-weight: 700;
    font-size: 16px;
  }
  .customer-input {
    width: 307px;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #94a3b8;
    padding: 15px;
    outline: none;
  }
  .status {
    width: 208px;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #94a3b8;
    outline: none;
  }
  .order-limit {
    width: 242px;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #94a3b8;
    outline: none;
  }
  .method {
    width: 256px;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #94a3b8;
    outline: none;
  }
  .inputs-flex {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
  .calender {
    width: 300px;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #94a3b8;
    outline: none;
    padding: 15px;
  }
  .label {
    font-family: inter;
    font-weight: 600;
    font-size: 14px;
    color: #1c1c27;
  }
  .filter {
    width: 200px;
    height: 48px;
    border-radius: 5px;
    background-color: #156a3d;
    font-family: inter;
    font-weight: 700;
    font-size: 16px;
    color: #ffffff;
    border: 0px;
  }
  .reset {
    width: 216px;
    height: 48px;
    border-radius: 5px;
    background-color: #e2e8f0;
    font-family: inter;
    font-weight: 700;
    font-size: 16px;
    color: #ffffff;
    border: 0px;
  }
  .button-flex {
    display: flex;
    gap: 5px;
    padding-block: 24px;
  }
  .head-button-flex {
    display: flex;
    gap: 10px;
    margin-top: 25px;
  }
  @media (max-width: 380px) {
    .head-button-flex {
      flex-wrap: wrap;
      margin: 0;
    }
  }
  .table-head {
    font-family: inter;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #0c3a2d;
  }
  .table-body {
    font-family: inter;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #1c1c27;
    border-bottom: 1px solid #e2e8f0;
  }
  .status-processing {
    width: 99px;
    height: 24px;
    border-radius: 5px;
    background-color: #00d1ff1a;
    font-family: inter;
    font-family: 400;
    font-size: 12px;
    color: #00d1ff;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: 0px;
  }
  .status-delivered {
    width: 90px;
    height: 24px;
    border-radius: 5px;
    background-color: #209a421a;
    font-family: inter;
    font-family: 400;
    font-size: 12px;
    color: #209a42;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: 0px;
  }
  .status-pending {
    width: 82px;
    height: 24px;
    border-radius: 5px;
    background-color: #ff8f501a;
    font-family: inter;
    font-family: 400;
    font-size: 12px;
    color: #ff8f50;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: 0px;
  }
  .status-cancelled {
    width: 75px;
    height: 24px;
    border-radius: 5px;
    background-color: #ff00001a;
    font-family: inter;
    font-family: 400;
    font-size: 12px;
    color: #ff0000;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: 0px;
  }

  .status-returned {
    width: 75px;
    height: 24px;
    border-radius: 5px;
    background-color: #ff00001a;
    font-family: inter;
    font-family: 400;
    font-size: 12px;
    color: #ff0000;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: 0px;
  }
  .status-declined {
    color: #cd2121;
    width: 75px;
    height: 24px;
    border-radius: 5px;
    background-color: #cc00002e;
    font-family: inter;
    font-family: 400;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: 0px;
  }
  .status-shipped {
    color: firebrick;
    width: 75px;
    height: 24px;
    border-radius: 5px;
    background-color: cornsilk;
    font-family: inter;
    font-family: 400;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: 0px;
  }

  .custom-select {
    border: none;
    border-radius: 5px;
    width: 99px;
    height: 24px;
    outline: none;
  }

  .custom-select option {
    background-color: #fff;
  }
  .options {
    font-family: inter;
    font-family: 400;
    font-size: 12px;
    outline: none;
    border: 0px;
  }
  .pagination-flex {
    display: flex;
    justify-content: end;
  }
  .input {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    padding: 10px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #94a3b8;
    outline: none;
  }
  .input2 {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    padding: 10px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #94a3b8;
    outline: none;
  }
  .select1 {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    padding: 10px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #94a3b8;
    outline: none;
  }
  .select2 {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    padding: 10px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #94a3b8;
    outline: none;
  }
  .date {
    font-family: inter;
    font-weight: 600;
    font-size: 14px;
    color: #1c1c27;
  }
  .date-input {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    gap: 88px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #808d9e;
    padding: 10px;
    outline: none;
  }
  .flex {
    display: flex;
    gap: 10px;
  }
  .filter {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    background-color: #156a3d;
    font-family: inter;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    border: 0px;
    color: white;
  }
  .reset {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    background-color: #e2e8f0;
    font-family: inter;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    border: 0px;
    color: #808d9e;
  }
  .display-grid {
    display: grid;
    grid: auto / auto auto auto;
    grid-gap: 10px;
    padding-bottom: 35px;
  }
  @media (max-width: 1280px) {
    .display-grid {
     grid: none;
    }
  }
  .buttons-flex {
    display: flex;
    justify-content: end;
    gap: 10px;
    padding: 16px;
  }

  /* =============================================== */
  /* .padding-dispute {
    padding: 0px 3px;
  } */

  .nav-tabs .nav-link.active {
    color: white;
    background-color: #0c3a2d;
    border-color: var(--bs-nav-tabs-link-active-border-color);
  }
  .custom-color {
    color: #808d9e;
    background-color: white;
  }
  .dispute-font {
    font-family: inter;
    font-size: 16px;
    font-weight: 400;
    white-space: nowrap;
    border-bottom: 0;
    color: #808d9e;
    gap: 10px;
    margin-top: 10px;
  }
  .create-btn {
    width: 616px;
    height: 56px;
    border-radius: 5px;
    background-color: #156a3d;
    font-family: "Inter";
    font-weight: 700;
    font-size: 16px;
    color: #ffffff;
    border: 0px;
  }
  .background {
    background: white;
    padding: 2px 10px;
    border-radius: 0px 10px 10px 10px;
  }
  .modal-div {
    //  display: flex;
    //flex-direction: column;
    // background-color: red;
    //position:absolute;
    // bottom: 0;
    // padding: 20px;
  }
  .table-responsive {
    width: 100%;
  }
  @media (max-width: 1100px) {
    .table-responsive {
      overflow-x: auto; /* Enable horizontal scrolling */
      width: 100%;
    }
  }
  .table-width {
    width: 100%;
  }
  @media (max-width: 1038px) {
    .table-width {
      width: 1150px;
    }
  }

`;
