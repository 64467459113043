import React, { useEffect, useState } from "react";
import { Details, Main, Profile, ProfileContainer, Reviews } from "./style";
import EditButtonIcon from "../../../assets/img/vendor-profile/EditButtonIcon.svg";
// import ProfileImage from "../../../assets/img/vendor-profile/useProfilePicture.svg";
import Star from "../../../assets/img/vendor-profile/starIcon.svg";
import Img from "../../../assets/img/vendor-profile/person1.svg";
import { useNavigate } from "react-router-dom";
import ReportModal from "components/modal/report-modal";
import useHooks from "./useHook";
import Spiner from "components/spinner";
import LotusPro from "assets/img/common/lotuspro.png";
import BusinessDetailsModal from "components/modal/business-details";
import VendorProfileModal from "components/modal/edit-vendor-profile";
import { MdVerified } from "react-icons/md";
const VendorProfile = () => {
  const [reportComment, setReportComment] = useState(false);
  const navigate = useNavigate();

  const { getProfile, fetchUserReviews } = useHooks();
  const [profileData, setProfileData] = useState([]);
  const [bussinessinfo, setbussinessinfo] = useState([]);
  const [loading, setLoading] = useState(true);

  const GetProfile = async () => {
    try {
      const response = await getProfile();
      setProfileData(response.data);
      setbussinessinfo(response.data.businessInfo);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const formattedDOB = new Date(profileData.dob).toLocaleDateString();

  const EditVendorProfile = () => {
    navigate("/edit-vendor-profile");
  };

  const HandleReportsSection = () => {
    setReportComment(true);
  };

  const HandleSeeAllReviews = () => {
    navigate("");
  };

  const backgroundImage = `${process.env.REACT_APP_BASE_URL}${bussinessinfo?.businessBannerImage}`;
  console.log(backgroundImage);

  const id = localStorage.getItem("id");
  const numericId = parseInt(id);
  const [userReviews, setUserReviews] = useState([]);

  const getUserReviews = async () => {
    const params = {
      userId: numericId,
      page: 1,
      perPage: 20,
    };

    try {
      const response = await fetchUserReviews(params);
      if (response.status === true) {
        setUserReviews(response.data.results);
        console.log(response.data.results);
        // console.log(userReviews);
      } else {
        console.log("Response status is false");
      }
    } catch (error) {
      console.error("Error fetching user reviews:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserReviews();
    GetProfile();
  }, []);

  const formatDate = (isoDateString) => {
    const date = new Date(isoDateString);

    // Options for formatting the date
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true, // for 12-hour format with am/pm
    };

    // Format the date according to options
    const formattedDate = date.toLocaleDateString("en-US", options);

    return formattedDate;
  };
  //    See All Reviews
  const [showAllReviews, setShowAllReviews] = useState(false);

  const handleSeeAllClick = () => {
    setShowAllReviews(true);
  };

  // Determine which reviews to display
  const reviewsToDisplay = showAllReviews
    ? userReviews
    : userReviews.slice(0, 5);

  ///    modal   ///
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  // profile modal
  const [vendorProfileOpen, setVendorProfileOpen] = useState(false);

  // Function to open the modal
  const handleVendorProfileOpen = () => {
    setVendorProfileOpen(true);
  };

  // Function to close the modal
  const handleVendorProfileClose = () => {
    setVendorProfileOpen(false);
  };

  return (
    <Main>
      <BusinessDetailsModal
        open={isModalOpen}
        onClose={handleCloseModal}
        businessInfo={bussinessinfo}
        ProfileData={profileData}
      />
      <VendorProfileModal
        vendorProfileOpen={vendorProfileOpen}
        handleVendorProfileClose={handleVendorProfileClose}
        singelVendor={profileData}
      />
      {loading ? (
        <Spiner />
      ) : (
        <>
          <div className="container-fluid">
            <div className="heading-flex">
              <p className="heading">My Profile</p>
              <button className="edit-btn" onClick={EditVendorProfile}>
                <img src={EditButtonIcon} alt="Edit Button" />
                Edit
              </button>
            </div>
            {/* Vendor Profile */}
            <Profile>
              <div className="main-profile " style={{ width: "100%" }}>
                <div
                  className="profile-content "
                  style={{
                    maxHeight: "300px",
                    overflowY: "auto",
                    marginBottom: "20px",
                  }}
                >
                  <div
                    style={{
                      backgroundImage: `
          linear-gradient(
            180deg,
            rgba(2, 0, 36, 0.4) 180%,
            rgba(2, 0, 36, 1) 250%
          ),
          url(${backgroundImage})
        `,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      width: "100%",
                      height: "165px",
                      borderRadius: "25px 25px 0 0",
                      marginBottom: "134px",
                      padding: "49px 26px",
                    }}
                  >
                    <div style={{ textAlign: "end" }}>
                      <button
                        type="button"
                        className="business-details"
                        onClick={handleOpenModal}
                      >
                        View Business Details
                      </button>
                    </div>
                    <div className="d-flex  flex-md-row">
                      <figure className="profile-image">
                        <img
                          src={`${process.env.REACT_APP_BASE_URL}${
                            // profileData ? profileData.profile_pic : ProfileImage
                            bussinessinfo?.businessLogo
                          }`}
                          alt="Profile"
                          className="profile-pic"
                        />
                      </figure>

                      <div className="profile-details-section">
                        <div className="profile-details-flex">
                          <div className="icon-flex">
                            <h2>{bussinessinfo?.businessName}</h2>{" "}
                            {profileData?.isVerified ? (
                              <MdVerified
                                color="rgb(21, 106, 61)"
                                size={20}
                                style={{ marginBottom: " 4px" }}
                              />
                            ) : (
                              "not Verifiyed"
                            )}
                          </div>
                          <div className="profile-followers-sections">
                            {/* <p>
                              Profile like:{" "}
                              <span>{profileData?.profileLike}</span>
                            </p>
                            <p>
                              Followers:{" "}
                              <span>{profileData?.followersCount}</span>
                            </p> */}
                            <div className="ratting-flex">
                              <p className="mt-1">
                                {profileData &&
                                profileData.totalRatting !== undefined &&
                                profileData.totalRatting !== null
                                  ? parseFloat(
                                      profileData.totalRatting
                                    ).toFixed(2)
                                  : "Loading..."}{" "}
                                ({profileData.numberOfRattings})
                              </p>
                              <div>
                                <img src={Star} alt="Ratings" />
                                {/* <img src={Star} alt="Ratings" />
                                <img src={Star} alt="Ratings" />
                                <img src={Star} alt="Ratings" />
                                <img src={HalfStar} alt="Ratings" /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <h2>{profileData?.name || "Loading..."}</h2> */}
                        {/* <div className="contact-info-section">
                          <div>
                            <div className="email-section">
                              <p className="email-title">Business License :</p>
                              <p className="email-detail">
                                {bussinessinfo?.businessLicenseNo}
                              </p>
                            </div>
                          </div>
                          <div className="rating-section">
                            <p className="email-title">Business Type :</p>
                            <p className="email-detail">
                              {bussinessinfo?.businessType}
                            </p>
                          </div>
                          <div>
                            <div className="email-section">
                              <p className="email-title">DBA :</p>
                              <p className="email-detail">
                                {bussinessinfo?.dba}
                              </p>
                            </div>
                          </div>
                        </div> */}

                        <div className="contact-info-section">
                          {/* <div className="width">
                            <p className="email-title">
                              License &nbsp;&nbsp;&nbsp;:
                              <span className="email-detail">
                                {bussinessinfo?.businessLicenseNo}
                              </span>
                            </p>
                          </div> */}
                          <div className="width">
                            <p className="email-title">
                              Type
                              <span className="email-detail">
                                {bussinessinfo?.businessType}
                              </span>
                            </p>
                          </div>

                          <div className="width">
                            <p className="email-title">
                              DBA
                              <span className="email-detail">
                                {bussinessinfo?.dba}
                              </span>
                            </p>
                          </div>

                          <div className="width">
                            <p className="email-title">
                              EIN/ SSN
                              <span className="email-detail">
                                {bussinessinfo?.businessSsin}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="contact-info-section">
                          <div className="width">
                            {/* <p className="email-title">
                              City
                              <span className="email-detail">
                                {bussinessinfo?.city}
                              </span>
                            </p> */}
                          </div>
                          <div className="width">
                            <p className="email-title">
                              Business License
                              <span className="email-detail">
                                {bussinessinfo?.businessLicenseNo}
                              </span>
                            </p>
                          </div>

                          <div className="width">
                            <p className="email-title">
                              Date Started
                              <span className="email-detail">
                                {bussinessinfo?.businessStartDate}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="contact-info-section">
                          <div className="width">
                            {/* <p className="email-title">
                              License Image :
                              <span className="email-detail">
                                <img
                                  src={`${process.env.REACT_APP_BASE_URL}${bussinessinfo?.businessLicenseImage}`}
                                  alt=""
                                />
                              </span>
                            </p> */}
                          </div>
                        </div>

                        {/* <div className="license-img-width">
                          <p className="email-title">
                            Address &nbsp;:
                            <span className="email-detail">
                              {bussinessinfo?.addressLine1}
                            </span>
                          </p>
                        </div>
                        <div className="license-img-width">
                          <p className="email-title">
                            License Image :
                            <span className="email-detail">
                              <img
                                src={`${process.env.REACT_APP_BASE_URL}${bussinessinfo?.businessLicenseImage}`}
                                alt=""
                                className="license-img"
                              />
                            </span>
                          </p>
                        </div>
                        <div className="license-img-width">
                          <p className="email-title">
                            Description :
                            <span className="email-detail">
                              {bussinessinfo?.description}
                            </span>
                          </p>
                        </div>
                        <div className="license-img-width">
                          <p className="email-title">
                            About Shop :
                            <span className="email-detail">
                              {bussinessinfo?.aboutShop}
                            </span>
                          </p>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Profile>
            <section>
              {/* <Profile>
                <div
                  style={{
                    backgroundImage: `
                    linear-gradient(
                      180deg,
                      rgba(2, 0, 36, 0.4) 180%,
                      rgba(2, 0, 36, 1) 250%
                    ),
                    url(${backgroundImage})
                  `,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    width: "100%",
                    height: "172px",
                    borderRadius: " 25px 25px 0px 0px",
                    backgroundSize: "cover",

                    marginBottom: "134px",
                    padding: " 82px 48px",
                    backgroundPosition: "center",
                  }}
                >
                  <div className="d-flex">
                    <div>
                      <figure className="profile-image">
                        <img
                          src={`${process.env.REACT_APP_BASE_URL}${
                            profileData
                              ? profileData.profile_pic
                              : "no img here"
                          }`}
                          alt="Profile"
                          style={{ width: "100%", height: "144px" }}
                        />
                      </figure>
                    </div>

                    <div className="profile-details-section">
                      <h2>
                        &nbsp;
                        {profileData ? profileData.name : "Loading..."}
                      </h2>
                      <div className="contact-info-section">
                        <div>
                          <div className="email-section">
                            <p className="email-title">Email:</p>
                            <p className="email-detail ps-5">
                              {profileData ? profileData.email : "Loading..."}
                            </p>
                          </div>
                          <div className="phone-section">
                            <p className="phone-title">Phone no:</p>
                            <p className="phone-detail ps-3">
                              {profileData ? profileData.phone : "Loading..."}
                            </p>
                          </div>
                        </div>
                        <div className="rating-section">
                          <p className="rating">
                            {profileData &&
                            profileData.totalRatting !== undefined &&
                            profileData.totalRatting !== null
                              ? parseFloat(profileData.totalRatting).toFixed(2)
                              : "Loading..."}
                          </p>

                          <div className="rating-star-section">
                            <img src={Star} alt="Ratings" />
                          </div>
                          <p className="total-rating">
                            (
                            {profileData
                              ? profileData.numberOfRattings
                              : "Loading..."}
                            )
                          </p>
                        </div>
                      </div>

                      <div className="profile-followers-section">
                        <p>
                          Profile like:&nbsp;&nbsp;
                          <span>
                            {profileData
                              ? profileData.profileLike
                              : "Loading..."}
                          </span>
                        </p>
                        <p>
                          Followers:&nbsp;&nbsp;
                          <span>
                            {profileData
                              ? profileData.followersCount
                              : "Loading..."}
                          </span>
                        </p>
                        <p>
                          Service Provided:&nbsp;&nbsp;
                          <span>
                            {profileData
                              ? profileData.serviceNiches
                              : "Loading..."}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Profile> */}

              {/* #===========================Mid-SECTION================================= */}
              {/* <Details>
                {profileDetails.map((item, index) => (
                  <div key={index} className="business-details-section">
                    <div className="busines-name-section">
                      <h6 className="bussiness-name-title">Business Name</h6>
                      <p>
                        {profileData ? profileData.businessName : "Loading..."}
                      </p>
                    </div>

                    <address className=".busines-address-section">
                      <h6 className="business-address-title">Address</h6>
                      <p>{profileData ? profileData.address : "Loading..."}</p>
                    </address>

                    <div className="business-phone-section">
                      <h6 className="business-phone-title">Phone no</h6>
                      <p>{profileData ? profileData.phone : "Loading..."}</p>
                    </div>

                    <div className="business-shop-section">
                      <h6 className="business-shop-title">Shop</h6>
                      <p>{profileData ? profileData.about : "Loading..."}</p>
                    </div>

                    <div className="business-description-section">
                      <h6 className="business-description-title">
                        Description
                      </h6>
                      <p>
                        {profileData ? profileData.description : "Loading..."}
                      </p>
                    </div>
                  </div>
                ))}
              </Details> */}
              <ProfileContainer>
                {/* Profile Picture */}
                {/* <div className="profile-picture">
                  {profileData.profile_pic ? (
                    <img
                      src={`${process.env.REACT_APP_MEDIA_URL}${profileData.profile_pic}`}
                      alt="logo"
                      className="w-100 h-100 object-cover profile-pic"
                    />
                  ) : (
                    // <AdminLotusPRO className="w-100 h-100 object-cover profile-pic" />
                    ""
                  )}
                </div> */}
                {/* Profile Information */}
                <div className="profile-flex">
                  <div className="flex flex-col name-container">
                    <p className="email-title">Name</p>
                    <span className="email-detail">{profileData.name}</span>
                  </div>
                  {/* <div className="email-container">
                    <p className="email-title">Email</p>
                    <span className="email-detail">{profileData.email}</span>
                  </div>
                  <div className="phone-container">
                    <p className="email-title">Phone Number</p>
                    <span className="email-detail">{profileData.phone}</span>
                  </div> */}
                  <div>
                    <div className="email-container">
                      <p className="email-title">Email</p>
                      <span className="email-detail">{profileData.email}</span>
                    </div>
                    {/* <div className="flex flex-col name-container">
                    <p className="email-title">Age</p>
                    <span className="email-detail">{profileData.age}</span>
                  </div>
                  <div className="email-container">
                    <p className="email-title">Rattings</p>
                    <span className="email-detail">
                      {profileData.numberOfRattings}
                    </span>
                  </div>
                  <div className="phone-container">
                    <p className="email-title">Date of Birth</p>
                    <span className="email-detail">{formattedDOB}</span>
                  </div> */}
                  </div>
                </div>

                <button
                  className="business-details"
                  onClick={handleVendorProfileOpen}
                >
                  View Profile
                </button>
                {/* Pass the open state and close function to the modal */}
                {/* <EditProfileModal open={isModalOpen} closeModal={closeModal} profiledata={profiledata} /> */}
              </ProfileContainer>

              {/* #===========================Last-SECTION================================= */}

              {/* <Reviews>
                <div className="card-body">
                  <div className="review-section-title">
                    <p className="review-section-name">My Reviews</p>
                    {!showAllReviews && userReviews.length > 5 && (
                      <p className="seeAll" onClick={handleSeeAllClick}>
                        See all
                      </p>
                    )}
                  </div>
                  <div>
                    {reviewsToDisplay.map((item) => (
                      <div className="review-section-body" key={item.id}>
                        <figure className="profile-picture">
                          <img
                            src={
                              item.user?.profile_pic &&
                              item.user?.profile_pic.startsWith(
                                "https://lh3.googleusercontent.com/"
                              )
                                ? item.user?.profile_pic
                                : `${process.env.REACT_APP_BASE_URL}${
                                    item.user?.profile_pic || LotusPro
                                  }`
                            }
                            alt="Profile"
                            style={{
                              width: "45px",
                              height: "45px",
                              borderRadius: "40px",
                            }}
                          />
                        </figure>
                        <div>
                          <div className="review-section-profile">
                            <h1 className="user-name">{item.user?.name}</h1>
                            <div className="review-rating-section">
                              <p className="profile-rating">
                                {item.ratting !== undefined &&
                                item.ratting !== null &&
                                !isNaN(item.ratting)
                                  ? parseFloat(item.ratting).toFixed(2)
                                  : "N/A"}
                              </p>
                              <figure className="review-star">
                                <img src={Star} alt="Ratings" />
                              </figure>
                            </div>
                          </div>
                          <div className="reviews-section-timestamp">
                            <p className="reviews-date">
                              {new Date(item.createdAt).toLocaleDateString()}
                            </p>
                            <p className="reviews-time">
                              {new Date(item.createdAt).toLocaleTimeString()}
                            </p>
                          </div>
                          <div>
                            <p className="reviews-comment">{item.comment}</p>
                          </div>
                          <div className="review-images">
                            {item.images.map((imageItem) => (
                              <img
                                key={imageItem.id}
                                src={`${process.env.REACT_APP_BASE_URL}${imageItem.image}`}
                                alt={`Review Image ${imageItem.id}`}
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  margin: "5px",
                                  objectFit: "cover",
                                  borderRadius: "10px",
                                }}
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </Reviews> */}

              <Reviews>
                <div className="card-body">
                  <div className="review-section-title">
                    <p className="review-section-name">My Reviews</p>
                    {!showAllReviews && userReviews.length > 5 && (
                      <p className="seeAll" onClick={handleSeeAllClick}>
                        See all
                      </p>
                    )}
                  </div>

                  <div>
                    {/* Check if there are reviews to display */}
                    {reviewsToDisplay.length > 0 ? (
                      reviewsToDisplay.map((item) => (
                        <div className="review-section-body" key={item.id}>
                          <figure className="profile-picture">
                            <img
                              src={
                                item.user?.profile_pic &&
                                item.user?.profile_pic.startsWith(
                                  "https://lh3.googleusercontent.com/"
                                )
                                  ? item.user?.profile_pic
                                  : `${process.env.REACT_APP_BASE_URL}${
                                      item.user?.profile_pic || LotusPro
                                    }`
                              }
                              alt="Profile"
                              style={{
                                width: "45px",
                                height: "45px",
                                borderRadius: "40px",
                              }}
                            />
                          </figure>
                          <div>
                            <div className="review-section-profile">
                              <h1 className="user-name">{item.user?.name}</h1>
                              <div className="review-rating-section">
                                <p className="profile-rating">
                                  {item.ratting !== undefined &&
                                  item.ratting !== null &&
                                  !isNaN(item.ratting)
                                    ? parseFloat(item.ratting).toFixed(2)
                                    : "N/A"}
                                </p>
                                <figure className="review-star">
                                  <img src={Star} alt="Ratings" />
                                </figure>
                              </div>
                            </div>
                            <div className="reviews-section-timestamp">
                              <p className="reviews-date">
                                {/* {item.createdAt
                                  ? new Date(
                                      item.createdAt
                                    ).toLocaleDateString()
                                  : "Unknown date"} */}

                                {item?.createdAt
                                  ? new Date(
                                    item.createdAt
                                    ).toLocaleDateString("en-US", {
                                      year: "numeric",
                                      month: "short",
                                      day: "numeric",
                                    })
                                  : "N/A"}
                              </p>
                              <p className="reviews-time">
                                {item.createdAt
                                  ? new Date(
                                      item.createdAt
                                    ).toLocaleTimeString()
                                  : ""}
                              </p>
                            </div>
                            <div>
                              <p className="reviews-comment">{item.comment}</p>
                            </div>
                            <div className="review-images">
                              {item.images?.map((imageItem) => (
                                <img
                                  key={imageItem.id}
                                  src={`${process.env.REACT_APP_BASE_URL}${imageItem.image}`}
                                  alt={`Review Image ${imageItem.id}`}
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    margin: "5px",
                                    objectFit: "cover",
                                    borderRadius: "10px",
                                  }}
                                />
                              ))}
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      // Display message when no reviews are available
                      <p className="no-record">No reviews available</p>
                    )}
                  </div>
                </div>
              </Reviews>
            </section>
          </div>
          {reportComment && (
            <ReportModal
              onOpen={HandleReportsSection}
              onClose={() => setReportComment(false)}
            />
          )}
        </>
      )}
    </Main>
  );
};

export default VendorProfile;
