import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
  putRequest,
} from "../methods";
export const AuthAPI = {
  // auth
  login: async (body) => postRequest("auth/vendor/login", body),
  forgetPassword: async (body) => postRequest("auth/forget-password", body),
  verifyOtp: async (body) => postRequest("auth/verify-reset-otp", body),
  changePassword: async (body) =>
    postRequest("auth/change-forget-password", body),

  // profile
  getProfile: async () => getRequest("users/get-profile"),
  updateProfile: async (body) => putRequest("users/update-profile", body),
  imagepost: async (body, params) =>
    postRequest("images", body, (params = params)),

  // collection
  collectionPost: async (body) => postRequest("collections", body),
  getcollections: async (params) => getRequest("collections", params),
  deleteCollection: async (id) => deleteRequest(`collections/${id}`),
  getSingleCollection: async (id) => getRequest(`collections/${id}`),
  putcollectonSingle: async (id, params) =>
    putRequest(`collections/${id}`, (params = params)),
  //category
  OrdersGet: async (id, params) =>
    getRequest(`users/${id}/customer-orders`, params),
  getcategory: async (params) => getRequest("categories", params),

  // material
  getmaterial: async (params) => getRequest("materials", params),

  // Catalog
  catalogspost: async (body) => postRequest("catalogs", body),
  getSingleCatalog: async (id) => getRequest(`catalogs/${id}`),
  getcatalogs: async (params) => getRequest("catalogs", params),
  catalogPut: async (id, params) =>
    putRequest(`catalogs/${id}`, (params = params)),
  getSingleCatalog: async (id) => getRequest(`catalogs/${id}`),
  deleteCatalog: async (id) => deleteRequest(`catalogs/${id}`),
  catalogPut: async (id, params) =>
    putRequest(`catalogs/${id}`, (params = params)),
  // getSingleCatalog:async(id)=>getRequest(`catalogs/${id}`),
  deleteCatalog: async (id) => deleteRequest(`catalogs/${id}`),

  // Sub Catalog
  getSubCatalog: async (body) => getRequest("catalog-images", body),
  postSubCatalog: async (body) => postRequest("catalog-images", body),
  deleteSubCatalog: async (id) => deleteRequest(`catalog-images/${id}`),
  putSubCatalog: async (id, params) =>
    putRequest(`catalog-images/${id}`, (params = params)),
  getSingleSubCatelog: async (id) => getRequest(`catalog-images/${id}`),

  // policies
  policiesPost: async (body) => postRequest("policies", body),
  policiesGet: async (body) => getRequest("policies", body),
  policiesDelete: async (id) => deleteRequest(`policies/${id}`),
  Getpolicies: async (id) => getRequest(`policies/${id}`),
  PoliciesUpdate: async (id, body) => patchRequest(`policies/${id}`, body),

  // customer
  customerGet: async (body) => getRequest("users/my-customers", body),
  customerOderDetailGet: async (id) =>
    getRequest(`users/${id}/customer-order-details`),
  getorderID: async (Id) => getRequest(`orders/${Id}`),
  blockunblockUser: async (id) => postRequest(`users/${id}/block-unblock-user`),
  // customerCurrentOrder:async(id,body)=>getRequest(`users/${id}/customer-current-orders`)

  // products
  productsPost: async (body) => postRequest("products", body),
  getproducts: async (body) => getRequest("products", body),
  putPublishproducts: async (id, body) =>
    putRequest(`products/${id}/publish-or-unpublish`, body),
  putPublishproducts: async (id, body) =>
    putRequest(`products/${id}/publish-or-unpublish`, body),

  getproducts: async (params) => getRequest(`products${params}`),
  // putPublishproducts: async (id, body) =>putRequest(`products/${id}/publish-or-unpublish`, body),

  // products Details
  getproductsdetails: async (id, body) => getRequest(`products/${id}`, body),
  ProductsDelete: async (id) => deleteRequest(`products/${id}`),

  // peoducts reviews
  getproductsReviews: async (params) => getRequest("reviews", params),

  // single Product
  getsingleproduct: async (params) => getRequest(`products/${params.id}`),

  // update Product
  updateProduct: async (id, productData) =>
    putRequest(`products/${id}`, productData),

  updateProduct: async (id, productData) =>
    putRequest(`products/${id}`, productData),
  deleteproduct: async (id) => deleteRequest(`products/${id}`),
  // OrdersGet
  OrdersGet: async (id, params) =>
    getRequest(`users/${id}/customer-orders`, params),

  // Orders
  getOrders: async (params) => getRequest("orders", params),
  changeOrderStatus: async (id, params) =>
    postRequest(`orders/${id}/change-status`, params),

  // Dashboard here
  dashboardTopTilesGet: async (body) =>
    getRequest("dashboard/vendor/dashboard-top-titles", body),
  dashboardTopCardsGet: async (body) =>
    getRequest("dashboard/vendor/dashboard-top-cards", body),
  dashboardGraphDataGet: async (body) =>
    getRequest("dashboard/vendor/get-stats", body),
  getOrders: async (params) => getRequest("orders", params),
  // Dashboard here
  dashboardTopTilesGet: async (body) =>
    getRequest("dashboard/vendor/dashboard-top-titles", body),
  dashboardTopCardsGet: async (body) =>
    getRequest("dashboard/vendor/dashboard-top-cards", body),
  dashboardGraphDataGet: async (body) =>
    getRequest(`dashboard/vendor/get-stats${body}`),
  ordersGraphGet: async (body) =>
    getRequest(`dashboard/vendor/get-orders-stats${body}`),

  // Withdrawl Request Apis
  getWithDrawlCards: async (body) =>
    getRequest(`withdrawal-requests/get-vendor-wallet-cards`, body),
  getWithDrawlStats: async (body) =>
    getRequest(`withdrawal-requests/get-vendor-wallet-stats`, body),
  postAccount: async (data) => postRequest("accounts", data),
  getAccount: async () => getRequest("accounts"),
  WithdrawRequest: async (data) => postRequest("withdrawal-requests", data),
  getallRequest: async function (params) {
    return getRequest(
      "withdrawal-requests/get-my-withdrawals-requests",
      params
    );
  },
  getsettings: async () => getRequest("settings/get"),

  // Account Delete
  deleteBankAccount: async (id) => deleteRequest(`accounts/${id}`),
  // Order dispute
  getDispute: async (body) => getRequest("disputes", body),
  disputeStatus: async (id, params) =>
    postRequest(`disputes/${id}/change-status`, params),
  disputeDetails: async (id) => getRequest(`/disputes/${id}`),
  disputeModalPost: async (id, formData) =>
    postRequest(`/disputes/${id}/create-comment`, formData), // Pass the formData as the request body

  // Notifications
  GetNotifications: async (params) =>
    getRequest("users/get-my-notifications", params),
  ReadNotification: async () => getRequest("users/read-notifications"),

  // send Notifications

  sendNotification: async (params) =>
    postRequest("users/send-notifications", params),

  SaveToken: async (deviceId, token) =>
    postRequest("users/save-fcm-tokens", { deviceId, token }),

  // user reviews
  getuserReviews: async (params) => getRequest("user-reviews", params),

  // settings
  PostChangePassword: async (body) =>
    postRequest("users/change-password", body),
  // settings/notifications
  ToggleNotification: async (body) =>
    putRequest("users/toggle-notifications-enabled", body),

  /// Order Disputes change Status
  changeDisputesStatus: async (id, params) =>
    postRequest(`disputes/${id}/change-status`, params),

  //  Vendor Information

  BussinessInfo: async (params) =>
    postRequest(`users/save-business-info`, params),
  AdditionalInformation: async (params) =>
    postRequest(`users/save-additional-info`, params),
};
