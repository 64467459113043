import React, { useEffect, useState } from "react";
import { Main } from "./style";
import total from "../../../assets/img/user-management/total.png";
import PaginationRounded from "components/Pagination";
import StatusModal from "components/modal/status-modal";
import useHooks from "./usehooks";
import View from "assets/img/orders/view.svg";
import Spiner from "components/spinner";
import OrderDisputes from "components/modal/order-disputes";
import { useNavigate } from "react-router-dom";
const OrderDispute = () => {
  const { getOrdersDisputes } = useHooks();

  const [modalOpen, setModalOpen] = useState(false);
  const [userId, setUserId] = useState();
  const handleModalOpen = (id) => {
    setUserId(id);
    setModalOpen(true);
    console.log("modal open done", id);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };
  const [disputelist, setdisputelist] = useState([]);
  const [loading, setLoading] = useState(true);
  const [InitiatedcurrentPage, setInitiatedcurrentPage] = useState(1);
  const [ReturnedcurrentPage, setReturnedcurrentPage] = useState(1);
  const [AdmincurrentPage, setAdmincurrentPage] = useState(1);
  const [ResolvedcurrentPage, setResolvedcurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [search, setSearch] = useState("");
  const [activeTab, setActiveTab] = useState("initiated");
  const fetchOrderDisputes = async (
    status = activeTab,
    page = 1,
    perPage = 10
  ) => {
    try {
      setLoading(true);
      const response = await getOrdersDisputes({
        search,
        status,
        page,
        perPage,
      });
      setdisputelist(response.data.results);
      setTotalPage(Math.ceil(response.data.counts / 10));

      console.log(response.data.results);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  // useEffect(() => {
  //   fetchOrderDisputes("initiated");
  // }, [InitiatedcurrentPage]);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };

  // const handlePageChange = (status, value) => {
  //   switch (status) {
  //     case "initiated":
  //       setInitiatedcurrentPage(value);
  //       break;
  //     case "admin_review":
  //       setAdmincurrentPage(value);
  //       break;
  //     case "resolved":
  //       setResolvedcurrentPage(value);
  //       break;
  //     case "returned":
  //       setReturnedcurrentPage(value);
  //       break;
  //     default:
  //       break;
  //   }
  // };

  const handlePageChange = (status, pageNumber) => {
    switch (status) {
      case "initiated":
        setInitiatedcurrentPage(pageNumber);
        break;
      case "admin_review":
        setAdmincurrentPage(pageNumber);
        break;
      case "resolved":
        setResolvedcurrentPage(pageNumber);
        break;
      case "returned":
        setReturnedcurrentPage(pageNumber);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    fetchOrderDisputes(activeTab, getCurrentPage());
  }, [activeTab, InitiatedcurrentPage, ReturnedcurrentPage, AdmincurrentPage, ResolvedcurrentPage]);
  
  const getCurrentPage = () => {
    switch (activeTab) {
      case "initiated":
        return InitiatedcurrentPage;
      case "returned":
        return ReturnedcurrentPage;
      case "admin_review":
        return AdmincurrentPage;
      case "resolved":
        return ResolvedcurrentPage;
      default:
        return 1;
    }
  };
  
  const handleTabClick = (tab) => {
    setActiveTab(tab);

    // Reset the pagination for the selected tab to 1
    switch (tab) {
      case "initiated":
        setInitiatedcurrentPage(1);
        break;
      case "admin_review":
        setAdmincurrentPage(1);
        break;
      case "resolved":
        setResolvedcurrentPage(1);
        break;
      case "returned":
        setReturnedcurrentPage(1);
        break;
      default:
        break;
    }
  };

  const handleFiltere = (e) => {
    e.preventDefault();
    handlePageChange(activeTab, 1);
    fetchOrderDisputes(activeTab, 1);
    
  };
  const handleReset = (e) => {
    e.preventDefault();
    setSearch("");
  };

  useEffect(() => {
    if (search === "") {
      fetchOrderDisputes();
    }
  }, [search]);

  // const handleTabClick = (tab) => {
  //   setActiveTab(tab);
  //   fetchOrderDisputes(tab);
  // };

  useEffect(() => {
    if (!modalOpen) {
      fetchOrderDisputes();
    }
  }, []);
  // }, [InitiatedcurrentPage]);

  const navigate = useNavigate();
  const DisputesDetails = (id) => {
    navigate(`/order-dispute/disputes-details/${id}`);
  };
  return (
    <Main>
      {loading ? (
        <Spiner />
      ) : (
        <div className="container-fluid">
          <div className="heading-flex">
            <p className="m-0 heading">Orders Disputes</p>
          </div>
          <div className="card border-0 ">
            <div className="card-body disply-grid">
              <input
                type="text"
                onChange={(e) => setSearch(e.target.value)}
                className="input-reason "
                placeholder="Search by Reason"
              />

              {/* <div>
              <select className="dispute-list me-2">
                <option value="1">Dispute Limit</option>
                <option value="2">status 1</option>
                <option value="3">status 2</option>
              </select>
            </div> */}

              <button
                type="submit"
                className="filter-dispute"
                onClick={handleFiltere}
              >
                Filter
              </button>

              {/* reset  */}

              <button
                type="submit"
                className="reset-dispute"
                onClick={handleReset}
              >
                Reset
              </button>
            </div>
          </div>
          {/* <StatusModal open={modalOpen} onClose={handleModalClose} userId={userId} /> */}
          {/* #========================= Dispute tabs start ================================= */}
          <ul
            className="nav nav-tabs mt-3 dispute-font"
            id="myTab"
            role="tablist"
          >
            <li className="nav-item padding-dispute" role="presentation">
              <button
                className={`nav-link ${
                  activeTab === "initiated" ? "active" : ""
                } dispute-font`}
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home-tab-pane"
                type="button"
                role="tab"
                aria-controls="home-tab-pane"
                aria-selected={activeTab === "initiated"}
                onClick={() => {
                  fetchOrderDisputes("initiated");
                  handleTabClick("initiated");
                }}
              >
                Initiated
              </button>
            </li>
            <li className="nav-item padding-dispute" role="presentation">
              <button
                className={`nav-link ${
                  activeTab === "admin_review" ? "active" : ""
                }`}
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#profile-tab-pane"
                type="button"
                role="tab"
                aria-controls="profile-tab-pane"
                aria-selected={activeTab === "admin_review"}
                onClick={() => {
                  fetchOrderDisputes("admin_review");
                  handleTabClick("admin_review");
                }}
              >
                Admin Review
              </button>
            </li>
            <li className="nav-item padding-dispute" role="presentation">
              <button
                className={`nav-link ${
                  activeTab === "resolved" ? "active" : ""
                }`}
                id="contact-tab"
                data-bs-toggle="tab"
                data-bs-target="#contact-tab-pane"
                type="button"
                role="tab"
                aria-controls="contact-tab-pane"
                aria-selected={activeTab === "resolved"}
                onClick={() => {
                  fetchOrderDisputes("resolved");
                  handleTabClick("resolved");
                }}
              >
                Resolved
              </button>
            </li>
            <li className="nav-item padding-dispute" role="presentation">
              <button
                className={`nav-link ${
                  activeTab === "returned" ? "active" : ""
                }`}
                id="Declined-tab"
                data-bs-toggle="tab"
                data-bs-target="#Declined-tab-pane"
                type="button"
                role="tab"
                aria-controls="disabled-tab-pane"
                aria-selected={activeTab === "returned"}
                onClick={() => {
                  fetchOrderDisputes("returned");
                  handleTabClick("returned");
                }}
              >
                Returned
              </button>
            </li>
          </ul>
          <div className="tab-content dispute-content" id="myTabContent">
            <div
              className={`tab-pane fade ${
                activeTab === "initiated" ? "show active" : ""
              }`}
              id="home-tab-pane"
              role="tabpanel"
              aria-labelledby="home-tab"
              tabindex="0"
            >
              {/* #=================== Initiated Table start ====================== */}
              <div className="card border-0 card-border mb-5">
                <div className="card-body">
                  {disputelist.length <= 0 ? (
                    <div className="no-record">No Record Found</div>
                  ) : (
                    <div className="table-responsive">
                      <table className="table-width">
                        <thead>
                          <tr className="table-heading">
                            <th style={{ width: "10%" }}>Dispute Id</th>
                            <th style={{ width: "14%" }}>Customer</th>
                            <th style={{ width: "18%" }}>Invoice Number</th>
                            <th style={{ width: "28%" }}>Reason</th>
                            <th style={{ width: "16%" }}>Date</th>

                            <th style={{ width: "16%" }}>Actions</th>
                            {/* <th
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              Actions
                            </th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {disputelist && disputelist.length > 0 ? (
                            disputelist.map((item, index) => (
                              <tr key={index} className="table-row">
                                <td style={{ paddingBlock: "13px" }}>
                                  {item.id}
                                </td>
                                <td style={{ paddingBlock: "13px" }}>
                                  {item.order.user.name}
                                </td>
                                <td style={{ paddingBlock: "13px" }}>
                                  {item.order.id}
                                </td>
                                <td style={{ paddingBlock: "13px" }}>
                                  {item.reason}
                                </td>
                                <td style={{ paddingBlock: "13px" }}>
                                  {formatDate(item.createdAt)}
                                </td>
                                <td
                                  style={{
                                    paddingBlock: "13px",
                                    display: "flex",
                                    // justifyContent: "center",
                                  }}
                                >
                                  {/* <OrderDisputes itemId={item.id} /> */}
                                  <img
                                    src={View}
                                    alt=""
                                    className="view-icon "
                                    onClick={() => DisputesDetails(item.id)}
                                  />
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="6">No disputes found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <div>
                        {/* <PaginationRounded
                          count={totalPage}
                          page={InitiatedcurrentPage}
                          onPageChange={(value) =>
                            handlePageChange("initiated", value)
                          }
                        /> */}
                         <PaginationRounded
                          page={InitiatedcurrentPage}
                          count={totalPage}
                          onChange={(event, value) =>
                            handlePageChange("initiated", value)
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* #=================== Initiated Table end ====================== */}
            </div>
            <div
              className={`tab-pane fade ${
                activeTab === "admin_review" ? "show active" : ""
              }`}
              id="profile-tab-pane"
              role="tabpanel"
              aria-labelledby="profile-tab"
              tabindex="0"
            >
              {/* #=================== Admin Table start ====================== */}
              <div className="card border-0 card-border mb-5">
                <div className="card-body">
                  {disputelist.length <= 0 ? (
                    <div className="no-record">No Record Found</div>
                  ) : (
                    <div className="table-responsive">
                      <table className="table-width">
                        <thead>
                          <tr className="table-heading">
                            <th style={{ width: "10%" }}>Dispute Id</th>
                            <th style={{ width: "14%" }}>Customer</th>
                            <th style={{ width: "18%" }}>Invoice Number</th>
                            <th style={{ width: "28%" }}>Reason</th>
                            <th style={{ width: "16%" }}>Date</th>
                            <th style={{ width: "16%" }}>Actions</th>
                            {/* <th
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              Actions
                            </th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {disputelist.map((item, index) => (
                            <tr key={index} className="table-row">
                              <td style={{ paddingBlock: "13px" }}>
                                {item.id}
                              </td>
                              <td style={{ paddingBlock: "13px" }}>
                                {item.order.user.name}
                              </td>
                              <td style={{ paddingBlock: "13px" }}>
                                {item.order.id}
                              </td>
                              <td style={{ paddingBlock: "13px" }}>
                                {item.reason}
                              </td>
                              <td style={{ paddingBlock: "13px" }}>
                                {formatDate(item.createdAt)}
                              </td>
                              <td
                                style={{
                                  paddingBlock: "13px",
                                  display: "flex",
                                }}
                              >
                                {/* <OrderDisputes itemId={item.id} /> */}
                                <img
                                  src={View}
                                  alt=""
                                  className="view-icon "
                                  onClick={() => DisputesDetails(item.id)}
                                />
                              </td>
                              {/* <td
                                style={{
                                  paddingBlock: "13px",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <img src={View} style={{ cursor: "pointer" }} />
                              </td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div>
                        {/* <PaginationRounded
                          count={totalPage}
                          page={AdmincurrentPage}
                          onPageChange={(value) =>
                            handlePageChange("admin_review", value)
                          }
                        /> */}
                           <PaginationRounded
                          page={AdmincurrentPage}
                          count={totalPage}
                          onChange={(event, value) =>
                            handlePageChange("admin_review", value)
                          }
                        />
                        {/* onChange={handleAdminPageChange} /> */}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* #=================== Admin Table end ====================== */}
            </div>
            <div
              className={`tab-pane fade ${
                activeTab === "resolved" ? "show active" : ""
              }`}
              id="contact-tab-pane"
              role="tabpanel"
              aria-labelledby="contact-tab"
              tabindex="0"
            >
              {/* #=================== Resolved Table start ====================== */}
              <div className="card border-0 card-border mb-5">
                <div className="card-body">
                  {disputelist.length <= 0 ? (
                    <div className="no-record">No Record Found</div>
                  ) : (
                    <div className="table-responsive">
                      <table className="table-width">
                        <thead>
                          <tr className="table-heading">
                            <th style={{ width: "10%" }}>Dispute Id</th>
                            <th style={{ width: "14%" }}>Customer</th>
                            <th style={{ width: "18%" }}>Invoice Number</th>
                            <th style={{ width: "28%" }}>Reason</th>
                            <th style={{ width: "16%" }}>Date</th>
                            <th style={{ width: "16%" }}>Full Filled By</th>
                            <th style={{ width: "16%" }}>Actions</th>
                            {/* <th
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              Actions
                            </th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {disputelist.map((item, index) => (
                            <tr key={index} className="table-row">
                              <td style={{ paddingBlock: "13px" }}>
                                {item.id}
                              </td>
                              <td style={{ paddingBlock: "13px" }}>
                                {item.order.user.name}
                              </td>
                              <td style={{ paddingBlock: "13px" }}>
                                {item.order.id}
                              </td>
                              <td style={{ paddingBlock: "13px" }}>
                                {item.reason}
                              </td>
                              <td style={{ paddingBlock: "13px" }}>
                                {formatDate(item.createdAt)}
                              </td>
                             <td style={{ paddingBlock: "13px",textTransform:"capitalize" }}>
                                {item.fullFilledBy?.role || "-"}
                              </td>

                              <td
                                style={{
                                  paddingBlock: "13px",
                                  display: "flex",
                                }}
                              >
                                {/* <OrderDisputes itemId={item.id} /> */}
                                <img
                                  src={View}
                                  alt=""
                                  className="view-icon "
                                  onClick={() => DisputesDetails(item.id)}
                                />
                              </td>
                              {/* <td
                                style={{
                                  paddingBlock: "13px",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <img src={View} style={{ cursor: "pointer" }} />
                              </td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div>
                        {/* <PaginationRounded
                          count={totalPage}
                          page={ResolvedcurrentPage}
                          // onChange={handleResollvedPageChange} />
                          onPageChange={(value) =>
                            handlePageChange("resolved", value)
                          }
                        /> */}
                          <PaginationRounded
                          page={ResolvedcurrentPage}
                          count={totalPage}
                          onChange={(event, value) =>
                            handlePageChange("resolved", value)
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* #=================== Resolved Table end ====================== */}
            </div>
            <div
              className={`tab-pane fade ${
                activeTab === "returned" ? "show active" : ""
              }`}
              id="Declined-tab-pane"
              role="tabpanel"
              aria-labelledby="Declined-tab"
              tabindex="0"
            >
              {/* #=================== Resolved Table start ====================== */}
              <div className="card border-0 card-border mb-5">
                <div className="card-body">
                  {disputelist.length <= 0 ? (
                    <div className="no-record">No Record Found</div>
                  ) : (
                    <div className="table-responsive">
                      <table className="table-width">
                        <thead>
                          <tr className="table-heading">
                            <th style={{ width: "10%" }}>Dispute Id</th>
                            <th style={{ width: "14%" }}>Customer</th>
                            <th style={{ width: "18%" }}>Invoice Number</th>
                            <th style={{ width: "28%" }}>Reason</th>
                            <th style={{ width: "16%" }}>Date</th>
                            <th style={{ width: "16%" }}>Full Filled By</th>

                            <th style={{ width: "16%" }}>Actions</th>
                            {/* <th
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              Actions
                            </th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {disputelist.map((item, index) => (
                            <tr key={index} className="table-row">
                              <td style={{ paddingBlock: "13px" }}>
                                {item.id}
                              </td>
                              <td style={{ paddingBlock: "13px" }}>
                                {item.order.user.name}
                              </td>
                              <td style={{ paddingBlock: "13px" }}>
                                {item.order.id}
                              </td>
                              <td style={{ paddingBlock: "13px" }}>
                                {item.reason}
                              </td>
                              <td style={{ paddingBlock: "13px" }}>
                                {formatDate(item.createdAt)}
                              </td>
                              <td style={{ paddingBlock: "13px",textTransform:"capitalize" }}>
                                {item.fullFilledBy?.role || "-"}
                              </td>

                              <td
                                style={{
                                  paddingBlock: "13px",
                                  display: "flex",
                                }}
                              >
                                {/* <OrderDisputes itemId={item.id} /> */}
                                <img
                                  src={View}
                                  alt=""
                                  className="view-icon "
                                  onClick={() => DisputesDetails(item.id)}
                                />
                              </td>
                              {/* <td
                                style={{
                                  paddingBlock: "13px",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <img src={View} style={{ cursor: "pointer" }} />
                              </td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div>
                        {/* <PaginationRounded
                          // count={totalPage}
                          page={ReturnedcurrentPage}
                          count={totalPage}
                          // page={ReturnedcurrentPage}
                          // onChange={handleReturnedPageChange} />
                          onChange={(value) =>
                            handlePageChange("returned", value)
                          }
                        /> */}
                        {/* <PaginationRounded
                          page={ReturnedcurrentPage}
                          count={totalPage}
                          onChange={(value) =>
                            handlePageChange("returned", value)
                          } // Pass value directly
                        /> */}
                        <PaginationRounded
                          page={ReturnedcurrentPage}
                          count={totalPage}
                          onChange={(event, value) =>
                            handlePageChange("returned", value)
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* #=================== Resolved Table end ====================== */}
            </div>
          </div>
          {/* #========================= Dispute tabs end ================================= */}
        </div>
      )}
    </Main>
  );
};

export default OrderDispute;
