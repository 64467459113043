// import * as React from "react";
// import Backdrop from "@mui/material/Backdrop";
// import Box from "@mui/material/Box";
// import Modal from "@mui/material/Modal";
// import Fade from "@mui/material/Fade";
// import { useState } from "react";
// import { Title, Form } from "./style";
// import closeIcon from "../../../assets/img/my-wallet-modals/closeIcon.svg";
// import useHook from "./usehooks";
// import { useEffect } from "react";
// import toastr from "toastr";

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "695px",
//   height: "413px",
//   bgcolor: "#FFFFFF",
//   p: "25px 45px",
//   borderRadius: "30px",
// };
// const PaymentRequestModal = ({
//   onOpenRequestModal,
//   onCloseRequestModal,
//   onOpenSelectAccountModal,
//   accountid,
// }) => {
//   const [selectAccount, setSelectAccount] = useState("");
//   const [selectAmount, setSelectAmount] = useState("");

//   const handleSelectAccountModal = () => {
//     onCloseRequestModal();
//     onOpenSelectAccountModal();
//   };
//   const handleSubmit = () => {
//     console.log("Account:", selectAccount, "Amount:", selectAmount);
//   };
//   const handleModalClose = () => {
//     onCloseRequestModal(false);
//   };

//   ////     API get Available balanace    //////

//   const { GetwalletCards, WithDrawRequest, GetallRequests } = useHook();
//   const [withdrawlCard, setWithdrawlCard] = useState("");
//   const getCardsWithdrawl = async () => {
//     const response = await GetwalletCards();
//     setWithdrawlCard(response.data);
//     console.log("withdraw response", response.data);
//   };

//   useEffect(() => {
//     getCardsWithdrawl();
//   }, []);

//   //   ACCOUNT  DETAILS

//   const [accountId, setAccountId] = useState(null);
//   const [accountName, setAccountName] = useState(null);
//   const [accountNumber, setAccountNumber] = useState(null);
//   //   const [selectAccount, setSelectAccount] = useState("");
//   //   const [selectAmount, setSelectAmount] = useState("");
//   //   const [error, setError] = useState("");

//   useEffect(() => {
//     const storedAccountId = localStorage.getItem("accountId");
//     const storedAccountName = localStorage.getItem("accountName");
//     const storedAccountNumber = localStorage.getItem("accountNumber");
//     setAccountId(storedAccountId);
//     setAccountName(storedAccountName);
//     setAccountNumber(storedAccountNumber);
//     if (storedAccountName && storedAccountNumber) {
//       setSelectAccount(`${storedAccountName} - ${storedAccountNumber}`);
//     }
//   }, []);

//   const Request = async () => {
//     const amount = selectAmount; // This should work fine now
//     const currentAccountId = parseInt(accountId); // Use a different name to avoid confusion

//     // Validate the amount before making the API request
//     if (amount > withdrawlCard.availableAmount) {
//       setError("Amount exceeds available balance.");
//       return; // Exit the function to prevent API call
//     } else {
//       setError("");
//     }

//     try {
//       if (currentAccountId) {
//         const response = await WithDrawRequest(amount, currentAccountId);
//         toastr.success("Withdraw Request Send");
//         onCloseRequestModal();
//       } else {
//         setError("Account ID is not set.");
//       }
//     } catch (error) {
//       console.log(error);
//       setError("Failed to process request.");
//     }
//   };

//   //   Validation for Amount

//   const [error, setError] = useState("");

//   const handleAmountChange = (e) => {
//     const amount = parseFloat(e.target.value);
//     setSelectAmount(amount);

//     // Validate the amount
//     if (amount > withdrawlCard.availableAmount) {
//       setError("Amount exceeds available balance.");
//     } else {
//       setError("");
//     }
//   };

//   return (
//     <>
//       <Modal
//         open={onOpenRequestModal}
//         onClose={handleModalClose}
//         closeAfterTransition
//         slots={{ backdrop: Backdrop }}
//         slotProps={{
//           backdrop: {
//             timeout: 300,
//           },
//         }}
//       >
//         <Fade in={onOpenRequestModal}>
//           <Box sx={style}>
//             <Title className="modal-head">
//               <p className="modal-title">Payment Request {accountid}</p>
//               <figure
//                 onClick={onCloseRequestModal}
//                 style={{ cursor: "pointer" }}
//               >
//                 <img src={closeIcon} alt="Close Button" />
//               </figure>
//             </Title>
//             <Form>
//               <div className="selectAccount">
//                 <label htmlFor="account" className="selectAccount-title">
//                   Select Account
//                 </label>
//                 <p
//                   className="selectAccount-selectTitle"
//                   onClick={handleSelectAccountModal}
//                   style={{ cursor: "pointer" }}
//                 >
//                   Select
//                 </p>
//               </div>
//               <input
//                 type="text"
//                 placeholder="Select Account"
//                 name="account"
//                 value={selectAccount}
//                 onChange={(e) => setSelectAccount(e.target.value)}
//                 className="selectAccount-inputField"
//                 readOnly
//               />
//               <div className="selectAccount">
//                 <label htmlFor="amount" className="selectAmmount-title">
//                   Enter Amount
//                 </label>
//                 <p className="selectAmmount-title">
//                   Balance Available:
//                   <span className="selectAmmount-selectTitle">
//                     &nbsp;{withdrawlCard.availableAmount}
//                   </span>
//                 </p>
//               </div>

//               <input
//                 type="number"
//                 placeholder="Enter Amount"
//                 name="amount"
//                 value={selectAmount}
//                 onChange={handleAmountChange}
//                 className="selectAmmount-inputField"
//               />

//               {error && <p className="error-message">{error}</p>}
//               <button type="button" id="form-submitButton" onClick={Request}>
//                 Withdraw Funds
//               </button>
//             </Form>
//           </Box>
//         </Fade>
//       </Modal>
//     </>
//   );
// };

// export default PaymentRequestModal;

import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useState, useEffect } from "react";
import { Title, Form } from "./style";
import closeIcon from "../../../assets/img/my-wallet-modals/closeIcon.svg";
import useHook from "./usehooks";
import toastr from "toastr";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "695px",
  height: "413px",
  bgcolor: "#FFFFFF",
  p: "25px 45px",
  borderRadius: "30px",
  "@media (max-width: 425px)": {
    width: "90%",
    height: "auto",
    padding: 2,
    borderRadius: "30px",
  },
};

const PaymentRequestModal = ({
  onOpenRequestModal,
  onCloseRequestModal,
  onOpenSelectAccountModal,
  accountid,
}) => {
  const [selectAccount, setSelectAccount] = useState("");
  const [selectAmount, setSelectAmount] = useState("");
  const [error, setError] = useState("");

  const handleSelectAccountModal = () => {
    onCloseRequestModal();
    onOpenSelectAccountModal();
    
  };

  const handleSubmit = () => {
    if (!selectAccount) {
      setError("Account must be selected.");
      return;
    }

    if (selectAmount === "" || selectAmount === -1) {
      setError("Amount cannot be empty or -1.");
      return;
    }

    // If all validations pass, clear the error and proceed with the API call
    setError("");
    Request();
  };

  const handleModalClose = () => {
    onCloseRequestModal(false);
    
  };

  const { GetwalletCards, WithDrawRequest, GetallRequests } = useHook();
  const [withdrawlCard, setWithdrawlCard] = useState("");

  const getCardsWithdrawl = async () => {
    const response = await GetwalletCards();
    setWithdrawlCard(response.data);
    // console.log("withdraw response", response.data);
  };

  useEffect(() => {
    getCardsWithdrawl();
  }, []);

  const [accountId, setAccountId] = useState(null);
  const [accountName, setAccountName] = useState(null);
  const [accountNumber, setAccountNumber] = useState(null);

  useEffect(() => {
    const storedAccountId = localStorage.getItem("accountId");
    const storedAccountName = localStorage.getItem("accountName");
    const storedAccountNumber = localStorage.getItem("accountNumber");
    setAccountId(storedAccountId);
    setAccountName(storedAccountName);
    setAccountNumber(storedAccountNumber);
    if (storedAccountName && storedAccountNumber) {
      setSelectAccount(`${storedAccountName} - ${storedAccountNumber}`);
    }
  }, []);

  const Request = async () => {
    const amount = selectAmount;
    const currentAccountId = parseInt(accountId);

    // Additional validation check before hitting the API
    if (amount === -1) {
      setError("Amount cannot be -1.");
      return; // Prevent API call
    }

    if (amount > withdrawlCard.availableAmount) {
      setError("Amount exceeds available balance.");
      return; // Prevent API call
    }

    try {
      if (currentAccountId) {
        const response = await WithDrawRequest(amount, currentAccountId);
        // console.log(response, "hehehehhe");
        if (response.status) {
          toastr.success("Withdraw Request Sent");
          onCloseRequestModal();
          localStorage.removeItem('accountName');
          localStorage.removeItem('accountNumber');
        } else {
          // console.log(response);
          // toastr.error(response.errors[0]);
          toastr.error(response.errors? response.errors[0]: response.error);
        }
      } else {
        setError("Account ID is not set.");
      }
    } catch (error) {
      console.log(error);
      setError("Failed to process request.");
      
    }
  };

  const handleAmountChange = (e) => {
    const amount = parseFloat(e.target.value);
    setSelectAmount(amount);

    if (amount === -1) {
      setError("Amount cannot be -1.");
    } else if (amount > withdrawlCard.availableAmount) {
      setError("Amount exceeds available balance.");
    } else {
      setError("");
    }
  };

  return (
    <>
      <Modal
        open={onOpenRequestModal}
        onClose={handleModalClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 300,
          },
        }}
      >
        <Fade in={onOpenRequestModal}>
          <Box sx={style}>
            <Title className="modal-head">
              <p className="modal-title">Payment Request {accountid}</p>
              <figure
                onClick={onCloseRequestModal}
                style={{ cursor: "pointer" }}
              >
                <img src={closeIcon} alt="Close Button" />
              </figure>
            </Title>
            <Form>
              <div className="selectAccount">
                <label htmlFor="account" className="selectAccount-title">
                  Select Account
                </label>
                <p
                  className="selectAccount-selectTitle"
                  onClick={handleSelectAccountModal}
                  style={{ cursor: "pointer" }}
                >
                  Select
                </p>
              </div>
              <input
                type="text"
                placeholder="Select Account"
                name="account"
                value={selectAccount}
                onChange={(e) => setSelectAccount(e.target.value)}
                className="selectAccount-inputField"
                readOnly
              />
              <div className="selectAccount">
                <label htmlFor="amount" className="selectAmmount-title">
                  Enter Amount
                </label>
                <p className="selectAmmount-title">
                  Balance Available:
                  <span className="selectAmmount-selectTitle">
                    &nbsp;{withdrawlCard.availableAmount}
                  </span>
                </p>
              </div>

              <input
                type="number"
                placeholder="Enter Amount"
                name="amount"
                value={selectAmount}
                onChange={handleAmountChange}
                className="selectAmmount-inputField"
              />

              {error && <p className="error-message">{error}</p>}
              <button
                type="button"
                id="form-submitButton"
                onClick={handleSubmit}
              >
                Withdraw Funds
              </button>
            </Form>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default PaymentRequestModal;
